import moment from "moment";
import React, { Component } from "react";
import FinanceServices from "../../services/axiosServices/apiServices/FinanceServices";
import SwalServices from "../../services/swalServices/SwalServices";
import { isNullNumber, isNullString } from "../../utils/Utils";
import DropdownSelect from "../../components/dropdown/Dropdown";
import EntryManagementServices from "../../services/axiosServices/apiServices/EntryManagementServices";
import { CommonValidationMessages } from "../../utils/Messages";
import { isValidForm, validate } from "../../utils/validation/CommonValidator";
import ValidationText from "../../utils/validation/ValidationText";
import { Navigate, NavLink } from "react-router-dom";
import { saveAs } from "file-saver";
import DropdownAction from "../../components/dropdown/DropdownAction";
import ActionContainer from "../../components/UIComponent/ActionTableComponent/actionContainer";
import { checkParameters } from "../../utils/Encryption";
import { getAuthProps } from "../../utils/AuthenticationLibrary";
import ButtonLoader from "../../components/common/buttonLoader/ButtonLoader";
import MembershipPricingServices from "../../services/axiosServices/apiServices/MembershipPricingServices";
import ModalBasic from "../component/FormModal";
import CommonServices from "../../services/axiosServices/apiServices/CommonServices";


export default class CreditNoteManagement extends Component {
    constructor(props) {
        super(props);
        this.financeService = new FinanceServices();
        this.swalServices = new SwalServices();
        this.commonServices = new CommonServices();
        this.entryServices = new EntryManagementServices();
        this.membershipPricingServices = new MembershipPricingServices();
        this.state = {
            creditNoteStatusList: [],
            creditNote: {
                creditNotePath:""
            },
            bankNameList: [],
            refundMethodList: [
                { id: 1, name: "Pay Now" },
                { id: 2, name: "Bank Transfer" },
            ],
            updateCreditNoteRequest: {
                financeCreditNoteId: 0,
                creditNoteRefundAmount: 0,
                creditNoteRefundMethod: "",
                bankNameId: 0,
                offsetInvoiceId: 0,
                creditNoteOffsetInvoiceAmount: 0,
                creditNoteInvoiceNo: "",
                creditNoteImage: "",
            },
            creditNoteInvoiceList: [],
            refundMethodId: 0,
            financeUnpaidInvoiceList: [],
            totalAmount: 0,
            totalRefundAmount: 0,
            invoiceOutstandingAmount: 0,
            invoiceNoForCreditNote: "",
            redirect: null,
            validationRules: {
                creditNoteRefundMethod: [
                    {
                        type: "require",
                        message: CommonValidationMessages.SelectRequired.replace(
                            "{0}",
                            "refund method"
                        ),
                    },
                ],
                bankNameId: [
                    {
                        type: "require",
                        message: CommonValidationMessages.SelectRequired.replace(
                            "{0}",
                            "bank name"
                        ),
                    },
                ],
            },
            validate: {
                isValid: true,
                error: {},
            },
            pagination: {
                pageNo: 1,
                pageSize: 10,
                orderByColumn: "",
                orderFlag: 0,
                searchText: "",
                status: 0,
                creditNoteStatusId: 0,
                creditNoteNumber: "",
                approvalEmailFilter: "",
            },
            actionDropdownApproved: ["View Invoice"],
            updatedCreditNote: {},
            isLoader: false,
            isLoaderUpdateCreditNote: 0,
            creditNoteImage: "",
            comments: "",
            isApproveCreditNote: false,
            creditNoteList: [],
            isRejected: true,
            isApprove: true,
            isLoading: false,
            fileDisplayName: "",
            filePath: "",    
        };
    }

    getFinanceCreditNoteById = (creditNoteId) => {
        if (isNullNumber(creditNoteId) !== 0) {
            this.financeService.getFinanceCreditNoteById(creditNoteId).then((response) => {
                if (response.statusCode === 200 && response.responseItem.responseContent != null) {
                    let dropdown = response.responseItem.responseContent.financeUnpaidInvoiceList;
                    dropdown = dropdown && dropdown.map((p) => ({ id: p.id, name: p.invoiceNoForCreditNote, }));

                    let OffsetInvoiceHistory = { ...response.responseItem.responseContent.financeGetCreditNoteOffsetInvoiceHistory[0], };
                    let RefundHistory = { ...response.responseItem.responseContent.financeGetCreditNoteRefundHistory[0], };
                    let returnMethodId = 0; if (OffsetInvoiceHistory) { }

                    if (RefundHistory) {
                        returnMethodId = RefundHistory.creditNoteRefundMethod ?
                            this.state.refundMethodList.find(
                                (x) => x.name === RefundHistory.creditNoteRefundMethod
                            ).id
                            : 0;
                    }

                    let data = { ...this.state.updateCreditNoteRequest };
                    data.creditNoteRefundAmount = response.responseItem.responseContent.creditNoteTotalRefund;
                    data.bankNameId = RefundHistory ? RefundHistory.bankNameId : 0;
                    data.creditNoteOffsetInvoiceAmount = OffsetInvoiceHistory ? OffsetInvoiceHistory.creditNoteOffsetInvoiceAmount : 0;
                    response.responseItem.responseContent.creditNoteOpenCreditAmout = response.responseItem.responseContent.creditNoteTotalRefund - data.creditNoteRefundAmount;

                    let unpaidInvoiceData = [];
                    if (response.responseItem.responseContent.financeUnpaidInvoiceList !== null && response.responseItem.responseContent.financeUnpaidInvoiceList.length > 0) {

                        unpaidInvoiceData = response.responseItem.responseContent.financeUnpaidInvoiceList;
                        unpaidInvoiceData.map((x) => {
                            x.invoiceDate = moment(x.invoiceDate).format("DD MMM YYYY");
                            if (x.isPaidByOffset === true) {
                                x.isSelected = true;
                            } else {
                                x.isSelected = false;
                            }
                            return x;
                        });
                    }

                    this.setState({
                        creditNote: response.responseItem.responseContent,
                        totalAmount: response.responseItem.responseContent.creditNoteOpenCreditAmout,
                        financeUnpaidInvoiceList: unpaidInvoiceData,
                        creditNoteInvoiceList: dropdown,
                        updateCreditNoteRequest: data,
                        totalRefundAmount: data.creditNoteRefundAmount,
                        refundMethodId: returnMethodId,
                        creditNoteImage: response.responseItem.responseContent.creditNoteImage,
                        comments: response.responseItem.responseContent.comments,
                    }, () => {
                        this.getFinanceCreditNoteStatusList();

                    });
                } else {
                    this.getFinanceCreditNoteStatusList();
                    this.swalServices.Error(response.message);
                }
                this.setState({ isLoading: false });
            });
        }
    };

    getFinanceCreditNoteStatusList = () => {
        this.financeService.getFinanceCreditNoteStatusList().then((response) => {
            if (response.statusCode === 200 && response.responseItem != null) {
                this.setState({
                    creditNoteStatusList: response.responseItem.responseContent,
                }, () => this.getBankList());
            } else {
                this.getBankList();
                this.swalServices.Error("Something went wrong.");
            }
        });
    };

    getBankList = () => {
        this.membershipPricingServices.getAllDropDownForEntryManagement(0).then((response) => {
            if (response.statusCode === 200 && response.responseItem != null) {
                var bankList = response.responseItem.responseContent.filter(
                    (drp) => drp.dropdownName === "BankNames"
                );
                bankList = bankList.map((p) => ({
                    id: p.id,
                    name: p.name,
                }));

                this.setState({ bankNameList: bankList });
            } else {
                this.swalServices.Error(response.message);
            }
        });
    };

    updateCreditNote = () => {
        if (this.isValidFinanceCreditNote()) {
            let data = { ...this.state.updateCreditNoteRequest };
            let request = {
                financeCreditNoteId: this.state.creditNote.financeCreditNoteId,
                creditNoteOpenCreditAmout: data.creditNoteOpenCreditAmout ? data.creditNoteOpenCreditAmout : 0,
                creditNoteStatusId: 3,
                creditNoteRefundAmount: data.creditNoteRefundAmount,
                creditNoteRefundMethod: data.creditNoteRefundMethod,
                bankNameId: data.bankNameId,
                OffsetInvoiceId: data.offsetInvoiceId,
                invoiceNoForCreditNote: data.creditNoteInvoiceNo,
                creditNoteOffsetInvoiceAmount: data.creditNoteOffsetInvoiceAmount ? data.creditNoteOffsetInvoiceAmount : 0,
                createdBy: 1,
                createdAppId: 114,
                createdAt: new Date(),
                membershipPaymentTypeId: this.state.creditNote.membershipPaymentTypeId,
            };
            this.setState({ isLoaderUpdateCreditNote: true }, () => {
                this.financeService.financeUpdateCreditNote(request).then((response) => {
                    if (response.statusCode === 200 && response.responseItem.responseContent != null) {
                        this.setState({ updatedCreditNote: response.responseItem.responseContent });
                        let paymentTypeId = response.responseItem.responseContent.membershipPaymentTypeId;
                        let reconciliationReq = {};
                        reconciliationReq.corporateMemberId = response.responseItem.responseContent.corporateMemberId;
                        reconciliationReq.individualMemberId = response.responseItem.responseContent.individualMemberId;
                        reconciliationReq.eventId = response.responseItem.responseContent.eventId;
                        reconciliationReq.membershipPaymentTypeId = response.responseItem.responseContent.membershipPaymentTypeId;
                        reconciliationReq.PaymentProofStatusId = 2;
                        reconciliationReq.bookingId = response.responseItem.responseContent.bookingId;
                        reconciliationReq.firstName = response.responseItem.responseContent.attentionTo;
                        reconciliationReq.lastName = ' ';
                        reconciliationReq.email = response.responseItem.responseContent.emailAddress;
                        reconciliationReq.financeCreditNoteId = this.state.creditNote.financeCreditNoteId;
                        reconciliationReq.amountCollected = request.creditNoteOffsetInvoiceAmount;
                        reconciliationReq.offsetInvoiceId = data.offsetInvoiceId;

                        if (paymentTypeId !== 5 && request.creditNoteOffsetInvoiceAmount > 0) {
               
                            this.financeService.financeAddCreditNotePaymentProofMapping(reconciliationReq).then((response) => { });
                        }
                        this.swalServices.Success("Credit note updated.");
                        this.setState({ redirect: "/financial/CreditNoteApproval" });
                    } else {
                        this.swalServices.Error(response.message);
                    }
                    this.setState({ isLoaderUpdateCreditNote: false });
                });
            });
        }
        else {
            this.setState({ isLoaderUpdateCreditNote: false });
        }
    };
    onFileChange = (event) => {
        const file = event.target.files[0];
        if (file && (file.type === "image/jpeg" || file.type === "image/png")) {
            let request = {};
            request.storagePath = "CreditNoteImages";
            request.file = file;
            request.fileName = file.name;

            this.commonServices.uploadPDF(request).then((response) => {
                if (response !== null) {
                    if (request.storagePath === "CreditNoteImages") {
                     

                        this.setState({ filePath: response.fileName, fileDisplayName: file.name }, () => {
                            this.swalServices.Success("Image File Uploaded");
                        });
                    }
                } else {
                    this.swalServices.Error(response.message);
                }
            });
        } else {
            this.swalServices.Error("Please select only Image file");
        }
    };
    approveCreditNote = () => {
        this.setState({ isApproveCreditNote: true });
    }

    financeApproveRejectCreditNoteApproval = (identity) => {
        let isCheckForApproval = true;
        let request = {
            updatedBy: 1,
            updatedAt: moment().format("yyyy-MM-DD"),
            updatedAppId: 114,
            financeCreditNoteId: [],
            creditNoteImage : "",
            comments: ""
        };
        if (identity === 'Reject') {
            request.creditNoteStatusId = 6;
        } else if (identity === 'Approve') {
            request.creditNoteStatusId = 2;
            request.creditNoteImage = this.state.filePath;
            request.comments = this.state.comments;
        }
        let cookie = getAuthProps();
        const adminUserName = (cookie && cookie.userName) ? cookie.userName : '';
        if (this.state.creditNote.approvalEmail !== adminUserName) {
            isCheckForApproval = false;
        }
        request.financeCreditNoteId.push(this.state.creditNote.financeCreditNoteId);
        if (isCheckForApproval) {
            this.setState({ isLoader: true });
            this.financeService.financeApproveRejectCreditNoteApproval(request).then((response) => {
                if (response.statusCode === 200 && response.responseItem != null) {
                    this.getFinanceCreditNoteById(this.state.creditNote.financeCreditNoteId);
                    if ((this.state.creditNote.membershipPaymentTypeId) && ((this.state.creditNote.membershipPaymentTypeId === 1) || (this.state.creditNote.membershipPaymentTypeId === 2))){
                    }
                    this.setState({ redirect: "/financial/CreditNoteApproval" });
                } else {
                    this.swalServices.Error("Something went wrong.");
                }
                this.setState({ isLoader: false });
            })
        } else {
            this.swalServices.Error(`you are not authorized to approve or reject these credit note ${this.state.creditNote.approvalEmail ? this.state.creditNote.approvalEmail : ''}`);
            this.setState({ isLoader: false });
        }
    }

    addMembershipCreditNoteApprovalRecordsForBatchJob = (financeCreditNoteId) => {
        let request = [financeCreditNoteId];
        this.financeService.addMembershipCreditNoteApprovalRecordsForBatchJob(request).then((response) => {
            if ((response.statusCode === 200) && (response.responseItem.responseContent != null)) {
            }
            else {
                this.swalServices.Error(response.message);
            }
        });
    }
    addEventCreditNoteApprovalRecordsForBatchJob = (financeCreditNoteId) => {
        let request = [financeCreditNoteId];
        this.financeService.addEventCreditNoteApprovalRecordsForBatchJob(request).then((response) => {
            if ((response.statusCode === 200 )&& (response.responseItem.responseContent != null)) {
            }
            else {
                this.swalServices.Error(response.message);
            }
        });
    }

    addContinuosPeriodExtInvCNApprovalXeroRecords = (financeCreditNoteId) => {
        let request = [financeCreditNoteId];
        this.financeService.addContinuosPeriodExtInvCNApprovalXeroRecords(request).then((response) => {
            if ((response.statusCode === 200) &&( response.responseItem.responseContent != null)) {
            }
            else {
                this.swalServices.Error(response.message);
            }
        });
    }

    componentDidMount() {
        let params = new URLSearchParams(window.location.search);
        let creditNoteId = params.get("");
        if (creditNoteId && (creditNoteId = checkParameters(creditNoteId, "Number"))) {
            if (creditNoteId != null && creditNoteId > 0) {
                this.getFinanceCreditNoteById(creditNoteId);
            }
        }



    }

    isValidFinanceCreditNote = () => {
        const newValidState = isValidForm(
            this.state.updateCreditNoteRequest,
            this.state.validationRules,
            this.state.validate
        );
        this.setState({ validate: newValidState });
        return newValidState.isValid;
    };

    validateFinanceCreditNote = (identity, providedState) => {
        providedState = providedState
            ? providedState
            : this.state.updateCreditNoteRequest;
        const newValidState = validate(
            identity,
            providedState,
            this.state.validationRules,
            this.state.validate
        );
        this.setState({ validate: newValidState });
    };

    handleChange = (event) => {
        let { name, value } = event.target;
        let detail = { ...this.state.updateCreditNoteRequest };
        let data = { ...this.state.creditNote };
        let totalCount = this.state.totalAmount;
        let totalRefundAmount = this.state.totalRefundAmount;
        if (name === "creditNoteRefundAmount") {
            if (value === "") {
                detail[name] = value;
                totalRefundAmount = value;
                let creditNoteRefundAmount = detail.creditNoteRefundAmount
                    ? detail.creditNoteRefundAmount
                    : 0;
                data.creditNoteOpenCreditAmout =
                    totalCount - parseFloat(creditNoteRefundAmount).toFixed(2);
                detail.creditNoteOffsetInvoiceAmount = 0;
            } else {
                if (Number(value).toFixed(2) > totalCount) {
                    value = totalCount;
                } else if (isNullNumber(Number(value).toFixed(2)) === 0) {
                    value = 0;
                } else {
                
                }
                detail[name] = value;
                totalRefundAmount = value;
                let creditNoteRefundAmount = detail.creditNoteRefundAmount
                    ? detail.creditNoteRefundAmount
                    : 0;
                if (creditNoteRefundAmount > 0) {
                    detail.creditNoteOffsetInvoiceAmount = 0;
                }
                data.creditNoteOpenCreditAmout =
                    totalCount - parseFloat(creditNoteRefundAmount).toFixed(2);
            }
        }
        this.setState({
            updateCreditNoteRequest: detail,
            creditNote: data,
            totalRefundAmount: totalRefundAmount,
        });
    };

    setFilterParameters = (id, drpIdentity) => {
        let detail = { ...this.state.updateCreditNoteRequest };
        let invoiceOutstandingAmount = "";
        let invoiceNoForCreditNote = "";
        if (drpIdentity === "creditNoteRefundMethod") {
            let findData = this.state.refundMethodList.filter((x) => x.id === id);
            if (findData.length > 0) {
                detail.creditNoteRefundMethod = findData[0].name;
                this.setState({ refundMethodId: findData[0].id });
            } else {
                detail.creditNoteRefundMethod = "";
                this.setState({ refundMethodId: id });
            }
        }
        if (drpIdentity === "bankNameId") {
            detail.bankNameId = id;
        }
        this.setState(
            {
                updateCreditNoteRequest: detail,
                invoiceOutstandingAmount: invoiceOutstandingAmount,
                invoiceNoForCreditNote: invoiceNoForCreditNote,
            },
            () => {
                this.validateFinanceCreditNote(drpIdentity);
            }
        );
    };

    financeGetCreditNoteInvoice = (value) => {
        let request = [];
        request.push(value);
        if (isNullString(value) !== "") {
            this.financeService
                .financeGetCreditNoteInvoice(request)
                .then((response) => {
                    if (response.data !== null && response.status === 200) {
                        var formattedDate = moment(new Date()).format("DDMMYYYYHHmmss");

                        var filename = "";
                        filename = `Credit-Note-${formattedDate}`;

                        var file = new Blob([response.data], { type: "application/pdf" });

                        URL.createObjectURL(file);
                        window.open(URL.createObjectURL(file), "_blank");

                        saveAs(file, filename + ".pdf");
                    } else {
                        this.swalServices.Error(response.message);
                    }
                });
        } else {
            this.swalServices.Error("No Invoice is Available");
        }
    };

    actions = (element, index, value) => {
        return element !== "id" ? null : (
            <div className="relative w-full credit-note-action-dropdown">
                <ActionContainer>
                    <>
                        <DropdownAction
                            key={index}
                            value={value}
                            id={index}
                            index={index}
                            options={this.state.actionDropdownApproved}
                            align="right"
                            onOptionClick={this.actionClick.bind(this)}
                        />
                    </>
                </ActionContainer>
            </div>
        );
    };

    actionClick = (index, value, option, event) => {
        if (option === "View Invoice") {
            if (value && value > 0) {
                let path = this.state.financeUnpaidInvoiceList[index].invoicePath;
                if (isNullString(path) !== '') {
                    this.financeGetCreditNoteInvoice(path);
                }
            }
        }
    };

    setPagination = (newPagination) => {
    };

    select = (element, index, value) => {
        return element !== "selectRow" ? null : (
            <td className={`px-2 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt text-[#000000] ${this.state.creditNote.creditNoteStatusId === 5 || this.state.creditNote.creditNoteStatusId === 6 ? "disabled-checkbox" : ""} `}>
                < input
                    name="isRequireQuotation"
                    type="checkbox"
                    className="cursor-pointer h-6 w-6 rounded check-box-custom "
                    onChange={() => this.selectSingleRows(value)
                    }
                    checked={this.state.financeUnpaidInvoiceList[index].isSelected === true ? true : false}
                    disabled={
                        this.state.creditNote.creditNoteStatusId === 5 || this.state.creditNote.creditNoteStatusId === 6 ? true : false
                    }
                />
            </td>
        );
    };

    selectSingleRows = (value) => {
        let getAllRows = [...this.state.financeUnpaidInvoiceList];
        let check = getAllRows.find(x => x.id === value)
        let detail = { ...this.state.updateCreditNoteRequest };
        let totalRefundAmount = this.state.totalRefundAmount;
        let total = totalRefundAmount ? totalRefundAmount : 0;
        if (check.isSelected) {
            getAllRows.map((x) => {
                if (x.id === value) {
                    x.isSelected = false;
                    detail.creditNoteOffsetInvoiceAmount = 0;
                    detail.creditNoteRefundAmount = total;
                    detail.offsetInvoiceId = 0;
                    detail.creditNoteInvoiceNo = "";
                }
           return x; })
        } else {
            getAllRows.map((x) => {
                if (x.id === value) {
                    x.isSelected = true;
                    detail.offsetInvoiceId = value;
                    detail.creditNoteInvoiceNo = x.invoiceNoForCreditNote;
                    if (isNullNumber(x.totalAmount) === 0) {
                        detail.creditNoteOffsetInvoiceAmount = x.totalAmount;
                        detail.creditNoteRefundAmount = total;
                    }
                    else {
                        if (total > 0) {
                            let inAmount = parseFloat(x.totalAmount);
                            if (parseFloat(x.totalAmount) > parseFloat(total)) {
                                inAmount = parseFloat(total);
                            }
                            detail.creditNoteOffsetInvoiceAmount = inAmount;
                            detail.creditNoteRefundAmount = total - parseFloat(inAmount.toFixed(2));
                        } else {
                            detail.creditNoteOffsetInvoiceAmount = 0;
                            this.validateFinanceCreditNote("creditNoteRefundAmount");
                        }
                    }
                }
                else if (x.id !== value) {
                    x.isSelected = false;
                }
           return x; })
        }
        this.setState({
            financeUnpaidInvoiceList: getAllRows,
            updateCreditNoteRequest: detail,
            totalRefundAmount: totalRefundAmount,
        });

    }

    GetCreditNoteFiles = (type, creditNoteImage) => {
     
        if (creditNoteImage) {
            let request = [
                0,
                type,
                creditNoteImage,
            ];
            this.membershipPricingServices.GetCreditNoteFiles(request).then((response) => {
                if (response.data !== null && response.status === 200) {
                    var formattedDate = moment(new Date()).format("DDMMYYYYHHmmss");
                    var filename = "";
                    filename = `${type}${formattedDate}`;
                    const extension = creditNoteImage.split(".").pop();
                    if (extension) {
                        saveAs(new Blob([response.data]), `${filename}.${extension}`);
                    }
                } else {
                    this.swalServices.Error(response.message);
                }
            });
        } else {
            this.swalServices.Error("No data found");
        }
    };

    render() {
        if (this.state.redirect != null) {
            return <Navigate to={this.state.redirect} />;
        }
        return (
            <div className="main-body-section finance-body-sec">
                {/* Section 1 Start */}
                <div className="bg-white pb-14">
                    <div className="heading-part px-7 py-4 border-b">
                        <div className="flex items-center justify-between">
                            <h4 className="small-title font-bold theme-color">
                                Credit Note Details
                            </h4>
                            <div class="w-auto">
                                <button
                                    className="ml-5 2xl:px-7 lg:px-5 py-2 btn btn btn-blue-border text-xl border text-[#e78e3c]"
                                    onClick={() =>
                                        this.GetCreditNoteFiles(
                                            "CreditNoteImages",
                                            this.state.creditNoteImage
                                        )
                                    }
                                >
                                    <span className="text-xl text-[#1974D2] font-semibold tracking-normal underline">
                                        Credit Note Proof
                                    </span>
                                </button>
                            </div>
                           
                            <div className="w-auto">
                                <textarea
                                   
                                    name="comments"
                                    className="w-full"
                                   
                                    rows="1"
                                    value={this.state.comments}
                                    disabled={true}
                           
                                />

                            </div>
                            <div>
                                {isNullString(this.state.creditNote.creditNotePath) !== "" ? (
                                    <>
                                        <button
                                            className={
                                                "btn btn-blue text-xl border text-white md:mr-5"
                                            }
                                            onClick={() => {
                                                this.financeGetCreditNoteInvoice(
                                                    this.state.creditNote.creditNotePath
                                                );
                                            }}
                                        >
                                            Download Credit Note
                                        </button>
                                    </>
                                ) : null}
                                <button
                                    className={"btn btn-blue text-xl border text-white"}
                                    onClick={() => {
                                        this.financeGetCreditNoteInvoice(
                                            this.state.creditNote.invoicePath
                                        );
                                    }}
                                >
                                    Download Invoice
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="grid grid-cols-12 gap-6 w-full items-center px-8 pt-10 pb-3">
                        <div className="col-span-12">
                            <div className="grid grid-cols-12 gap-6">
                                <div className=" 2xl:col-span-6 lg:col-span-6 col-span-12 w-full mb-3 input-sec-texts">
                                    <h2 className="text-xl theme-color mb-3">
                                        Credit Note Number
                                    </h2>
                                    <input
                                        id="default"
                                        name="dateFrom"
                                        className="form-input rounded-none w-full shadow-red py-3 text-lg cursor-not-allowed bg-[#e9e9ea]  border-[#000000]  text-[#000000]"
                                        type="text"
                                        value={this.state.creditNote.creditNoteNumber}
                                        disabled={true}
                                    />
                                </div>
                                <div className=" 2xl:col-span-6 lg:col-span-6 col-span-12 w-full mb-3 input-sec-texts">
                                    <h2 className="text-xl theme-color mb-3">
                                        Credit Note Created Date
                                    </h2>
                                    <input
                                        id="default"
                                        name="dateFrom"
                                        className="date-pickers form-input rounded-none w-full shadow-red py-3 text-lg cursor-not-allowed bg-[#e9e9ea]  border-[#000000]  text-[#000000]"
                                        type="date"
                                        value={moment(this.state.creditNote.creditNoteMonth).format(
                                            "yyyy-MM-DD"
                                        )}
                                        disabled={true}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-span-12">
                            <div className="grid grid-cols-12 gap-6">
                                <div className=" 2xl:col-span-6 lg:col-span-6 col-span-12 w-full mb-3 input-sec-texts">
                                    <h2 className="text-xl theme-color mb-3">Company Name</h2>
                                    <input
                                        id="default"
                                        name="dateFrom"
                                        className="form-input rounded-none w-full shadow-red py-3 text-lg cursor-not-allowed bg-[#e9e9ea]  border-[#000000]  text-[#000000]"
                                        type="text"
                                        value={this.state.creditNote.companyName}
                                        disabled={true}
                                    />
                                </div>
                                <div className=" 2xl:col-span-6 lg:col-span-6 col-span-12 w-full mb-3 input-sec-texts">
                                    <h2 className="text-xl theme-color mb-3">Company UEN</h2>
                                    <input
                                        id="default"
                                        name="dateFrom"
                                        className="form-input rounded-none w-full shadow-red py-3 text-lg cursor-not-allowed bg-[#e9e9ea]  border-[#000000]  text-[#000000]"
                                        type="text"
                                        value={this.state.creditNote.companyUEN}
                                        disabled={true}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-span-12">
                            <div className="grid grid-cols-12 gap-6">
                                <div className=" 2xl:col-span-6 lg:col-span-6 col-span-12 w-full mb-3 input-sec-texts">
                                    <h2 className="text-xl theme-color mb-3">Contact Person</h2>
                                    <input
                                        id="default"
                                        name="dateFrom"
                                        className="form-input rounded-none w-full shadow-red py-3 text-lg cursor-not-allowed bg-[#e9e9ea]  border-[#000000]  text-[#000000]"
                                        type="text"
                                        value={this.state.creditNote.contactPerson}
                                        disabled={true}
                                    />
                                </div>
                                <div className=" 2xl:col-span-6 lg:col-span-6 col-span-12 w-full mb-3 input-sec-texts">
                                    <h2 className="text-xl theme-color mb-3">
                                        Contact Person Email
                                    </h2>
                                    <input
                                        id="default"
                                        name="dateFrom"
                                        className="form-input rounded-none w-full shadow-red py-3 text-lg cursor-not-allowed bg-[#e9e9ea]  border-[#000000]  text-[#000000]"
                                        type="text"
                                        value={this.state.creditNote.contactPersonEmail}
                                        disabled={true}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-span-12">
                            <div className="grid grid-cols-12 gap-6">
                                <div className=" 2xl:col-span-6 lg:col-span-6 col-span-12 w-full mb-3 input-sec-texts">
                                    <h2 className="text-xl theme-color mb-3">Invoice No</h2>
                                    <input
                                        id="default"
                                        name="dateFrom"
                                        className="form-input rounded-none w-full shadow-red py-3 text-lg cursor-not-allowed bg-[#e9e9ea]  border-[#000000]  text-[#000000]"
                                        type="text"
                                        value={this.state.creditNote.invoiceNum}
                                        disabled={true}
                                    />
                                </div>
                                <div className=" 2xl:col-span-6 lg:col-span-6 col-span-12 w-full mb-3 input-sec-texts">
                                    <h2 className="text-xl theme-color mb-3">Invoice Amount</h2>
                                    <input
                                        id="default"
                                        name="dateFrom"
                                        className="form-input rounded-none w-full shadow-red py-3 text-lg cursor-not-allowed bg-[#e9e9ea]  border-[#000000]  text-[#000000]"
                                        type="text"
                                        value={this.state.creditNote.invoiceAmount}
                                        disabled={true}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-span-12">
                            <div className="grid grid-cols-12 gap-6">
                                <div className=" 2xl:col-span-6 lg:col-span-6 col-span-12 w-full mb-3 input-sec-texts">
                                    <h2 className="text-xl theme-color mb-3">Credit Amount</h2>
                                    <input
                                        id="default"
                                        name="dateFrom"
                                        className="form-input rounded-none w-full shadow-red py-3 text-lg cursor-not-allowed bg-[#e9e9ea]  border-[#000000]  text-[#000000]"
                                        type="text"
                                        value={this.state.creditNote.creditNoteTotalRefund}
                                        disabled={true}
                                    />
                                </div>
                                <div className=" 2xl:col-span-6 lg:col-span-6 col-span-12 w-full mb-3 input-sec-texts">
                                    <h2 className="text-xl theme-color mb-3">Open Credit</h2>
                                    <input
                                        id="default"
                                        name="dateFrom"
                                        className="form-input rounded-none w-full shadow-red py-3 text-lg cursor-not-allowed bg-[#e9e9ea]  border-[#000000]  text-[#000000]"
                                        type="text"
                                        value={this.state.creditNote.creditNoteOpenCreditAmout}
                                        disabled={true}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-span-12">
                            <div className="grid grid-cols-12 gap-6">
                                <div className=" 2xl:col-span-6 lg:col-span-6 col-span-12 w-full mb-3 input-sec-texts">
                                    <h2 className="text-xl theme-color mb-3">Status</h2>
                                    <DropdownSelect
                                        drpIdentity="Status"
                                        optionArray={this.state.creditNoteStatusList}
                                        value={this.state.creditNote.creditNoteStatusId}
                                        disabled={true}
                                    />
                                </div>
                                <div className=" 2xl:col-span-6 lg:col-span-6 col-span-12 w-full mb-3 input-sec-texts">
                                    <h2 className="text-xl theme-color mb-3">
                                        Cancellation Reason
                                    </h2>
                                    <input
                                        id="default"
                                        name="dateFrom"
                                        className="form-input rounded-none w-full shadow-red py-3 text-lg cursor-not-allowed bg-[#e9e9ea]  border-[#000000]  text-[#000000]"
                                        type="text"
                                        value={this.state.creditNote.creditNoteCancellationReason}
                                        disabled={true}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                 
                    <div className="grid grid-cols-12 gap-6 w-full items-center px-8 pt-10 pb-3">
                        <div className="col-span-12">
                            <div className="grid grid-cols-12 gap-6">
                                <div className=" 2xl:col-span-6 lg:col-span-6 col-span-12 w-full mb-3 input-sec-texts">
                                    <h2 className="text-xl theme-color mb-3">Refund Amount</h2>
                                    <input
                                        id="default"
                                        name="creditNoteRefundAmount"
                                        className="form-input rounded-none w-full shadow-red py-3 text-lg cursor-not-allowed bg-[#e9e9ea]"
                                        type="text"
                                        value={this.state.creditNote.creditNoteStatusId === 5 || this.state.creditNote.creditNoteStatusId === 6 ?
                                            (this.state.updateCreditNoteRequest.creditNoteRefundAmount - this.state.updateCreditNoteRequest.creditNoteOffsetInvoiceAmount).toFixed(2)
                                            : this.state.updateCreditNoteRequest.creditNoteRefundAmount.toFixed(2)
                                        }
                                        onChange={(e) => this.handleChange(e)}
                                        disabled={true}
                                    />
                                </div>
                                <div className=" 2xl:col-span-6 lg:col-span-6 col-span-12 w-full mb-3 input-sec-texts">
                                    <h2 className="text-xl theme-color mb-3">
                                        Refund Method
                                        <span className="text-[#c00000]">*</span>
                                    </h2>
                                    <DropdownSelect
                                        drpIdentity="creditNoteRefundMethod"
                                        optionArray={this.state.refundMethodList}
                                        setFilterParameters={this.setFilterParameters.bind(this)}
                                        value={this.state.refundMethodId}
                                        disabled={
                                            this.state.creditNote.creditNoteStatusId === 5
                                                ? true
                                                : false
                                        }
                                    />
                                    <ValidationText
                                        error={this.state.validate.error.creditNoteRefundMethod}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-span-12">
                            <div className="grid grid-cols-12 gap-6">
                                <div className=" 2xl:col-span-6 lg:col-span-6 col-span-12 w-full mb-3 input-sec-texts">
                                    <h2 className="text-xl theme-color mb-3">
                                        Bank
                                        <span className="text-[#c00000]">*</span>
                                    </h2>
                                    <DropdownSelect
                                        drpIdentity="bankNameId"
                                        optionArray={this.state.bankNameList}
                                        setFilterParameters={this.setFilterParameters.bind(this)}
                                        value={this.state.updateCreditNoteRequest.bankNameId}
                                        disabled={
                                            this.state.creditNote.creditNoteStatusId === 5
                                                ? true
                                                : false
                                        }
                                    />
                                    <ValidationText
                                        error={this.state.validate.error.bankNameId}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                </div>


                
                <div className="flex flex-wrap justify-between space-x-10 mt-12">
                    <NavLink
                        to="/financial/CreditNoteApproval"
                        className="w-32 px-7 py-2 btn-sm btn-gray text-lg text-white hover:text-white"
                    >
                        Back
                    </NavLink>

                    {this.state.creditNote.creditNoteStatusId === 1 ?
                        <>
                            {this.state.isLoader === true ?
                                <ButtonLoader className="btn btn-blue mr-5" />
                                :
                                <div className='space-x-4'>
                                    <button
                                        className={`w-32 px-7 py-2 btn-sm btn-blue btn text-xl border text-white`}
                                        onClick={() => {
                                            this.financeApproveRejectCreditNoteApproval('Reject');
                                        }}
                                        disabled={
                                            this.state.creditNote.creditNoteStatusId === 1 ? false : true
                                        }
                                    >
                                        Reject
                                    </button>
                                    <button
                                        className={`w-32 px-7 py-2 btn-sm btn-blue btn text-xl border text-white`}
                                        onClick={() => {
                                            this.approveCreditNote('Approve');
                                        }}
                                        disabled={
                                            this.state.creditNote.creditNoteStatusId === 1 ? false : true
                                        }
                                    >
                                        Approve
                                    </button>
                                </div>
                            }

                        </>
                        :
                        <>
                            {this.state.isLoaderUpdateCreditNote === true ?
                                <ButtonLoader className="btn btn-blue mr-5" />
                                :
                                <button
                                    className={`w-32 px-7 py-2 btn-sm btn-blue btn text-xl border text-white ${this.state.creditNote.creditNoteStatusId === 5 || this.state.creditNote.creditNoteStatusId === 6
                                        ? "cursor-not-allowed"
                                        : ""
                                        }`}
                                    onClick={() => {
                                        this.updateCreditNote();
                                    }}
                                    disabled={
                                        this.state.creditNote.creditNoteStatusId === 5 || this.state.creditNote.creditNoteStatusId === 6 ? true : false
                                    }
                                >
                                    Update
                                </button>
                            }
                        </>
                    }
                </div>


                <div className="Approve-Credit-Note-modal">
                    <ModalBasic
                        id="ApproveCreditNote"
                        modalOpen={this.state.isApproveCreditNote}
                        setModalOpen={() => this.setState({ isApproveCreditNote: false })}
                        title="Approve Credit Notess"
                    >
                        <div className="grid grid-span-12 gap-6 p-5 pb-10">
                            <div className="col-span-12 mb-5">
                                <div className="items-center mb-5">
                                    <label className="text-[#000000] text-[16px] mobile-14 md:mb-5 inline-block">
                                        File 1
                                    </label>
                                    <div className="flex items-center disabled-input">
                                        <input
                                            className="w-full custom-input"
                                            type="text"
                                            name=""
                                            value={this.state.fileDisplayName}
                                            disabled
                                        />
                                        <div>
                                           
                                        </div>
                                        <div className="flex items-center justify-center ml-4 ">
                                            <form className="custom-uploader custom-file-upload-btn flex justify-center items-center text-center btn btn-blue-border">
                                                <label
                                                    htmlFor={`upload_File_For_CreditNote`}
                                                    className="text-uplod block text-sm text-[#005B9C] w-full whitespace-nowrap"
                                                >
                                                    Select File
                                                </label>
                                                   <input
                                                    className="sr-only"
                                                    // key={`FileQuestion${key}`}
                                                    id={`upload_File_For_CreditNote`}
                                                    type="file"
                                                    onChange={(event) => this.onFileChange(event)}
                                                />
                                            </form>
                                        </div>
                                    </div>
                                </div>
                                <div className="items-center">
                                    <label className="text-[#000000] text-[16px] mobile-14 md:mb-5 inline-block">
                                        Comments
                                        <span className="text-[#c00000]">*</span>
                                    </label>
                                    <textarea
                                        className="w-full"
                                       
                                        id=""
                                        rows="5"
                                        name="comments"
                                        value={this.state.comments}
                                        onChange={(e) => { this.setState({ comments: e.target.value }) }}
                                
                                    />
                                    <div>
                                      
                                    </div>
                                </div>
                            </div>
                            <div className="col-span-12 flex justify-center">
                                <button class="w-32 px-7 py-2 btn-sm btn-blue text-lg text-white"
                                    onClick={() => {
                                            this.financeApproveRejectCreditNoteApproval("Approve")
                                    }}
                                >Approve</button>
                            </div>
                        </div>

                    </ModalBasic>
                </div>
            </div>
        );
    }
}
