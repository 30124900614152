import React, { Component } from "react";
import { Navigate } from "react-router-dom";
import { encryptAES } from "../../../utils/Encryption";
import EmployeesList from "../../../components/membership/corporateMembers/EmployeesList";
import EngagementTrackingNotes from "../../../components/membership/corporateMembers/EngagementTrackingNotes";
import FinancialsRecord from "../../../components/membership/corporateMembers/FinancialsRecord";
import OrganisationChapterRole from "../../../components/membership/corporateMembers/OrganisationChapterRole";
import CorporateMemberServices from "../../../services/axiosServices/apiServices/CorporateMemberServices";
import SlotMemberService from "../../../services/axiosServices/apiServices/SlotMemberService";
import SIISCorporateAccountInformation from ".././../../components/membership/corporateMembers/corporateMemberSegment/SIISCorporateAccountInformation";
import { validate, isValidForm, } from "../../../utils/validation/CommonValidator";
import SwalServices from "../../../services/swalServices/SwalServices";
import {
  CommonSuccessMessages,
  ErrorMessage,
  SuccessMessage,
  CommonValidationMessages,
} from "../../../utils/Messages";
import FinanceCard from "../../../components/membership/corporateMembers/corporateMemberSegment/FinanceCard";
import CorporateMembershipSubscription from "../../../components/membership/corporateMembers/corporateMemberSegment/CorporateMembershipSubscription";
import OrganisationBasicInformations from "../../../components/membership/corporateMembers/corporateMemberSegment/OrganisationBasicInformation";
import SLOTSAccreditationCard from "../../../components/membership/corporateMembers/corporateMemberSegment/SLOTSAccreditationCard";
import CommonServices from "../../../services/axiosServices/apiServices/CommonServices";
import {
  engagementTrackingValRules,
  organisationKeyRolesValRules,
  organizationProfileValRules,
  organizationBasicInformationValRules,
  memberSubscriptionValRules,
  siisAccountInformationValRules,
  organizationEmployeesValRules,
  financeCardValRules,
  addEditCreditNoteValRules,
  addEditCreditNoteValRules2,
  organizationBCAScopeValRules,
} from "./CorporateValidations";
import moment from "moment";
import { saveAs } from 'file-saver';
import FinanceServices from "../../../services/axiosServices/apiServices/FinanceServices";
import { convertToDecimal, isNullBoolean, isNullString } from "../../../utils/Utils";
import { monthDifference } from "../../../utils/DateUtils";
import { checkParameters } from "../../../utils/Encryption";
import { getAuthProps } from "../../../utils/AuthenticationLibrary";


export default class CreateCorporateMembers extends Component {
  constructor(props) {
    super(props);
    this.corporateMemberServices = new CorporateMemberServices();
    this.financeServices = new FinanceServices();
    this.commonServices = new CommonServices();
    this.swalServices = new SwalServices();
    this.slotMemberService = new SlotMemberService();
    this.state = {
      redirect: null,
      isDisabledFlag: false,
      isLoading: false,
      membershipTypeName: "",
      migratedStatus: "",
      CertificateMembershipNo: "",
      slotsCertificateMembershipNo: "",
      slotsMemberId: 0,
      Organisationrole: [],
      chaptercommitteerol: [],
      chapterCommitteeRole: [],
      openSection: "SIISAccountInformation",
      mrMrs: [],
      status: [],
      reasonOfLeaving: [],
      _by: [],
      source1: [],
      recordType: [],
      billingCategory: [],
      matching: [],
      programs: [],
      engagementType: [],
      emailDomain: [],
      domainArr: [],
      membershipTypes: [],
      countriesWithPresence: [],
      corporateGrossTurnovers: [],
      organizationRoleArray: [],
      corporateInternalFlags: [],
      countriesSingleDrp: [],
      corporateMemberId: 0,
      companyEmploymentSize: [],
      corporateCreditTerms: [],
      interestedChapter: [],
      getToKnowUsArr: [],
      mainGroupList: [],
      workheadList: [],
      gradeList: [],
      typeOfProjects: [],
      fulltimeEmployeeLocal: [],
      fullTimeEmployeeForeigners: [],
      knowAboutusList: [],
      annualTurnover: [],
      updatedBy: 0,
      updatedAppId: 0,
      adminName: '',
      corporateSIISAccount: {
        corporateSIISAccountId: 0,
        isAccountActive: 0,
        corporateReasonLeavingId: 0,
        corporateJoinedDate: "",
        updatedDate: "",
        updatedById: 0,
        corporateMemberRecordTypeId: 0,
        corporateMemberBillingCategoryId: 0,
        corporateMemberId: 0,
        CompanyScopeList: [],
      },
      selectedScope: {
        companyScopeMappingId: 0,
        corporateMemberId: 0,
        scopeMainGroupId: 0,
        scopeWorkheadId: 0,
        scopeGradeId: 0,
        scopeId: 0,
        mainGroup: "",
        workhead: "",
        grade: "",
      },
      TempSelectedScopeList: [],
      siisAccountInformation: {
        corporateSIISAccountId: 0,
        siisAccountId: "",
        engagementScore: 0,
        accountStatus: 1,
        corporatejoinedDate: null,
        corporateReasonLeavingId: 0,
        renewalNotes: "",
        updatedAt: null,
        createdBy: 1,
        internalFlagId: 0,
        companyDomain: "",
        quotationNeeded: false,
        sendInvoiceReminder: 0,
        creditTermsId: 0,
        stratigicMember: false,
        projectsInvolved: 0,
        isFeatured: false,
        projectName: "",
        slotsMemberId: 0,
        isSLOTSMember: false
      },
      chapterInformation: {
        workgroupMappingId: 0,
        corporateMemberId: 0,
        availableChapters: 0,
        joinedChapters: 0,
        updatedBy: 1,
        updatedAt: null
      },
      chapterList: [],
      deletedChapterList: [],
      validStateEmp: {
        isValid: true,
        error: {},
      },
      engagementTrackingList: [],
      employeeList: [],
      nameSearch: "",
      isLoaderOn: false,
      validState: {
        isValid: true,
        error: {},
      },
      validStateOrg: {
        isValid: true,
        error: {},
      },
      validStateSIISInfo: {
        isValid: true,
        error: {}
      },
      validStateChapter: {
        isValid: true,
        error: {}
      },
      validMemberSub: {
        isValid: true,
        error: {}
      },
      validSiisAccountInfo: {
        isValid: true,
        error: {}
      },
      validOrgBasicInfo: {
        isValid: true,
        error: {}
      },
      validStateFinanceCard: {
        isValid: true,
        error: {}
      },
      disableUenNumber: false,
      organizationBasicInformation: {
        companyTypeId: 0,
        organisationInformationId: 0,
        companyUEN: "",
        companyName: "",
        chinesCompanyName: "",
        incorporationDate: "",
        firstName: "",
        lastName: "",
        designation: "",
        email: "",
        businessEmail1: "",
        contactNo: "",
        companyDomain: "",
        mrMrsId: 0,
        selectedDesignation: [],
        companyAddress: "",
        companyAddress2: "",
        companyAddress3: "",
        companyActivity: [],
        countryId: 153,
        countryName: "",
        postalCode: 0,
        membershipRevenueId: 0,
        aCRACertificatePath: "",
        projectTrackRecordReportPath: "",
        jobTitle: "",
        membershipTypeId: 1,
        isActive: false,
        // postalCode: "",
        companyDescription: "",
        businessTelNo: "",
        isCompanyRegisterWithBCA: true,
        companyScopeList: [],
        businessContactNumber: "",
        projectTypeId: 0,
        fullTimeStaffLocalId: 0,
        fullTimeStaffForeignersId: 0,
        knowAboutUsId: 0,
        otherProjectForm: "",
        hearAboutIsOther: false,
        hearAboutOther: ""
      },
      characterLimit: 300,
      publicProjectsForms: [],
      designationList: [],
      countryList: [],
      companyActivity: [],
      organizationProfile: {
        organisationProfileId: 0,
        logoImage: "",
        orgGrossTurnoverId: 0,
        companyDescription: "",
        businessContactNumber: "",
        website: "",
        businessEmail: "",
        mailingAddress: "",
        mailingAddress2: "",
        mailingAddress3: "",
        countryId: 153,
        city: "",
        postalCode: "",
        linkedIn: "",
        selectedCountries: "",
        selectedSoftwares: "",
        selectedSectors: "",
        selectedServices: "",
        selectedExpertise: "",
        selectedHardwares: "",
        updatedBy: 0,
        updatedAt: "",
        selectedSectorsArr: [],
      },
      // membership Subscription Object
      membershipSubscription: {
        corporateMemberSubscriptionId: 0,
        membershipTypeId: 0,
        billingCategoryId: 1,
        IsMigratedData: false,
        subscriptionStartDate: null,
        subcriptionEndDate: null,
        updatedBy: 0,
        updatedAt: null,
        incorporationDate: "",
        membershipRevenueId: 0,
        uploadPaymentProof: "",
        comments: ""
      },
      isPaymentProofLoader: false,
      // SLOTS Accreditation Object
      SLOTSAccreditation: {
        billingCategoryId: 1,
        membershipTypeId: 5,
        slotsMemberSubscriptionId: 0,
        subscriptionStartDate: null,
        subcriptionEndDate: null,
        updatedBy: 0,
        updatedAt: null,
        certificatePath: "",
        isForRenewal: false,
        renewalUrl: "",

      },
      // Employee Detail Object
      employeeDetails: {
        mrMrsId: 1,
        individualMemberId: 0,
        lastName: "",
        firstName: "",
        email: "",
        emailText: "",
        title: "",
        designation: "",
        contactNo: "",
        corporateMemberId: 0,
        emailDomainId: 0,
        emailString: "",
        selectedDesignation: [],
      },
      // Organisation Detail Object
      organisationDetails: {
        organisationRoleId: 0,
        mrMrs: 1,
        firstName: "",
        lastName: "",
        email: "",
        contactNo: "",
        directOfficeNumber: "",
        designation: "",
        organisationRole: 0,
        corporateMemberId: 0,
        emailDomainId: 0,
        emailText: "",
        emailString: "",
        selectedDesignation: [],
        selectedOrganisationRole: [],
      },
      // Engagement Tracking Detail Object
      engagementTrackingDetails: {
        corporateEngagementTrackingId: 0,
        corporateEngagementTypeId: 0,
        corporateEngagementDate: "",
        engagementDate: "",
        engagementDescription: "",
        corporateEngagementDescription: "",
        corporateEngagementRecordedById: 0,
        corporateEngagementFollowUpById: 0,
        corporateMemberId: 0,
        updatedAt: "",
        updatedBy: 0,
      },
      // Corporate Finance Object
      corporateFinance: {
        quotationNeeded: false,
        sendInvoiceReminder: true,
        creditTermsId: 1,
        financeId: 0,
        corporateMemberId: 0,
      },
      engagemmentTrackingList: [],
      organisationRoleArray: [],
      activeProjects: [],
      futureProjects: [],
      pastProjects: [],
      govProjectsNameList: [],
      corporateFinanceInfo: [],
      corporateCreditNotePaymentInfo: {},
      cancellationReason: [],
      selectedCancellationReason: [],
      isOtherCancellationReason: false,
      addEditCreditNote: {
        membershipPaymentId: 0,
        entranceRefund: 0,
        annualRefund: 0,
        chapterRefund: 0,
        subTotalRefund: 0,
        gstRefund: 0,
        discountRefund: 0,
        totalRefund: 0,
        cancellationReason: "",
        creditNoteMonth: "",
        compareEntranceRefund: 0,
        compareAnnualRefund: 0,
        compareChapterRefund: 0,
        membershipPaymentTypeId: 0,
        slotsMemberId: 0
      },
      gstRate: 0,
      withoutGstRate: 1.07,
      addEditCreditNoteRules: [],
      validCreditNote: {
        isValid: true,
        error: {}
      },
      validCreditNote2: {
        isValid: true,
        error: {}
      },
      validStateProfile: {
        isValid: true,
        error: {},
      },
      validStateRoles: {
        isValid: true,
        error: {},
      },
      validStateBCAScope: {
        isValid: true,
        error: {},
      },
      addEditOrganisationModel: false,
      isAddEditAOrganisation: false,
      searchByEmployeeName: "",
      addEditModel: false,
      companyRegisteredWithBCAList: [],
      companyScopeRule: [
        {
          type: "require",
          message: CommonValidationMessages.SelectRequired.replace(
            "{0}",
            "BCA scope"
          ),
        },
      ]
    }
  }

  // APi Method Declaration Section Start
  componentDidMount() {
    let params = new URLSearchParams(window.location.search);

    let cookie = getAuthProps();
    if (cookie != null) {
      const adminUserId = cookie.adminUserId;
      const adminname = cookie.name;
      this.setState({ updatedBy: adminUserId, updatedAppId: 111, adminName: adminname });
    }

    let checkRead = window.location.search;
    let getRead = checkRead.split("=");
    let checkFinal = getRead[2];
    if (checkFinal === "ReadOnly") {
      this.setState({ isDisabledFlag: true });
    }
    let corporateId = params.get("")
    if (corporateId && (corporateId = checkParameters(corporateId, "Number"))) {
      if (corporateId != null && corporateId > 0) {
        this.setState({ corporateMemberId: corporateId }, () => { this.setState({ disableUenNumber: true }) });
      }
    }
    this.getAllDropdownForCorporateMember();
    this.getOrganisationRoleList(corporateId);
    this.getEmployeeList(corporateId, "");
  }
  getEmployeeList = (id, nameSearch) => {
    if (id > 0 && id != null) {
      let request = [id, nameSearch]
      this.corporateMemberServices.getEmployeeList(request).then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {

          this.setState({ employeeList: response.responseItem.responseContent });
        }
        else {
          this.swalServices.Error(response.message);
        }
        this.setState({ isLoading: false });
      })
    }
  }

  getOrganisationRoleList = (id) => {
    if (id > 0 && id != null) {
      this.corporateMemberServices.getOrganisationRoleList(id).then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {

          this.setState({ Organisationrole: response.responseItem.responseContent }, () => {
            //this.getChapterCommitteeRoles(this.state.corporateMemberId);
          });
        }
        else {
          //this.getChapterCommitteeRoles(this.state.corporateMemberId);
          this.swalServices.Error(response.message);
        }
        this.setState({ isLoading: false });
      })
    }
  }

  // All checkboxes handle change method 
  handleChangeInCheckbox(event, identity, index) {
    if (identity === "Public_Project_Forms") {

      // const value = event.target.value;
      // const name = event.target.name;
      let detail = this.state.publicProjectsForms[index];

      if (detail.label === 'Others (please specify)') {
        detail.isChecked = !detail.isChecked;
        if (detail.isChecked) {
          detail.isOthers = true;
        } else {
          detail.isOthers = false;
        }
      } else {
        detail.isChecked = !detail.isChecked;
      }

      // this.state.publicProjectsForms[index] = detail;

      this.setState((prevState) => {
        const updatedForms = [...prevState.publicProjectsForms];
        updatedForms[index] = detail;
        return { publicProjectsForms: updatedForms };
      });


      //  detail[name] = !detail[name];
      let obj = this.state.publicProjectsForms

      this.setState({ publicProjectsForms: obj });

    }
  }

  handleChangeCheckBoxFinanceCard = (e) => {
    const { name, checked } = e.target;
    let det = this.state.corporateFinance;
    det[name] = checked;
    this.setState({ corporateFinance: { ...det } });
  };

  setFilterParametersFinanceCard = (id, drpIdentity) => {
    let request = this.state.corporateFinance;

    if (drpIdentity === "CreditTerms") {
      request["creditTermsId"] = id;
      this.validateFinanceCard("creditTermsId");
    }
    this.setState({ corporateFinance: request });
  }
  setFilterParametersEmployee = (id, drpIdentity) => {
    let request = this.state.employeeDetails;

    if (drpIdentity === "mrMrsId") {
      request["mrMrsId"] = id;
    }
    this.setState({ employeeDetails: request });
  }

  addEditFinanceCardDetails = () => {
    if (this.state.corporateMemberId > 0 && this.state.corporateMemberId != null) {
      var request = this.state.corporateFinance;
      request.corporateMemberId = this.state.corporateMemberId;
      request.createdBy = 1;
      request.createdAppId = 115;
      if (this.isValidFinanceCard()) {
        this.setState({ isLoading: true });
        this.corporateMemberServices.addEditFinanceCardDetails(request).then((response) => {
          if (response.statusCode === 200 && response.responseItem != null) {
            this.swalServices.Success("Finance details updated successfully.");
          }
          else {
            this.swalServices.Error(ErrorMessage.DefaultMessage);
          }
          this.setState({ isLoading: false });
        })
      }
    } else {
      this.swalServices.Warning("Please enter SIIS corporate account information.");
    }
  }
  ///////////////////////////////////////

  getAllDropdownForCorporateMember = () => {
    this.corporateMemberServices.getAllDropdownForCorporateMember().then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {

        var status = response.responseItem.responseContent.filter(
          (drp) => drp.dropdownName === "AccountStatus"
        );

        var recordType = response.responseItem.responseContent.filter(
          (drp) => drp.dropdownName === "RecordType"
        );

        var billingCategory = response.responseItem.responseContent.filter(
          (drp) => drp.dropdownName === "BillingCategory"
        );

        var domain = response.responseItem.responseContent.filter(
          (drp) => drp.dropdownName === "Domains"
        );

        var organisationRoles = response.responseItem.responseContent.filter(
          (drp) => drp.dropdownName === "OrganisationRoles"
        );
        var membershipTypes = response.responseItem.responseContent.filter(
          (drp) => drp.dropdownName === "MembershipTypes"
        );

        // var countriesSingleDrp = response.responseItem.responseContent.filter(
        //   (drp) => drp.dropdownName === "Countries"
        // );


        var companyEmploymentSize = response.responseItem.responseContent.filter(
          (drp) => drp.dropdownName === "CompanyEmploymentSize"
        );

        var getToKnowUsArr = response.responseItem.responseContent.filter((drp) => drp.dropdownName === "GetToKnowUs")


        var domainArr = response.responseItem.responseContent.filter(
          (drp) => drp.dropdownName === "Domains"
        );
        domainArr.map(function (item) {
          delete item["dropdownName"];
          return item;
        });
        domainArr = domainArr.map((p) => ({
          id: p.id,
          name: "@" + p.name,
        }));


        organisationRoles.map(function (item) {
          delete item["dropdownName"];
          return item;
        });
        organisationRoles = organisationRoles.map((p) => ({
          value: p.id,
          label: p.name,
        }));

        var matching = response.responseItem.responseContent.filter(
          (drp) => drp.dropdownName === "Matching"
        );
        matching.map(function (item) {
          delete item["dropdownName"];
          return item;
        });
        matching = matching.map((p) => ({
          value: p.id,
          label: p.name,

        }));

        var mrMrs = response.responseItem.responseContent.filter(
          (drp) => drp.dropdownName === "MrMrs"
        );

        var countries = response.responseItem.responseContent.filter(
          (drp) => drp.dropdownName === "Countries"
        );
        countries.map(function (item) {
          delete item["dropdownName"];
          return item;
        });
        countries = countries.map((p) => ({ value: p.id, label: p.name }));

        var designation = response.responseItem.responseContent.filter((drp) => drp.dropdownName === "Designation");
        designation.map(function (item) {
          delete item["dropdownName"];
          return item;
        });
        designation = designation.map((p) => ({ value: p.id, label: p.name }));

        var companyActivity = response.responseItem.responseContent.filter((drp) => drp.dropdownName === "MainActivitiesOfCompany");
        companyActivity.map(function (item) {
          delete item["dropdownName"];
          return item;
        });
        companyActivity = companyActivity.map((p) => ({ value: p.id, label: p.name }));

        var ScopeMainGroup = response.responseItem.responseContent.filter((drp) => drp.dropdownName === "ScopeMainGroup");
        ScopeMainGroup.map(function (item) {
          delete item["dropdownName"];
          return item;
        });
        ScopeMainGroup = ScopeMainGroup.map((p) => ({ value: p.id, label: p.name }));

        var ProjectTypeList = response.responseItem.responseContent.filter((drp) => drp.dropdownName === "ProjectTypeList");
        ProjectTypeList.map(function (item) {
          delete item["dropdownName"];
          return item;
        });
        ProjectTypeList = ProjectTypeList.map((p) => ({ value: p.id, label: p.name }));

        var LocalOrPRFullTimeStaffEmployeedList = response.responseItem.responseContent.filter((drp) => drp.dropdownName === "LocalOrPRFullTimeStaffEmployeedList");
        LocalOrPRFullTimeStaffEmployeedList.map(function (item) {
          delete item["dropdownName"];
          return item;
        });
        LocalOrPRFullTimeStaffEmployeedList = LocalOrPRFullTimeStaffEmployeedList.map((p) => ({ value: p.id, label: p.name }));

        var ForeignersFullTimeStaffEmployeedList = response.responseItem.responseContent.filter((drp) => drp.dropdownName === "ForeignersFullTimeStaffEmployeedList");
        ForeignersFullTimeStaffEmployeedList.map(function (item) {
          delete item["dropdownName"];
          return item;
        });
        ForeignersFullTimeStaffEmployeedList = ForeignersFullTimeStaffEmployeedList.map((p) => ({ value: p.id, label: p.name }));

        var CorporateGetToKnowUs = response.responseItem.responseContent.filter((drp) => drp.dropdownName === "CorporateGetToKnowUs");
        CorporateGetToKnowUs.map(function (item) {
          delete item["dropdownName"];
          return item;
        });
        CorporateGetToKnowUs = CorporateGetToKnowUs.map((p) => ({ value: p.id, label: p.name, isOthers: false }));

        var PublicProjectsfromlist = response.responseItem.responseContent.filter((drp) => drp.dropdownName === "PublicProjectsfromlist");
        PublicProjectsfromlist.map(function (item) {
          delete item["dropdownName"];
          return item;
        });
        PublicProjectsfromlist = PublicProjectsfromlist.map((p) => ({ value: p.id, label: p.name, isChecked: false, isOthers: false }));

        var annualTurnoverList = response.responseItem.responseContent.filter((drp) => drp.dropdownName === "AnnualTurnOver");
        annualTurnoverList.map(function (item) {
          delete item["dropdownName"];
          return item;
        });
        annualTurnoverList = annualTurnoverList.map((p) => ({ value: p.id, label: p.name, isChecked: false, isOthers: false }));

        var ScopeWorkhead = response.responseItem.responseContent.filter((drp) => drp.dropdownName === "ScopeWorkhead");
        ScopeWorkhead.map(function (item) {
          delete item["dropdownName"];
          return item;
        });
        ScopeWorkhead = ScopeWorkhead.map((p) => ({ value: p.id, label: p.name }))

        var gradeList = response.responseItem.responseContent.filter((drp) => drp.dropdownName === "ScopeGrade");
        gradeList.map(function (item) {
          delete item["dropdownName"];
          return item;
        });
        gradeList = gradeList.map((p) => ({ value: p.id, label: p.name }));

        var EngagementType = response.responseItem.responseContent.filter((drp) => drp.dropdownName === "EngagementType");
        EngagementType.map(function (item) {
          delete item["dropdownName"];
          return item;
        });

        var _By = response.responseItem.responseContent.filter((drp) => drp.dropdownName === "By");
        _By.map(function (item) {
          delete item["dropdownName"];
          return item;
        });

        var ReasonForLeaving = response.responseItem.responseContent.filter((drp) => drp.dropdownName === "ReasonForLeaving");
        ReasonForLeaving.map(function (item) {
          delete item["dropdownName"];
          return item;
        });

        this.setState({
          status: status,
          recordType: recordType, billingCategory: billingCategory,
          mrMrs: mrMrs,
          countriesWithPresence: countries,
          organizationRoleArray: organisationRoles,
          membershipTypes: membershipTypes,
          companyEmploymentSize: companyEmploymentSize,
          getToKnowUsArr: getToKnowUsArr,
          designationList: designation,
          countryList: countries,
          companyActivity: companyActivity,
          mainGroupList: ScopeMainGroup,
          typeOfProjects: ProjectTypeList,
          fulltimeEmployeeLocal: LocalOrPRFullTimeStaffEmployeedList,
          fullTimeEmployeeForeigners: ForeignersFullTimeStaffEmployeedList,
          knowAboutusList: CorporateGetToKnowUs,
          publicProjectsForms: PublicProjectsfromlist,
          annualTurnover: annualTurnoverList,
          engagementType: EngagementType, _by: _By,
          reasonOfLeaving: ReasonForLeaving,
        }, () => {
          if (this.state.corporateMemberId > 0) {
            this.getSIISCorporateAccountInformationById(this.state.corporateMemberId);

            this.setState({ emailDomain: domain }, () =>
              this.getDomainByOrganisationId(this.state.corporateMemberId)
            );
            this.setState({ domainArr: domainArr }, () =>
              this.getDomainByOrganisationId(this.state.corporateMemberId)
            );
          }
        });
      } else {
        if (this.state.corporateMemberId > 0) {
          this.getSIISCorporateAccountInformationById(this.state.corporateMemberId);
          this.setState({ emailDomain: domain }, () =>
            this.getDomainByOrganisationId(this.state.corporateMemberId)
          );
          this.setState({ domainArr: domainArr }, () =>
            this.getDomainByOrganisationId(this.state.corporateMemberId)
          );
        }
        //this.swalServices.Error(response.message);
      }
    });
  };

  validateOrganisationProfile = (key) => {
    const returnValidState = validate(key, this.state.organizationProfile, organizationProfileValRules, this.state.validStateProfile);
    this.setState({ validStateProfile: returnValidState });
  }

  isValidOrganisationProfile = () => {
    const returnValidState = isValidForm(this.state.organizationProfile, organizationProfileValRules, this.state.validStateProfile);
    this.setState({ validStateProfile: returnValidState });
    return returnValidState.isValid;
  }

  validateOrganisationKeyRoles = (key) => {
    const returnValidState = validate(key, this.state.organisationDetails, organisationKeyRolesValRules, this.state.validStateRoles);
    this.setState({ validStateRoles: returnValidState });
  }

  isValidOrganisationKeyRoles = () => {
    const returnValidState = isValidForm(this.state.organisationDetails, organisationKeyRolesValRules, this.state.validStateRoles);
    this.setState({ validStateRoles: returnValidState });
    return returnValidState.isValid;
  }

  validateEmployeeInfo = (key, object) => {
    const returnValidState = validate(key, this.state.employeeDetails, organizationEmployeesValRules, this.state.validStateEmp);
    this.setState({ validStateEmp: returnValidState, });
  };

  isValidateAllEmployeeInfo = () => {
    const returnValidState = isValidForm(this.state.employeeDetails, organizationEmployeesValRules, this.state.validStateEmp);
    this.setState({ validStateEmp: returnValidState });
    return returnValidState.isValid;
  };

  validateFinanceCard = (key) => {
    const returnValidState = validate(key, this.state.corporateFinance, financeCardValRules, this.state.validStateFinanceCard);
    this.setState({ validStateFinanceCard: returnValidState, });
  }

  isValidFinanceCard = () => {
    const returnValidState = isValidForm(this.state.corporateFinance, financeCardValRules, this.state.validStateFinanceCard);
    this.setState({ validStateFinanceCard: returnValidState });
    return returnValidState.isValid;
  };

  // Validate BCA Scope List
  validateBCAScopeProfile = (key) => {
    const returnValidState = validate(key, this.state.selectedScope, organizationBCAScopeValRules, this.state.validStateBCAScope);
    this.setState({ validStateBCAScope: returnValidState });
  }

  isValidBCAScope = () => {
    const returnValidState = isValidForm(this.state.selectedScope, organizationBCAScopeValRules, this.state.validStateBCAScope);
    this.setState({ validStateBCAScope: returnValidState });
    return returnValidState.isValid;
  }

  addEditSIISCorporateAccountInformation = () => {
    var request = this.state.siisAccountInformation;
    request.corporateMemberId = this.state.corporateMemberId;
    request.accountStatus = request.accountStatus === 0 ? 7 : request.accountStatus;
    request.createdBy = this.state.updatedBy;// request.createdBy === 0 ? 1 : request.createdBy;
    request.createdAppId = this.state.updatedAppId; //115;
    request.companyDomain = "";
    let isValid = this.isValidSiisAccountInfo()
    if (isValid) {
      this.setState({ isLoading: true });
      this.corporateMemberServices.addEditSIISCorporateAccountInformation(request).then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          if (response.responseItem.responseContent.errorMessage === '') {
            this.swalServices.Success(SuccessMessage.Insert_Success.replace("{0}", "Corporate SIIS Information"));
            this.setState({ corporateMemberId: response.responseItem.responseContent.keyId },
              () => this.getSIISCorporateAccountInformationById(response.responseItem.responseContent.keyId));
          }
          else {
            this.swalServices.Error(ErrorMessage.Already_Exist.replace("{0}", "Corporate SIIS Information"));
          }
        }
        else {
          this.swalServices.Error(ErrorMessage.DefaultMessage);
        }
        this.setState({ isLoading: false });
      })
    }
  }

  // renewCorporateMember = () => {
  //   let request = {};
  //   let details = { ...this.state.membershipSubscription }
  //   request.corporateMemberId = this.state.corporateMemberId;
  //   request.membershipTypeId = details.membershipTypeId;
  //   request.revenueTypeId = details.membershipRevenueId;
  //   request.transactionTypeId = 2;
  //   request.membershipTenureId = 0;
  //   request.incorporationDate = details.incorporationDate;
  //   request.numberOfChapters = 0;
  //   this.setState({ isLoading: true });
  //   this.corporateMemberServices.renewCorporateMember(request).then((response) => {
  //     if (response.statusCode === 200 && response.responseItem != null) {
  //       if (response.responseItem.responseContent != null) {
  //         this.swalServices.Success("Applied for renewal successfully!");
  //         // NavigateWithData(Routes.MembershipPayment, { isRenewal: 1, }, [this.state.corporateMemberId]);
  //       }
  //     } else {
  //       this.swalServices.Error(response.message);
  //     }
  //     this.setState({ isLoading: false });
  //   });
  // }

  addEditOrganisationBasicInformation = () => {
    if (this.state.corporateMemberId) {
      var request = this.state.organizationBasicInformation;
      request.CorporateMemberId = this.state.corporateMemberId;
      request.RepresentativeFirstName = this.state.organizationBasicInformation.firstName;
      request.RepresentativeLastName = this.state.organizationBasicInformation.lastName;
      request.BusinessContactNumber = this.state.organizationBasicInformation.contactNo;
      request.BusinessEmail = this.state.organizationBasicInformation.email;
      request.companyDomain = "";
      request.createdBy = 1;
      request.createdAppId = 115;

      //this part removed by jigar
      // if (request.selectedDesignation && request.selectedDesignation.length > 0) {
      //   var arr1 = request.selectedDesignation.map(function (item) {
      //     delete item.bad;
      //     return item.label;
      //   });
      //   request.designation = arr1.join([',']);
      // } else {
      //   request.designation = "";
      // }

      if (this.isValidOrgBasicInfo()) {
        this.setState({ isLoading: true });
        this.corporateMemberServices.addEditOrganisationBasicInformation(request).then((response) => {
          if (response.statusCode === 200 && response.responseItem != null) {
            if (response.responseItem.responseContent.errorMessage === '') {
              this.swalServices.Success(SuccessMessage.Insert_Success.replace("{0}", "Organisation basic information"));
              this.getOrganisationBasicInformationById();
              this.getOrganisationRoleList(this.state.corporateMemberId);
              // this.setState({corporateMemberId : response.responseItem.responseContent.keyId}, 
              //   () => this.getSIISCorporateAccountInformationById(response.responseItem.responseContent.keyId));
            }
            else {
              this.swalServices.Error(ErrorMessage.Already_Exist.replace("{0}", "Organisation basic information"));
            }
          }
          else {
            this.swalServices.Error(ErrorMessage.DefaultMessage);
          }
          this.setState({ isLoading: false });
        })
      }
    } else {
      this.swalServices.Warning("Please enter SIIS corporate account information.");
    }
  }

  addEditOrganisationBasicInformationAdmin = (e) => {
    if (this.state.corporateMemberId) { //organizationBasicInformation.companyScopeList
      var request = {}; //this.state.organizationBasicInformation;
      request.CorporateMemberId = this.state.corporateMemberId;
      request.organisationInformationId = this.state.organizationBasicInformation.organisationInformationId;
      request.companyUEN = this.state.organizationBasicInformation.companyUEN;
      request.companyName = this.state.organizationBasicInformation.companyName;
      //request.chinesCompanyName: "",
      request.incorporationDate = this.state.organizationBasicInformation.incorporationDate;
      request.mrMrsId = this.state.organizationBasicInformation.mrMrsId;
      request.RepresentativeFirstName = this.state.organizationBasicInformation.firstName;
      request.RepresentativeLastName = this.state.organizationBasicInformation.lastName;
      request.BusinessContactNumber = this.state.organizationBasicInformation.contactNo;
      request.BusinessEmail = this.state.organizationBasicInformation.email;
      request.companyAddress = this.state.organizationBasicInformation.companyAddress;
      request.companyAddress2 = this.state.organizationBasicInformation.companyAddress2;
      request.companyAddress3 = this.state.organizationBasicInformation.companyAddress3;
      request.businessTelNo = this.state.organizationBasicInformation.businessTelNo;
      //request.businessContactNumber = this.state.organizationBasicInformation.businessContactNumber;
      request.businessEmail1 = this.state.organizationBasicInformation.businessEmail1
      request.chinessCompanyName = this.state.organizationBasicInformation.chinesCompanyName;
      request.membershipRevenueId = this.state.organizationBasicInformation.membershipRevenueId;
      request.companyTypeId = this.state.organizationBasicInformation.companyTypeId;
      request.countryId = this.state.organizationBasicInformation.countryId;
      request.countryName = this.state.organizationBasicInformation.countryName;
      request.companyDescription = this.state.organizationBasicInformation.companyDescription;
      request.postalCode = this.state.organizationBasicInformation.postalCode;
      request.designation = this.state.organizationBasicInformation.jobTitle;
      request.isCompanyRegisterWithBCA = this.state.organizationBasicInformation.isCompanyRegisterWithBCA;
      request.aCRACertificatePath = this.state.organizationBasicInformation.aCRACertificatePath;
      request.projectTractRecordReportPath = this.state.organizationBasicInformation.projectTrackRecordReportPath;
      request.directOfficeNumber = this.state.organizationBasicInformation.directOfficeNumber;
      request.companyActivity = this.state.organizationBasicInformation.companyActivity;
      request.updatedBy = this.state.updatedBy;// request.createdBy === 0 ? 1 : request.createdBy;
      request.updatedAppId = this.state.updatedAppId;

      //
      // request.companyScopeList = this.state.organizationBasicInformation.companyScopeList;
      request.companyScopeList = this.state.TempSelectedScopeList;

      request.companyDomain = "";
      request.createdBy = 1;
      request.createdAppId = 115;
      let publicProejectForms = this.state.publicProjectsForms;
      publicProejectForms.forEach(element => {
        if (element.isOthers) {
          element.publicProjectformId = element.value;
          element.publicProjectformName = this.state.organizationBasicInformation.otherProjectForm;
          //Warning //  element.isChecked = element.isChecked;

        } else {
          element.publicProjectformId = element.value;
          element.publicProjectformName = element.label;
          //Warning //  element.isChecked = element.isChecked;
        }
      });
      request.publicProjectFrom = publicProejectForms;
      request.projectTypeId = this.state.organizationBasicInformation.projectTypeId;
      request.fullTimeStaffLocalId = this.state.organizationBasicInformation.fullTimeStaffLocalId;
      request.fullTimeStaffForeignersId = this.state.organizationBasicInformation.fullTimeStaffForeignersId;
      request.knowAboutUsId = this.state.organizationBasicInformation.knowAboutUsId;
      request.hearAboutIsOther = this.state.organizationBasicInformation.hearAboutIsOther;

      request.companyActivitys = "";
      if (request.companyActivity && request.companyActivity.length > 0) {
        var arr1 = request.companyActivity.map(function (item) {
          delete item.bad;
          return item.value;
        });
        request.companyActivitys = arr1.join([',']);
      } else {
        request.companyActivitys = "";
      }


      // request.companyActivity = this.state.organizationBasicInformation.companyActivity;
      // let obj = this.state.organizationBasicInformation.companyActivity.split(',');

      // obj.forEach(element => {
      //   request.companyActivity.push(element)
      // });
      //this part removed by jigar
      // if (request.selectedDesignation && request.selectedDesignation.length > 0) {
      //   var arr1 = request.selectedDesignation.map(function (item) {
      //     delete item.bad;
      //     return item.label;
      //   });
      //   request.designation = arr1.join([',']);
      // } else {
      //   request.designation = "";
      // }

      //if (this.isValidOrgBasicInfo()) {
      this.setState({ isLoading: true });
      //request.companyScopeList = this.state.TempSelectedScopeList;
      this.corporateMemberServices.addEditOrganisationBasicInformationAdmin(request).then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          if (response.responseItem.responseContent.errorMessage === '') {

            this.swalServices.Success(SuccessMessage.Insert_Success.replace("{0}", "Organisation basic information"));
            this.getOrganisationBasicInformationById();
            this.getOrganisationRoleList(this.state.corporateMemberId);
            // this.setState({corporateMemberId : response.responseItem.responseContent.keyId}, 
            //   () => this.getSIISCorporateAccountInformationById(response.responseItem.responseContent.keyId));
          }
          else {
            this.swalServices.Error(ErrorMessage.Already_Exist.replace("{0}", "Organisation basic information"));
          }
        }
        else {
          this.swalServices.Error(ErrorMessage.DefaultMessage);
        }
        this.setState({ isLoading: false });
      })
    }
    // } else {
    //   this.swalServices.Warning("Please enter SIIS corporate account information.");
    // }
  }

  addEditCorporateMembershipSubscription = () => {

    if (this.state.corporateMemberId > 0 && this.state.corporateMemberId != null) {
      var request = this.state.membershipSubscription;
      request.corporateMemberSubscriptionId = this.state.membershipSubscription.corporateMemberSubscriptionId;
      request.membershipTypeId = this.state.membershipSubscription.membershipTypeId;
      request.billingCategoryId = this.state.membershipSubscription.billingCategoryId;
      request.subscriptionStartDate = this.state.membershipSubscription.subscriptionStartDate;
      request.subcriptionEndDate = this.state.membershipSubscription.subcriptionEndDate;
      request.corporateMemberId = this.state.corporateMemberId;
      //request.IsMigratedData = this.state.membershipSubscription.IsMigratedData;
      request.createdBy = this.state.updatedBy;// request.createdBy === 0 ? 1 : request.createdBy;
      request.createdAppId = this.state.updatedAppId;
      if (this.isValidMemberSub()) {
        this.setState({ isLoading: true });
        this.corporateMemberServices.addEditCorporateMembershipSubscription(request).then((response) => {
          if (response.statusCode === 200 && response.responseItem != null) {
            if (response.responseItem.responseContent.errorMessage === '') {
              var res = this.state.membershipSubscription;
              res.updatedAt = new Date();
              res.updatedBy = 1;
              res.corporateMemberSubscriptionId = response.responseItem.responseContent.keyId;
              this.setState({ membershipSubscription: res }, () => { this.swalServices.Success(SuccessMessage.Insert_Success.replace("{0}", "Corporate membership subscription")); });
            }
            else {
              this.swalServices.Error(ErrorMessage.Already_Exist.replace("{0}", "Corporate membership subscription"));
            }
          }
          else {
            this.swalServices.Error(ErrorMessage.DefaultMessage);
          }
          this.setState({ isLoading: false });
        })
      }
    } else {
      this.swalServices.Warning("Please enter SIIS corporate account information.");
    }
  }

  // Add Edit Employee Information Method
  addEditEmployeeInformation = () => {
    if (this.state.corporateMemberId > 0 && this.state.corporateMemberId != null) {
      let request = this.state.employeeDetails;
      request.corporateMemberId = this.state.corporateMemberId;

      // let domain = this.state.emailDomain.find(
      //   (x) => x.id === request.emailDomainId
      // );
      // request.email = `${request.emailText}@${domain.name}`;

      // if (request.selectedDesignation && request.selectedDesignation.length > 0) {
      //   var arr1 = request.selectedDesignation.map(function (item) {
      //     delete item.bad;
      //     return item.label;
      //   });
      //   request.title = arr1.join([',']);
      // } else {
      //   request.title = "";
      // }

      if (this.isValidateAllEmployeeInfo()) {
        this.setLoader(true);
        this.corporateMemberServices.addEditEmployeeInformation(request).then((response) => {

          if (
            response.statusCode === 200 &&
            response.responseItem != null &&
            response.responseItem.responseContent.errorType === "EXISTS"
          ) {

            this.swalServices.Error(
              "Member already exists with same email address."
            );
            this.setState({ addEditModel: false });
          }
          else if (response.statusCode === 200 && response.responseItem != null) {
            this.swalServices.Success(
              "Employee information inserted successfully."
            );
            this.getEmployeeList(this.state.corporateMemberId, "");
            this.getOrganisationRoleList(this.state.corporateMemberId);
            this.setState({ addEditModel: false });
          } else {
            this.swalServices.Error("Error");
          }
          this.setLoader(false);
        });
      }
    } else {
      this.swalServices.Warning("Please enter SIIS corporate account information.");
    }
  };

  addEditOraganisation = () => {
    if (this.state.corporateMemberId > 0 && this.state.corporateMemberId != null) {

      let request = this.state.organisationDetails;
      request.emailString = request.emailText + "@abc.com";
      request.corporateMemberId = this.state.corporateMemberId;

      //warning // let domain = this.state.emailDomain.find(
      //   (x) => x.id === request.emailDomainId
      // );


      // request.email = `${request.emailText}@${domain.name}`;
      // request.CompanyScopeList =   this.state.organizationBasicInformation.CompanyScopeList;
      // if (request.selectedDesignation && request.selectedDesignation.length > 0) {
      //   if (request.selectedDesignation != null && request.selectedDesignation != undefined) {
      //     var arr1 = request.selectedDesignation.map(function (item) {
      //       delete item.bad;
      //       return item.label;
      //     });
      //     request.designation = arr1.join([',']);
      //   }
      // } else {
      //   request.designation = request.designation;
      // }
      // remove multi select 
      // if (request.selectedOrganisationRole && request.selectedOrganisationRole.length > 0) {
      //   var arr1 = request.selectedOrganisationRole.map(function (item) {
      //     delete item.bad;
      //     return item.value;
      //   });
      //   request.organisationRole = arr1.join([',']);
      // } else {
      //   request.organisationRole = "";
      // }
      if (this.isValidOrganisationKeyRoles()) {
        this.setState({ isAddEditAOrganisation: true });
        this.corporateMemberServices.addEditOrganisation(request).then((response) => {
          if (response.statusCode === 200 &&
            response.responseItem != null &&
            response.responseItem.responseContent.errorType === "EXISTS") {
            this.setState({ addEditOrganisationModel: false });
            this.swalServices.Error(
              "Member already exists with same email address."
            );


          } else if (response.statusCode === 200 &&
            response.responseItem != null) {
            this.swalServices.Success(
              "Employee information inserted successfully."
            );
            this.setState({ addEditOrganisationModel: false });
            this.getOrganisationRoleList(this.state.corporateMemberId);
            this.getEmployeeList(this.state.corporateMemberId, "");
            let reset = this.state.organisationDetails;
            reset.organisationRoleId = 0;
            reset.mrMrs = "";
            reset.firstName = "";
            reset.lastName = "";
            reset.email = "";
            reset.contactNo = "";
            reset.designation = "";
            reset.organisationRole = "";
            reset.selectedDesignation = [];
            reset.emailString = "";
            reset.directOfficeNumber = ''
            this.setState({ organisationDetails: reset }, () => this.getEmployeeList(this.state.corporateMemberId, "")
            );
          } else {
            this.swalServices.Error("Error");
          }
        });
      }
    } else {
      this.swalServices.Warning("Please enter SIIS corporate account information.");
    }
  };

  addEditEngagementTracking = () => {
    let request = this.state.engagementTrackingDetails;
    request.corporateEngagementDate = request.engagementDate;
    request.corporateMemberId = this.state.corporateMemberId;
    request.corporateEngagementDescription = request.engagementDescription;
    if (this.state.corporateMemberId) {
      if (this.isValidengagement()) {
        this.setState({ isLoading: true });
        this.corporateMemberServices.addEditEngagementTracking(request).then((response) => {
          if (response.statusCode === 200 && response.responseItem != null) {
            this.getEngagementTrackingList(this.state.corporateMemberId);
            let details = this.state.engagementTrackingDetails;

            details.corporateEngagementTrackingId = 0;
            details.corporateEngagementTypeId = 0;
            details.corporateEngagementDate = "";
            details.engagementDate = "";
            details.engagementDescription = "";
            details.corporateEngagementDescription = "";
            details.corporateEngagementRecordedById = 0;
            details.corporateEngagementFollowUpById = 0;
            details.corporateMemberId = 0;

            this.swalServices.Success("Engagement inserted successfully.");
          } else {
            this.swalServices.Error(response.message);
          }
          this.setState({ isLoading: false });
        });
      }
    } else {
      this.swalServices.Warning("Please enter SIIS corporate account information.");
    }
  };

  getSIISCorporateAccountInformationById = (corporateMemberId) => {
    this.corporateMemberServices.getSIISCorporateAccountInformationById(corporateMemberId).then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        let res = { ...this.state.siisAccountInformation };
        res.corporateSIISAccountId = response.responseItem.responseContent.corporateSIISAccountId;
        res.siisAccountId = response.responseItem.responseContent.siisAccountId;
        res.engagementScore = response.responseItem.responseContent.engagementScore;
        res.accountStatus = response.responseItem.responseContent.accountStatus;
        res.corporatejoinedDate = response.responseItem.responseContent.corporateJoinedDate;
        res.corporateReasonLeavingId = response.responseItem.responseContent.corporateReasonLeavingId;
        res.renewalNotes = response.responseItem.responseContent.renewalNotes;
        res.updatedAt = response.responseItem.responseContent.updatedDate;
        res.updatedBy = response.responseItem.responseContent.updatedBy;
        res.internalFlagId = response.responseItem.responseContent.internalFlagId;
        res.companyDomain = response.responseItem.responseContent.companyDomain;
        res.isFeatured = response.responseItem.responseContent.isFeatured;
        res.projectName = response.responseItem.responseContent.projectName;
        res.slotsMemberId = response.responseItem.responseContent.slotsMemberId;
        res.isSLOTSMember = response.responseItem.responseContent.isSLOTSMember;
        this.setState({
          siisAccountInformation: res, slotsMemberId: response.responseItem.responseContent.slotsMemberId
          , CertificateMembershipNo: response.responseItem.responseContent.certificateMembershipNo
          , slotsCertificateMembershipNo: response.responseItem.responseContent.sloTsCertificateMembershipNo
          , migratedStatus: response.responseItem.responseContent.isMigratedData
        },
          () => {

            var finance = this.state.corporateFinance;
            finance.creditTermsId = response.responseItem.responseContent.creditTermsId !== null ? response.responseItem.responseContent.creditTermsId : 1;
            finance.quotationNeeded = response.responseItem.responseContent.quotationNeeded;
            finance.sendInvoiceReminder = response.responseItem.responseContent.sendInvoiceReminder;

            this.setState({ corporateFinance: finance })


            this.setState({ membershipTypeName: response.responseItem.responseContent.membershipTypeName }, () => {
              if (response.responseItem.responseContent.slotsMemberId > 0) {
                this.getSLOTSMembershipSubscriptionById();
              }
            });
          });
      }
      else {
        this.getOrganisationRoleList(this.state.corporateMemberId);

        this.swalServices.Error(response.message);
      }
      this.setState({ isLoading: false });
    })
  }

  // Get Organisation basic Information By Id Method
  getOrganisationBasicInformationById = () => {
    if (this.state.corporateMemberId > 0 && this.state.corporateMemberId != null) {

      this.corporateMemberServices.getOrganisationBasicInformationById(this.state.corporateMemberId).then((response) => {
        console.log("Organisation Basic Information :", response.responseItem);
        if (response.statusCode === 200 && response.responseItem != null) {
          if (response.responseItem.responseContent != null) {
            let res = { ...this.state.organizationBasicInformation };
            res.organisationInformationId = response.responseItem.responseContent.organisationInformationId;
            res.companyUEN = response.responseItem.responseContent.companyUEN;
            res.companyName = response.responseItem.responseContent.companyName;
            res.chinesCompanyName = response.responseItem.responseContent.chineseCompanyName;
            res.incorporationDate = response.responseItem.responseContent.incorporationDate;
            res.firstName = response.responseItem.responseContent.representativeFirstName;
            res.lastName = response.responseItem.responseContent.representativeLastName;
            res.email = response.responseItem.responseContent.businessEmail;
            res.businessEmail1 = response.responseItem.responseContent.businessEmail1;
            res.contactNo = response.responseItem.responseContent.businessContactNumber;
            res.mrMrsId = response.responseItem.responseContent.mrMrsId;
            res.companyAddress = response.responseItem.responseContent.companyAddress;
            res.companyAddress2 = response.responseItem.responseContent.companyAddress2;
            res.companyAddress3 = response.responseItem.responseContent.companyAddress3;
            // res.companyActivity = response.responseItem.responseContent.companyActivity; 
            res.countryId = response.responseItem.responseContent.countryId;
            res.postalCode = response.responseItem.responseContent.postalCode;
            res.membershipRevenueId = response.responseItem.responseContent.membershipRevenueId;
            res.aCRACertificatePath = response.responseItem.responseContent.acraCertificatePath;
            res.projectTrackRecordReportPath = response.responseItem.responseContent.projectTrackRecordReportPath;
            res.jobTitle = response.responseItem.responseContent.designation;
            res.companyDescription = response.responseItem.responseContent.companyDescription;
            res.isCompanyRegisterWithBCA = response.responseItem.responseContent.isCompanyRegisterWithBCA;
            let projectFrom = response.responseItem.responseContent.publicProjectFrom;
            if (response.responseItem.responseContent.companyActivity) {
              let array = (response.responseItem.responseContent.companyActivity !== null || response.responseItem.responseContent.companyActivity !== "") ? response.responseItem.responseContent.companyActivity.split(',') : [];
              res.companyActivity = [];
              array.forEach(topic => {
                res.companyActivity.push(this.state.companyActivity.find(topics => topics.value === Number(topic)));
              });
            }

            let publicProjectFormObj = this.state.publicProjectsForms;

            projectFrom.forEach(element => {
              //let obj = publicProjectFormObj.filter(s => s.value === element.publicProjectformId);
              if (element.isOther) {
                publicProjectFormObj.find(s => s.value === element.publicProjectformId).isChecked = true;
                publicProjectFormObj.find(s => s.value === element.publicProjectformId).isOthers = true;
                publicProjectFormObj.find(s => s.value === element.publicProjectformId).publicProjectformMappingId = element.publicProjectformMappingId;
                res.otherProjectForm = element.publicProjectformName;


              } else {
                // obj.isChecked = true
                publicProjectFormObj.find(s => s.value === element.publicProjectformId).isChecked = true;
                publicProjectFormObj.find(s => s.value === element.publicProjectformId).publicProjectformMappingId = element.publicProjectformMappingId;
              }
              this.setState({ publicProjectsForms: [...publicProjectFormObj] });
            })

            res.projectTypeId = response.responseItem.responseContent.projectTypeId;
            res.fullTimeStaffLocalId = response.responseItem.responseContent.fullTimeStaffLocalId;
            res.fullTimeStaffForeignersId = response.responseItem.responseContent.fullTimeStaffForeignersId;
            res.knowAboutUsId = response.responseItem.responseContent.knowAboutUsId;
            res.hearAboutIsOther = response.responseItem.responseContent.hearAboutIsOther;
            if (res.hearAboutIsOther === true) {
              res.hearAboutOther = response.responseItem.responseContent.hearAboutOther;
            } else {
              res.hearAboutOther = "";
            }
            this.setState({ organizationBasicInformation: res }, () => {
              this.getCompanyRegisteredWithBCAList();
            });
          }
        }
        else {
          this.swalServices.Error(response.message);
        }
        this.setState({ isLoading: false });
      })
    }
  }

  getCompanyRegisteredWithBCAList = () => {
    if (this.state.corporateMemberId > 0 && this.state.corporateMemberId != null) {
      this.corporateMemberServices.getCompanyRegisteredWithBCAList(this.state.corporateMemberId).then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          var newList = response.responseItem.responseContent;

          newList = newList.map((p) => ({
            mainGroup: p.scopeMainGroupName,
            workhead: p.scopeWorkheadName,
            grade: p.scopeGradeName,
            companyScopeMappingId: p.companyScopeMappingId,
            corporateMemberId: 0,
            scopeMainGroupId: 0,
            scopeWorkheadId: 0,
            scopeGradeId: 0,
          }));
          // this.setState({ companyRegisteredWithBCAList: response.responseItem.responseContent });
          this.setState({ companyRegisteredWithBCAList: newList });
          let data = this.state.organizationBasicInformation;
          data.companyScopeList = newList;
          this.setState({ organizationBasicInformation: data });
        }
        else {
          this.swalServices.Error(response.message);
        }
        this.setState({ isLoading: false });
      })
    }
  }

  //  Get Corporate Membership Subscription Detail By Id Method
  getCorporateMembershipSubscriptionById = () => {

    if (this.state.corporateMemberId > 0 && this.state.corporateMemberId != null) {
      this.corporateMemberServices.getCorporateMembershipSubscriptionById(this.state.corporateMemberId).then((response) => {

        if (response.statusCode === 200 && response.responseItem != null) {

          if (response.responseItem.responseContent != null) {

            let res = { ...this.state.membershipSubscription };
            res.corporateMemberSubscriptionId = response.responseItem.responseContent.corporateMemberSubscriptionId;
            res.membershipTypeId = response.responseItem.responseContent.membershipTypeId;
            res.billingCategoryId = response.responseItem.responseContent.billingCategoryId;
            res.IsMigratedData = response.responseItem.responseContent.isMigratedData;

            res.subscriptionStartDate = response.responseItem.responseContent.subscriptionStartDate;
            res.subcriptionEndDate = response.responseItem.responseContent.subcriptionEndDate;
            res.updatedBy = response.responseItem.responseContent.updatedBy;
            res.updatedAt = response.responseItem.responseContent.updatedAt;
            res.membershipRevenueId = response.responseItem.responseContent.membershipRevenueId;
            res.incorporationDate = response.responseItem.responseContent.incorporationDate;
            this.setState({ membershipSubscription: res });
          }
        }
        else {
          this.swalServices.Error(response.message);
        }
        this.setState({ isLoading: false });
      })
    }
  }

  //  Get SLOTS Membership Subscription Detail By Id Method 
  getSLOTSMembershipSubscriptionById = () => {
    if (this.state.slotsMemberId > 0 && this.state.slotsMemberId != null) {
      this.slotMemberService.getSLOTSMembershipSubscriptionById(this.state.slotsMemberId).then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          if (response.responseItem.responseContent != null) {
            let res = { ...this.state.SLOTSAccreditation };
            res.billingCategoryId = response.responseItem.responseContent.billingCategoryId;
            res.membershipTypeId = response.responseItem.responseContent.membershipTypeId;
            res.slotsMemberSubscriptionId = response.responseItem.responseContent.slotsMemberSubscriptionId;
            res.subscriptionStartDate = response.responseItem.responseContent.subscriptionStartDate;
            res.subcriptionEndDate = response.responseItem.responseContent.subcriptionEndDate;
            res.updatedBy = response.responseItem.responseContent.updatedBy;
            res.updatedAt = response.responseItem.responseContent.updatedAt;
            res.certificatePath = response.responseItem.responseContent.certificatePath;
            res.isForRenewal = response.responseItem.responseContent.isForRenewal;
            res.renewalUrl = response.responseItem.responseContent.renewalUrl;
            this.setState({ SLOTSAccreditation: res });
          }
        }
        else {
          this.swalServices.Error(response.message);
        }
        this.setState({ isLoading: false });
      })
    }
  }

  //  Get Employee Information By Id Method
  getEmployeeInfoById = (id) => {
    this.corporateMemberServices.getEmployeeInfoById(id).then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {

        if (response.responseItem.responseContent.title !== null && response.responseItem.responseContent.title !== "") {
          var array = response.responseItem.responseContent.title.split(',');
          var designation = [];
          array.forEach(topic => {
            designation.push(this.state.designationList.find(deg => deg.label === topic));
          });
        }
        this.setState({
          employeeDetails: response.responseItem.responseContent,
        }, () => {
          let data = { ...this.state.employeeDetails };
          data.selectedDesignation = designation;
          this.setState({ employeeDetails: data });
        });

        let details = response.responseItem.responseContent;
        var fields = details.email.split("@");
        details.emailText = fields[0];
        details.emailDomainId = details.corporateMemberId;
        this.setState({ organisationRoleDetail: details });
      } else {
        // this.swalServices.Error(response.message);
      }
    });
  };

  getCorporateOrganisationRoleById = (id) => {
    this.corporateMemberServices.getCorporateOrganisationRoleById(id).then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {

        if (response.responseItem.responseContent.designation !== null && response.responseItem.responseContent.designation !== "") {
          var array = response.responseItem.responseContent.designation.split(',');
          var designation = [];
          array.forEach(topic => {
            designation.push(this.state.designationList.find(deg => deg.label === topic));
          });
        }

        if (response.responseItem.responseContent.organisationRole !== null && response.responseItem.responseContent.organisationRole !== "") {
          var array1 = response.responseItem.responseContent.organisationRole.split(',');
          var orgRoleArr = [];
          array1.forEach(topic => {
            let found = this.state.organizationRoleArray.find(deg => deg.value === Number(topic) ? Number(topic) : 0);
            if (found) {
              orgRoleArr.push(found);
            }
          });
        } this.setState(
          { organisationDetails: response.responseItem.responseContent },
          () => {
            let data = { ...this.state.organisationDetails };
            data.selectedDesignation = designation;
            data.selectedOrganisationRole = orgRoleArr;
            this.setState({ organisationDetails: data });
          }
        );
        let details = response.responseItem.responseContent;
        var fields = details.email.split("@");
        details.emailText = fields[0];
        details.emailDomainId = details.corporateMemberId;
        this.setState({ organisationDetails: details });
      } else {
        this.swalServices.Error(response.message);
      }
    });
  };

  getEngagementTrackingById = (id) => {
    this.corporateMemberServices.getEngagmentDetailsById(id).then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        this.setState({
          engagementTrackingDetails: response.responseItem.responseContent,
        });
      } else {
        //this.swalServices.Error(response.message);
      }
    });
  };

  getEngagementTrackingList = (id) => {
    if (id > 0 && id != null) {
      this.corporateMemberServices.getEngagementTrackingList(id).then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          if (response.responseItem.responseContent.length > 0) {
            var updatedDate = new Date(Math.max(...response.responseItem.responseContent.map(e => new Date(e.lastUpdatedDate))));
            //const found = response.responseItem.responseContent.find(element => new Date(element.lastUpdatedDate) === updatedDate);
            var engagement = this.state.engagementTrackingDetails;
            engagement.lastUpdatedDate = updatedDate;
            engagement.updatedAt = updatedDate;
            engagement.updatedBy = 1;
            this.setState({
              engagemmentTrackingList: response.responseItem.responseContent,
            }, () => this.setState({ engagementTrackingDetails: engagement }));
          }
        } else {
          this.swalServices.Error(response.message);
        }
      });
    }
  };

  deleteOrganisationRole = (id) => {
    if (id > 0 && id != null) {
      let request = this.state.organisationDetails;
      request.organisationRoleId = id;
      this.swalServices.Confirm(CommonSuccessMessages.Confirm_Question.replace("{0}", "Organisation Role"), CommonSuccessMessages.Confirm_Delete.replace("{0}", "Organisation Role"),
        CommonSuccessMessages.Yes_Text, CommonSuccessMessages.No_Text).then((response) => {
          if (response) {
            this.corporateMemberServices.deleteCorporateOrganisationRole(request).then((response) => {
              if (response.statusCode === 200 && response.responseItem != null) {
                this.swalServices.Success(
                  "Organisation role deleted successfully."
                );
                let reset = this.state.organisationDetails;
                reset.organisationRoleId = 0;
                reset.mrMrs = "";
                reset.firstName = "";
                reset.lastName = "";
                reset.email = "";
                reset.contactNo = "";
                reset.designation = "";
                reset.organisationRole = "";
                reset.emailString = "";
                this.setState({ organisationDetails: reset });
                this.getOrganisationRoleList(this.state.corporateMemberId);
                this.setState({ addEditOrganisationModel: false })
              } else {
                //this.swalServices.Error(response.message);
              }
            });
          }
        });
    }
  };

  saveMainImage = (file, keyId, imageIdentity) => {
    this.setState({ isLoaderEnabled: true });
    file.storagePath = "CorporateMembers";
    this.commonServices.storeBase64Image(file).then((response) => {
      if (response != null) {
        this.swalServices.Success("File Uploaded");
        if (file.storagePath === "CorporateMembers") {
          let detail = this.state.organizationProfile;
          detail["logoImage"] = response.fileName;
          this.setState({ organizationProfile: { ...detail } }, () => this.setState({ isLoaderEnabled: false }));
        }
      }
      else {
        this.swalServices.Error(response.message);
      }
    })
  }

  onEditEmployee = (id) => {
    this.getEmployeeInfoById(id);
  };

  openSection = (section) => {
    this.setState({ openSection: this.state.openSection === section ? "" : section, });
    if (section === "SIISAccountInformation") {
      this.getSIISCorporateAccountInformationById(this.state.corporateMemberId);
    } else if (section === "OrganisationBasicInformation") {
      this.getOrganisationBasicInformationById();
    }
    else if (section === "OrganisationProfile") {
      // this.getOrganisationProfileById();
    }
    else if (section === "CorporateSubscription") {
      this.getCorporateMembershipSubscriptionById();
    }
    else if (section === "SLOTSMember") {
      if (this.state.siisAccountInformation.slotsMemberId > 0) {
        this.getSLOTSMembershipSubscriptionById();
      }
    }
    else if (section === "GGBSMember") {
      // this.getCommunicationById();
    }
    else if (section === "EngagementTracking") {
      this.getEngagementTrackingList(this.state.corporateMemberId);
    } else if (section === "Employees") {
      this.getEmployeeList(this.state.corporateMemberId, "");
    }
    else if (section === "FinancialRecords") {
      this.getCorporateFinancialInfo(this.state.corporateMemberId);
    }
  };

  editOrganisationRole = (value, e) => {
    if (value > 0) {
      this.getCorporateOrganisationRoleById(value);
    }
  };

  onEditEngagement = (id) => {
    this.getEngagementTrackingById(id);
  };

  // Start All validation Method 
  // Organisation Role Validation
  validateOrganisationRole = (key, object) => {
    const returnValidState = validate(key, this.state.organisationDetails, this.state.organisationRoleValidationRule, this.state.validStateOrg);
    this.setState({ validStateOrg: returnValidState, });
  };

  isValidateAllOrganisationRoles = () => {
    const returnValidState = isValidForm(this.state.organisationDetails, this.state.organisationRoleValidationRule, this.state.validStateOrg);
    this.setState({ validStateOrg: returnValidState });
    return returnValidState.isValid;
  };
  // Organisation Role Validation

  // Engagement tracking Validation
  engagementValidate = (key, object) => {
    const returnValidState = validate(key, this.state.engagementTrackingDetails, engagementTrackingValRules, this.state.validState);
    this.setState({ validState: returnValidState, });
  };

  isValidengagement = () => {
    const returnValidState = isValidForm(this.state.engagementTrackingDetails, engagementTrackingValRules, this.state.validState);
    this.setState({ validState: returnValidState });
    return returnValidState.isValid;
  };
  //  End engagement tracking Validation

  // Corporate Membership Subscription Validation
  memberSubValidate = (key, object) => {
    const returnValidState = validate(key, this.state.membershipSubscription, memberSubscriptionValRules, this.state.validMemberSub);
    this.setState({ validMemberSub: returnValidState, });
  };

  isValidMemberSub = () => {
    let newRules = memberSubscriptionValRules;
    if (this.state.membershipSubscription.corporateMemberSubscriptionId === 0) {
      newRules.uploadPaymentProof = [
        {
          type: "require",
          message: "Please upload payment proof",
        },
      ]
    };

    if (this.state.membershipSubscription.comments === "" && this.state.membershipSubscription.uploadPaymentProof !== "") {

      newRules.comments = [
        {
          type: "require",
          message: "Please Enter Comments",
        },
      ]
    };
    const returnValidState = isValidForm(this.state.membershipSubscription, newRules, this.state.validMemberSub);
    this.setState({ validMemberSub: returnValidState });
    return returnValidState.isValid;
  };
  //End Corporate Membership Subscription validation

  // Siis account information Validation 
  siisAccountInfoValidate = (key, object) => {
    const returnValidState = validate(key, this.state.siisAccountInformation, siisAccountInformationValRules, this.state.validSiisAccountInfo);
    this.setState({ validSiisAccountInfo: returnValidState, });
  };

  isValidSiisAccountInfo = () => {
    const returnValidState = isValidForm(this.state.siisAccountInformation, siisAccountInformationValRules, this.state.validSiisAccountInfo);
    this.setState({ validSiisAccountInfo: returnValidState });
    return returnValidState.isValid;
  };
  //End siis account information validation

  // organisation basic information Validation 
  orgBasicInfoValidate = (key, object) => {
    const returnValidState = validate(
      key,
      this.state.organizationBasicInformation, organizationBasicInformationValRules, this.state.validOrgBasicInfo);
    this.setState({ validOrgBasicInfo: returnValidState, });
  };

  isValidOrgBasicInfo = () => {
    let basicInfoAllValidRules = { ...organizationBasicInformationValRules };
    if (this.state.organizationBasicInformation.companyScopeList.length <= 0) {
      if (this.state.organizationBasicInformation.isCompanyRegisterWithBCA === true) {
        basicInfoAllValidRules.companyScopeList = this.state.companyScopeRule;
      }
    }
    const returnValidState = isValidForm(this.state.organizationBasicInformation, basicInfoAllValidRules, this.state.validOrgBasicInfo);
    this.setState({ validOrgBasicInfo: returnValidState });
    return returnValidState.isValid;
  };
  //End organisation basic information validation

  //Start corporate basic Information Validation
  validateCorporateBasic = (key, object) => {
    const returnValidState = validate(key, this.state.corporateBasicInformation, this.state.corporateBasicValidationRule, this.state.validState);
    this.setState({ validState: returnValidState, });
  };

  isValiCorporateBasic = () => {
    const returnValidState = isValidForm(this.state.corporateBasicInformation, this.state.corporateBasicValidationRule, this.state.validState);
    this.setState({ validState: returnValidState });
    return returnValidState.isValid;
  };
  // /End corporate basic Information Validation 
  // End All validation methods

  getDomainByOrganisationId = (id) => {
    // var domainId = this.state.emailDomain.filter(domain => domain.id === id);
    // this.setState({emailDomain : domainId});
    if (id > 0) {
      var domainId = this.state.emailDomain.find((domain) => domain.id === id);
      if (domainId) {
        let setDomain = this.state.employeeDetails;
        let setDomainOrg = this.state.organisationDetails;
        if (domainId.id && domainId.id > 0) {
          setDomain.emailDomainId = domainId.id;
          setDomainOrg.emailDomainId = domainId.id;
          this.setState({
            employeeDetails: setDomain,
            organisationDetails: setDomain,
          });
        }
      }
    }
  };

  handleReset = () => {
    let reset = {
      ...this.state.organisationDetails,
      organisationRoleId: 0,
      mrMrs: "",
      firstName: "",
      lastName: "",
      directOfficeNumber: "",
      contactNo: "",
      designation: "",
      organisationRole: "",
      selectedDesignation: [],
      email: "",
    };
    let res = this.state.validStateOrg;
    res.isValid = true;
    res.error = {};
    this.setState({ validStateOrg: res });
    this.setState({ organisationDetails: reset });
  };

  handleResetScope = () => {
    let reset = { ...this.state.selectedScope };
    reset.companyScopeMappingId = 0;
    reset.corporateMemberId = 0;
    reset.scopeMainGroupId = 0;
    reset.scopeWorkheadId = 0;
    reset.scopeGradeId = 0;
    reset.scopeId = 0;
    reset.mainGroup = "";
    reset.workhead = "";
    reset.grade = "";
    this.setState({ selectedScope: reset });
  }

  setFilterParameters = (id, drpIdentity) => {
    let engagement = this.state.engagementTrackingDetails;
    let basicInfo = this.state.organizationBasicInformation;
    let siisInfo = this.state.siisAccountInformation;
    let chapter = this.state.chapterInformation;
    let orgProfile = this.state.organizationProfile;
    let subscription = this.state.membershipSubscription;
    let org = this.state.organisationDetails;
    let cancel_reason = this.state.cancellationReason;
    if (drpIdentity === "updatedById") {
      siisInfo["createdBy"] = id
    }

    if (drpIdentity === "corporateReasonLeaving") {
      siisInfo["corporateReasonLeavingId"] = id;
      // this.validateCorporateBasic("corporateReasonLeavingId");
    }
    else if (drpIdentity === "corporateInternalFlags") {
      siisInfo["internalFlagId"] = id;
      // this.validateCorporateBasic("corporateReasonLeavingId");
    }
    else if (drpIdentity === "Chapters") {
      chapter["workgroupMappingId"] = id;
      this.chapterInfoValidate("workgroupMappingId")
      // this.validateCorporateBasic("corporateReasonLeavingId");
    }
    else if (drpIdentity === "grossTurnOver") {
      orgProfile["orgGrossTurnoverId"] = id;
      this.validateOrganisationProfile("orgGrossTurnoverId");
    }
    else if (drpIdentity === "orgCountry") {
      orgProfile["countryId"] = id;
      this.validateOrganisationProfile("countryId");
    }
    else if (drpIdentity === "MembershipTypesSubscription") {
      subscription["membershipTypeId"] = id;
      this.memberSubValidate("membershipTypeId");
    }
    else if (drpIdentity === "EngagementType") {
      engagement["corporateEngagementTypeId"] = id;
      this.engagementValidate("corporateEngagementTypeId");
    }
    else if (drpIdentity === "RecordedBy") {
      engagement["corporateEngagementRecordedById"] = id;
      this.engagementValidate("corporateEngagementRecordedById");
    }
    else if (drpIdentity === "FollowUpBy") {
      engagement["corporateEngagementFollowUpById"] = id;
      // this.engagementValidate("corporateEngagementFollowUpById");
    }
    else if (drpIdentity === "Org_MrMrs") {
      org["mrMrs"] = id;
      // this.validateOrganisationRole("mrMrs");
      this.validateOrganisationKeyRoles("mrMrs");
    }
    else if (drpIdentity === "Org_EmailDomain") {
      org["emailDomainId"] = id;
      //this.validateField("membershipTypeId");
    }
    else if (drpIdentity === "Org_OrganisationRoles") {
      org["organisationRole"] = id;
      this.validateOrganisationKeyRoles("organisationRole");
      // this.validateOrganisationRole("organisationRole");
    }
    else if (drpIdentity === "NamePrefix") {
      basicInfo["mrMrsId"] = id;
      this.orgBasicInfoValidate("mrMrsId");
    }
    else if (drpIdentity === "cancel_reason") {
      //let othersArr = cancel_reason.filter((x) => x.id == id).find(({ name }) => name === "Others");
      //this.setState({ isOtherCancellationReason: othersArr !== null && othersArr !== undefined ? true : false });
      let data = { ...this.state.addEditCreditNote }
      let details = { ...this.state.corporateCreditNotePaymentInfo }
      let findData = cancel_reason.filter((x) => x.id === id);
      if (findData.length > 0) {
        details.cancellationReason = findData[0].id;
        if (findData[0].name === "Others") {
          data.cancellationReason = '';
          this.setState({ isOtherCancellationReason: true });
          this.validFinanceCreditNoteForOtherReason("cancellationReason", data);
        } else {
          data.cancellationReason = findData[0].name;
          this.setState({ isOtherCancellationReason: false });
        }
      } else {
        data.cancellationReason = '';
        details.cancellationReason = 0;
        this.setState({ isOtherCancellationReason: false, corporateCreditNotePaymentInfo: details });
      }
      this.validateFinanceCreditNote("cancellationReason", details);
      this.setState({ addEditCreditNote: data, corporateCreditNotePaymentInfo: details });
    }
    this.setState({
      siisAccountInformation: siisInfo, organizationProfile: orgProfile, chapterInformation: chapter,
      membershipSubscription: subscription, engagementTrackingDetails: engagement,
      organisationDetails: org
    });
  };

  getDropdownDataOfWorkheadAndGrade = (id, identity) => {
    let request = {};
    request.id = id;
    request.dropdownName = identity;
    this.corporateMemberServices.getDropdownDataOfWorkheadAndGrade(request).then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        //let data = response.responseItem.responseContent;
        if (identity === 'Workhead') {
          var ScopeWorkhead = response.responseItem.responseContent;
          ScopeWorkhead = ScopeWorkhead.map((p) => ({ value: p.id, label: p.name }));
          this.setState({ workheadList: ScopeWorkhead });
        } else if (identity === 'Grade') {
          var gradeList = response.responseItem.responseContent;
          gradeList = gradeList.map((p) => ({ value: p.id, label: p.name }));
          this.setState({ gradeList: gradeList });
        }
      }
    })
  }

  // Handle All Dropdown for Corporate Member
  handleChangeMultiDropdown(selected, drpIdentity) {
    var profile = this.state.organizationProfile;
    if (drpIdentity === "countriesWithPresence") {
      profile.selectedCountriesArr = selected;
      this.setState({ corporateBasicInformation: profile });
    } else if (drpIdentity === "Designation_KeyRoles") {
      let detail = this.state.organisationDetails;
      detail.selectedDesignation = selected;
      this.setState({ organisationDetails: detail });
    } else if (drpIdentity === "Country") {
      let detail = this.state.organizationBasicInformation;
      detail.countryId = selected.value;
      detail.countryName = selected.label;
      this.validateOrganisationProfile("countryId");
      this.setState({ organizationBasicInformation: detail }, () => this.orgBasicInfoValidate("countryId"));
    } else if (drpIdentity === "Company_Activity") {
      let detail = { ...this.state.organizationBasicInformation };
      detail.companyActivity = selected;
      this.setState({ organizationBasicInformation: detail }, () => this.orgBasicInfoValidate("companyActivity"));
    } else if (drpIdentity === "Main_Group") {
      let profile = { ...this.state.selectedScope };
      profile.scopeMainGroupId = selected.value;
      profile.mainGroup = selected.label
      this.setState({ selectedScope: profile },
        () => this.validateBCAScopeProfile("scopeMainGroupId"),
        this.getDropdownDataOfWorkheadAndGrade(selected.value, "Workhead"),
      );
    } else if (drpIdentity === "Workhead") {
      let profile = { ...this.state.selectedScope };
      profile.scopeWorkheadId = selected.value;
      profile.workhead = selected.label;
      this.setState({ selectedScope: profile },
        () => this.validateBCAScopeProfile("scopeWorkheadId"),
        this.getDropdownDataOfWorkheadAndGrade(selected.value, "Grade")
      );
    } else if (drpIdentity === "Grade") {
      let profile = { ...this.state.selectedScope };
      profile.scopeGradeId = selected.value;
      profile.grade = selected.label;
      this.setState({ selectedScope: profile },
        () => this.validateBCAScopeProfile("scopeGradeId"),
      );
    } else if (drpIdentity === "Project_Type") {
      let profile = { ...this.state.organizationBasicInformation };
      profile.projectTypeId = selected.value;
      this.setState({ organizationBasicInformation: profile }, () => this.orgBasicInfoValidate("projectTypeId")
      );
    } else if (drpIdentity === "Employee_Local") {
      let profile = { ...this.state.organizationBasicInformation };
      profile.fullTimeStaffLocalId = selected.value;
      this.setState({ organizationBasicInformation: profile }, () => this.orgBasicInfoValidate("fullTimeStaffLocalId")
      );
    } else if (drpIdentity === "Employee_Foreginrs") {
      let profile = { ...this.state.organizationBasicInformation };
      profile.fullTimeStaffForeignersId = selected.value;
      this.setState({ organizationBasicInformation: profile }, () => this.orgBasicInfoValidate("fullTimeStaffForeignersId")
      );
    } else if (drpIdentity === "Know_About_Us") {
      let profile = { ...this.state.organizationBasicInformation };
      profile.knowAboutUsId = selected.value;
      this.setState({ organizationBasicInformation: profile }, () => this.orgBasicInfoValidate("knowAboutUsId")
      );
    } else if (drpIdentity === "Annual_Turnover") {
      let profile = { ...this.state.organizationBasicInformation };
      profile.membershipRevenueId = selected.value;
      this.setState({ organizationBasicInformation: profile }
        , () => this.orgBasicInfoValidate("membershipRevenueId")
      );
    } else if (drpIdentity === "Designation_KeyRoles") {
      let profile = { ...this.state.organisationDetails };
      profile.selectedDesignation = selected;
      this.setState({ organisationDetails: profile });
    } else if (drpIdentity === "Org_OrganisationRoles") {
      let profile = { ...this.state.organisationDetails };
      //profile.selectedOrganisationRole = selected;
      profile.organisationRole = selected.value;
      this.setState({ organisationDetails: profile },
        () => this.validateOrganisationKeyRoles(("organisationRole")));
    }
  }

  handleChangeCheckBoxSIIS = (e) => {
    const { name, checked } = e.target;
    let det = this.state.siisAccountInformation;
    det[name] = checked;
    this.setState({ siisAccountInformation: { ...det } });
  };

  handleChange(event, identity) {

    const name = event.target.name;
    var value = '';
    if (identity === "EngagementTracking" && name === 'engagementDescription') {
      value = event.target.value;
    } else {
      value = event.target.value.toUpperCase();

    }

    if (identity === "SIISInformation") {
      let detail = this.state.siisAccountInformation;
      detail[name] = value;
      this.setState({ siisAccountInformation: { ...detail } });
    }
    if (identity === "BasicInformation") {
      let detail = this.state.organizationBasicInformation;
      detail[name] = value;
      if (name === "email") {
        let fields = detail.email.split("@");
        detail["companyDomain"] = fields[1];
      }
      this.setState({ organizationBasicInformation: { ...detail } });
    }
    if (identity === "OrganizationProfile") {
      let detail = this.state.organizationProfile;
      detail[name] = value;
      this.setState({ organizationProfile: { ...detail } });
    }
    else if (identity === "EngagementTracking") {
      let detail = this.state.engagementTrackingDetails;
      detail[name] = value;
      this.setState({ engagementTrackingDetails: { ...detail } });
    }
    else if (identity === "MemebershipSubscription") {
      let detail = this.state.corporateMemberSubscription;
      detail[name] = value;
      this.setState({ corporateMemberSubscription: { ...detail } });
    }
    else if (identity === "IndividualContribution") {
      let detail = this.state.contributionDetails;
      detail[name] = value;
      this.setState({ contributionDetails: { ...detail } });
    }
    else if (identity === "Employee") {
      let detail = this.state.employeeDetails;
      detail[name] = value;
      if (name === "emailText") {
        detail["emailString"] = value + "@abc.com";
        this.validateEmployeeInfo("emailString");
      }
      this.setState({ employeeDetails: { ...detail } });
    }
    else if (identity === "Organisation") {
      let detail = this.state.organisationDetails;
      detail[name] = value;
      this.setState({ organisationDetails: { ...detail } });
    } else if (identity === "CreditNoteOthers") {
      let detail = this.state.addEditCreditNote;
      detail[name] = value;
      this.setState({ addEditCreditNote: { ...detail } });
    }
    else if (identity === "CreditNote") {
      let detail = { ...this.state.addEditCreditNote };
      let val = value;
      let usedMonth = 0;
      let totalMonth = monthDifference(this.state.corporateCreditNotePaymentInfo.subcriptionEndDate, this.state.corporateCreditNotePaymentInfo.subscriptionStartDate, false) + 1;
      //  let data = { ...this.state.corporateCreditNotePaymentInfo };
      if (name === "creditNoteMonth") {
        if (detail.paymentStatus !== 'Pending') {
          detail[name] = val;
          usedMonth = monthDifference(detail.creditNoteMonth, this.state.corporateCreditNotePaymentInfo.subscriptionStartDate, false);
          let paidMonth = ((totalMonth - usedMonth) / totalMonth)

          // detail.entranceRefund = this.state.corporateCreditNotePaymentInfo.entranceFee;
          detail.annualRefund = this.state.corporateCreditNotePaymentInfo.annualFee;
          // detail.chapterRefund = this.state.corporateCreditNotePaymentInfo.addtionalChapterFee;

          // detail.entranceRefund = parseFloat((paidMonth * detail.entranceRefund).toFixed(2));
          detail.annualRefund = parseFloat((paidMonth * detail.annualRefund).toFixed(2));
          // detail.chapterRefund = parseFloat((paidMonth * detail.chapterRefund).toFixed(2));

          detail.subTotalRefund = parseFloat(detail.annualRefund).toFixed(2);
          detail.gstRefund = parseFloat((parseFloat(detail.subTotalRefund) * (this.state.gstRate / 100)).toFixed(2));
          detail.totalRefund = parseFloat((parseFloat(detail.subTotalRefund) + parseFloat(detail.gstRefund) - parseFloat(detail.discountRefund)).toFixed(2));
        }
      }
      else if ((val = convertToDecimal(value)) || val === 0) {
        // if(data.entranceFee < val ){
        detail[name] = val;
        this.validFinanceCreditNoteForOtherReason(name, detail);
        // }
        //detail[name] = val;
        detail.subTotalRefund = parseFloat((parseFloat(detail.entranceRefund) + parseFloat(detail.annualRefund) + parseFloat(detail.chapterRefund)).toFixed(2));
        detail.gstRefund = parseFloat((parseFloat(detail.subTotalRefund) * (this.state.gstRate / 100)).toFixed(2));
        detail.totalRefund = parseFloat((parseFloat(detail.subTotalRefund) + parseFloat(detail.gstRefund) - parseFloat(detail.discountRefund)).toFixed(2));
        detail.cancellationReason = value;
      }
      this.setState({ addEditCreditNote: { ...detail } });
    }
    else if (identity === "comments") {

      let detail = this.state.membershipSubscription;
      detail[name] = value;
      this.setState({ membershipSubscription: { ...detail } });
    }
  }

  // Get Corporate Finance Inforamtion Method
  getCorporateFinancialInfo = (corporateMemberId) => {
    if (corporateMemberId > 0 && corporateMemberId != null) {
      this.corporateMemberServices.getCorporateFinancialInfo(corporateMemberId).then((response) => {
        if (response.statusCode === 200 && response.responseItem.responseContent != null) {
          if (response.responseItem.responseContent.length > 0) {
            this.setState({ corporateFinanceInfo: response.responseItem.responseContent });
          }
          else {
            this.swalServices.Alert('No records found.');
          }
        } else {
          this.swalServices.Error(response.message);
        }
        this.setState({ isLoading: false });
      });
    } else {

    }
  };

  getReceiptAndCertificate = (MembershipPaymentId) => {
    if (MembershipPaymentId > 0 && MembershipPaymentId != null) {
      //let request = MembershipPaymentId;

      this.corporateMemberServices.getReceiptAndCertificate(MembershipPaymentId).then((response) => {
        if (response.responseItem !== null && response.statusCode === 200) {
          this.swalServices.Success("Certificate & Receipt send to user successfully.");
        } else {
          this.swalServices.Error(response.message);
        }
      });
    } else {

    }
  }

  getCreditNoteInfoForCorporateMember = (membershipPaymentId, paymentStatus, isReceipt) => {

    if (this.state.corporateMemberId > 0 && membershipPaymentId > 0) {
      let request = [this.state.corporateMemberId, membershipPaymentId];
      this.financeServices.getCreditNoteInfoForCorporateMember(request).then((response) => {
        if (response.statusCode === 200 && response.responseItem.responseContent != null) {
          this.setState({ corporateCreditNotePaymentInfo: response.responseItem.responseContent });
          let creditNote = { ...this.state.addEditCreditNote };
          if (isNullString(paymentStatus) === 'Pending' && isNullBoolean(isReceipt) === false) {
            creditNote.entranceRefund = 0
            creditNote.annualRefund = 0
            creditNote.chapterRefund = 0;
            creditNote.subTotalRefund = 0;
            creditNote.gstRefund = 0;
            creditNote.discountRefund = 0;
            creditNote.totalRefund = response.responseItem.responseContent.paidPriceByReconciliation
              ? response.responseItem.responseContent.paidPriceByReconciliation : 0;
            creditNote.compareEntranceRefund = 0;
            creditNote.compareAnnualRefund = 0;
            creditNote.compareChapterRefund = 0;
          } else {
            creditNote.entranceRefund = response.responseItem.responseContent.entranceFee;
            creditNote.annualRefund = response.responseItem.responseContent.annualFee;
            creditNote.chapterRefund = response.responseItem.responseContent.addtionalChapterFee;
            creditNote.subTotalRefund = response.responseItem.responseContent.subTotalAmount;
            creditNote.gstRefund = response.responseItem.responseContent.gstAmount;
            creditNote.discountRefund = response.responseItem.responseContent.discountedAmount;
            creditNote.totalRefund = response.responseItem.responseContent.totalAmount;
            creditNote.compareEntranceRefund = response.responseItem.responseContent.entranceFee;
            creditNote.compareAnnualRefund = response.responseItem.responseContent.annualFee;
            creditNote.compareChapterRefund = response.responseItem.responseContent.addtionalChapterFee;
          }
          creditNote.membershipPaymentTypeId = response.responseItem.responseContent.membershipPaymentTypeId;
          creditNote.creditNoteMonth = response.responseItem.responseContent.subscriptionStartDate;// moment();
          creditNote.paymentStatus = paymentStatus;
          creditNote.slotsMemberId = response.responseItem.responseContent.slotsMemberId ? response.responseItem.responseContent.slotsMemberId : 0;
          this.setState({ addEditCreditNote: creditNote, gstRate: response.responseItem.responseContent.gstRate });
        }
        else {
          this.swalServices.Error(response.message);
        }
      });
    }
  };

  getCancellationReasonForCreditNote = () => {
    this.financeServices.getCancellationReasonForCreditNote().then((response) => {
      if (response.statusCode === 200 && response.responseItem.responseContent != null) {
        let cancelReason = response.responseItem.responseContent;
        this.setState({ cancellationReason: cancelReason });
      }
      else {
        this.swalServices.Error(response.message);
      }
    });
  }

  isValidFinanceCreditNote = () => {
    const newValidState = isValidForm(this.state.corporateCreditNotePaymentInfo, addEditCreditNoteValRules, this.state.validCreditNote);
    this.setState({ validCreditNote: newValidState });
    return newValidState.isValid;
  }

  validateFinanceCreditNote = (key, providedState) => {
    providedState = providedState ? providedState : this.state.corporateCreditNotePaymentInfo;
    const newValidState = validate(key, providedState, addEditCreditNoteValRules, this.state.validCreditNote);
    this.setState({ validCreditNote: newValidState });
  }

  isValidFinanceCreditNoteForOtherReason = () => {
    const newValidState = isValidForm(this.state.addEditCreditNote, addEditCreditNoteValRules2, this.state.validCreditNote2);
    this.setState({ validCreditNote2: newValidState });
    return newValidState.isValid;
  }

  validFinanceCreditNoteForOtherReason = (key, providedState) => {
    providedState = providedState ? providedState : this.state.addEditCreditNote;
    const newValidState = validate(key, providedState, addEditCreditNoteValRules2, this.state.validCreditNote2);
    this.setState({ validCreditNote2: newValidState });
  }

  addFinanceCreditNote = () => {
    if (this.isValidFinanceCreditNote()) {
      if (this.isValidFinanceCreditNoteForOtherReason()) {
        this.setState({ isLoading: true });
        let cookie = getAuthProps();
        const adminUserId = (cookie && cookie.adminUserId) ? cookie.adminUserId : '';

        let request = {
          corporateMemberId: this.state.corporateMemberId,
          bookingId: this.state.addEditCreditNote.membershipPaymentId,
          isCorporateMember: (this.state.addEditCreditNote.slotsMemberId > 0 ? false : true),
          creditNoteMonth: this.state.addEditCreditNote.creditNoteMonth,
          creditNoteTotalRefund: this.state.addEditCreditNote.totalRefund,
          creditNoteStatusId: 1,
          creditNoteItem: [],
          creditNoteCancellationReason: this.state.addEditCreditNote.cancellationReason,
          CreatedAppId: 114,
          CreatedAt: moment(),
          CreatedBy: adminUserId,
          creditSubTotalAmount: this.state.addEditCreditNote.subTotalRefund,
          creditNoteGSTAmount: this.state.addEditCreditNote.gstRefund,
          creditNoteDiscountAmount: this.state.addEditCreditNote.discountRefund ? this.state.addEditCreditNote.discountRefund : 0,
          membershipPaymentTypeId: this.state.addEditCreditNote.membershipPaymentTypeId,
          slotsMemberId: this.state.addEditCreditNote.slotsMemberId
        }
        let creditNoteItemList = [];
        let stardDate = this.state.corporateCreditNotePaymentInfo.subscriptionStartDate;
        let endDate = this.state.corporateCreditNotePaymentInfo.subcriptionEndDate;
        let membershipTypeName = this.state.corporateCreditNotePaymentInfo.membershipTypeName;
        // creditNoteItemList.push({ creditNoteItem: `${membershipTypeName} - Entrance Fee`, creditNoteItemAmount: this.state.addEditCreditNote.entranceRefund });
        creditNoteItemList.push({ creditNoteItem: `${membershipTypeName} - Annual Fee (${moment(stardDate).format("DD MMM YYYY")} - ${moment(endDate).format("DD MMM YYYY")})`, creditNoteItemAmount: this.state.addEditCreditNote.annualRefund });
        // creditNoteItemList.push({ creditNoteItem: `${membershipTypeName} - Additional Chapter Fee (${moment(stardDate).format("DD MMM YYYY")} - ${moment(endDate).format("DD MMM YYYY")})`, creditNoteItemAmount: this.state.addEditCreditNote.chapterRefund });
        creditNoteItemList.push({ creditNoteItem: "Membership Subtotal Amount", creditNoteItemAmount: this.state.addEditCreditNote.subTotalRefund });
        creditNoteItemList.push({ creditNoteItem: "Membership GST Amount", creditNoteItemAmount: this.state.addEditCreditNote.gstRefund });
        creditNoteItemList.push({ creditNoteItem: "Membership Discounted Amount", creditNoteItemAmount: this.state.addEditCreditNote.discountRefund });
        creditNoteItemList.push({ creditNoteItem: "Membership Total Amount", creditNoteItemAmount: this.state.addEditCreditNote.totalRefund });
        request.creditNoteItem = creditNoteItemList;
        this.financeServices.addFinanceCreditNote(request).then((response) => {
          if (response.statusCode === 200 && response.responseItem != null) {
            if (response.responseItem.responseContent.errorMessage === '') {
              this.swalServices.Success(SuccessMessage.Insert_Success.replace("{0}", "Credit Note"));
              this.getCorporateFinancialInfo(this.state.corporateMemberId);
              //this.addCreditNoteInvoiceRecordsForBatchJob(response.responseItem.responseContent.keyId);
            }
            else {
              this.swalServices.Error(ErrorMessage.Already_Exist.replace("{0}", "Credit note"));
            }
          }
          else {
            this.swalServices.Error(ErrorMessage.DefaultMessage);
          }
          this.setState({ isLoading: false });
        });
        this.setState({ isLoading: false });
      }
    }
  }

  addCreditNoteInvoiceRecordsForBatchJob = (financeCreditNoteId) => {
    let request = [financeCreditNoteId];
    this.financeServices.addCreditNoteInvoiceRecordsForBatchJob(request).then((response) => {
      if (response.statusCode === 200 && response.responseItem.responseContent != null) {
        //  let cancelReason = response.responseItem.responseContent;
      }
      else {
        this.swalServices.Error(response.message);
      }
    });
  }

  setOrganisationModel = (value) => {
    this.setState({ addEditOrganisationModel: value });
  }

  handleisAddEditAOrganisation = () => {
    this.setState({ isAddEditAOrganisation: false });
  }

  searchByName = (value) => {
    if (value.length > 2) {
      this.getEmployeeList(this.state.corporateMemberId, value)
    }
    else if (value.length < 3) {
      this.getEmployeeList(this.state.corporateMemberId, "")
    }
  }

  setLoader = (boolean) => {
    this.setState({ isLoaderOn: boolean });
  }

  setAddEditModel = (boolean) => {
    this.setState({ addEditModel: boolean })
  }

  // Get Corporate Finance Invocie Method
  getCorporateFinanceReceiptInvoice = (receiptInvoiceId, isReceipt) => {
    if (receiptInvoiceId > 0 && receiptInvoiceId != null) {
      let request = [
        this.state.corporateMemberId,
        receiptInvoiceId,
        isReceipt
      ]
      this.corporateMemberServices.getCorporateFinanceReceiptInvoice(request).then((response) => {
        if (response.data !== null && response.status === 200) {
          var formattedDate = moment(new Date()).format("DDMMYYYYHHmmss")
          var filename = "";
          if (isReceipt === "Receipt") {
            filename = `Receipt-${formattedDate}`;
          }
          else if (isReceipt === "Invoice") {
            filename = `Invoice-${formattedDate}`;
          }
          else if (isReceipt === "Certificate") {
            filename = `Certificate-${formattedDate}`;
          }
          var file = new Blob([response.data], { type: "application/pdf" });

          URL.createObjectURL(file);
          window.open(URL.createObjectURL(file), "_blank");

          saveAs(file, filename + ".pdf");
        } else {
          this.swalServices.Error(response.message);
        }
      });
    } else {

    }
  }

  // Get ACRA Certificate Method
  GetCorporateMemberFiles = (isACRA) => {
    let request = [
      this.state.corporateMemberId,
      isACRA
    ]
    this.corporateMemberServices.GetCorporateMemberFiles(request).then((response) => {
      if (response.data !== null && response.status === 200) {
        var formattedDate = moment(new Date()).format("DDMMYYYYHHmmss");
        var filename = "";
        if (isACRA === true) {
          filename = `ACRA-File_${formattedDate}`;
        } else {
          filename = `Project_TractRecordFile_${formattedDate}`;
        }
        var file = new Blob([response.data], { type: "application/pdf" });

        URL.createObjectURL(file);
        window.open(URL.createObjectURL(file), "_blank");
        saveAs(file, filename + ".pdf");
      } else {
        this.swalServices.Error(response.message);
      }
    });
  }


  // Get ACRA Certificate Method(aws)
  GetCorporateMemberFilesDownload = (isACRA, filePath) => {

    let request = [filePath, isACRA]
    this.corporateMemberServices.GetCorporateMemberFilesDownload(request).then((response) => {
      if (response.data !== null && response.status === 200) {
        var formattedDate = moment(new Date()).format("DDMMYYYYHHmmss");
        var filename = "";
        if (isACRA === true) {
          filename = `ACRA-File_${formattedDate}`;
        } else {
          filename = `Project_TractRecordFile_${formattedDate}`;
        }
        var file = new Blob([response.data], { type: "application/pdf" });

        URL.createObjectURL(file);
        window.open(URL.createObjectURL(file), "_blank");
        saveAs(file, filename + ".pdf");
      } else {
        this.swalServices.Error(response.message);
      }
    });
  }

  // Handle Radio Buttonj Changes Method
  handleRadioButtons = (event, identity, typeId) => {
    if (identity === "membershipType") {
      let value = event.target.value;
      let detail = this.state.organizationBasicInformation;
      detail.selectedMembershipType = value;
      detail.membershipTypeId = typeId;
      this.setState({ organizationBasicInformation: { ...detail } });
    }
    else if (identity === "isRegisterd") {
      const name = event.target.name;
      let detail = this.state.organizationBasicInformation;
      detail[name] = !detail[name];
      this.setState({ organizationBasicInformation: { ...detail } });
    }
  }

  // Method to Add Scope and Roles
  handleAddScopeAndRoles = (identity) => {
    if (identity === "Add_Scope") {
      if (this.isValidBCAScope()) {
        const data = this.state.organizationBasicInformation;
        data.companyScopeList.push(this.state.selectedScope);
        this.setState({ organizationBasicInformation: data });
        const data2 = this.state.TempSelectedScopeList;
        data2.push(this.state.selectedScope);
        this.setState({ TempSelectedScopeList: data2 })
        this.handleResetScope();
      }
    }
    else if (identity === "Add_Roles") {
      // if (this.isValidateAllRolesFields()) {
      const data = this.state.organizationBasicInformation;
      data.authorisedRepresentativeList.push(this.state.rolesDetails);
      this.setState({ organizationBasicInformation: data });
      // this.resetData("Role_Section");
      // }
    }
  };

  // Method to remove Scope and Roles
  handleRemoveScopeAndRoles = (key, identity) => {
    if (identity === "Remove_Scope") {
      const list = this.state.organizationBasicInformation;
      let tempcheck = list.companyScopeList[key];
      list.companyScopeList.splice(key, 1);
      let templist = this.state.TempSelectedScopeList;
      if (templist.length > 0) {
        var tempindex = templist.findIndex(S => S.mainGroup === tempcheck.mainGroup && S.workhead === tempcheck.workhead && S.grade === tempcheck.grade);
        if (tempindex >= 0) {
          templist.splice(tempindex, 1);
          this.setState({ TempSelectedScopeList: templist })
        }
      }
      if (tempcheck.companyScopeMappingId > 0) {
        this.RemoveCompanyRegisteredBCAScopeById(tempcheck.companyScopeMappingId);
      }
      this.setState({ organizationBasicInformation: list });

    }
    else if (identity === "Remove_Roles") {
      const list = this.state.organizationBasicInformation;
      list.authorisedRepresentativeList.splice(key, 1);
      this.setState({ organizationBasicInformation: list });
    }
  };

  onFileChange = (event, identity) => {

    const file = event.target.files[0];
    if (identity === "Upload_Certificate") {
      if (file && file.type === "application/pdf") {
        let uploadFile = { ...this.state.resFile };
        uploadFile.filename = file.name;
        uploadFile["file"] = file;
        uploadFile.storagePath = "ACRA_FILE";

        this.commonServices.uploadPDF(uploadFile).then((response) => {
          if (response !== null) {

            if (response.isNotCurrupted === true) {
              let detail = { ...this.state.organizationBasicInformation };
              detail.aCRACertificatePath = response.fileName;
              this.setState({ organizationBasicInformation: detail }, () => this.orgBasicInfoValidate("aCRACertificatePath"));
              this.swalServices.Success("File Uploaded");
            }
            else {
              this.swalServices.Error("Invalid Document");
            }
          }
          else {
            this.swalServices.Error(response.message);
          }
        })
      }
      else {
        this.swalServices.Error("Please Upload only PDF file !");
      }
    }
    else if (identity === "Upload_Document") {
      if (file && file.type === "application/pdf") {
        let uploadFile = { ...this.state.resFile };
        uploadFile.filename = file.name;
        uploadFile["file"] = file;

        uploadFile.storagePath = "Project_Track_Record_FILE";

        this.commonServices.uploadPDF(uploadFile).then((response) => {
          if (response !== null) {
            if (response.isNotCurrupted === true) {
              let detail = { ...this.state.organizationBasicInformation };
              detail.projectTrackRecordReportPath = response.fileName;

              this.setState({ organizationBasicInformation: detail }, () => this.orgBasicInfoValidate("projectTrackRecordReportPath"));
              this.swalServices.Success("File Uploaded");
            }
            else {
              this.swalServices.Error("Invalid Document");
            }
          }
          else {
            this.swalServices.Error(response.message);
          }
        })
      }
      else {
        this.swalServices.Error("Please Upload only PDF file !");
      }
    } else if (identity === "Upload_Payment_Proof") {
      if (file && (file.type === "image/jpeg" || file.type === "image/png")) {
        let request = {};
        request.storagePath = "PaymentImages";
        request.file = file;
        request.fileName = file.name;
        this.setState({ isPaymentProofLoader: true });
        this.commonServices.uploadPDF(request).then((response) => {
          if (response != null) {
            let detail = { ...this.state.membershipSubscription };
            detail.uploadPaymentProof = response.fileName;
            this.setState({ membershipSubscription: detail }, () => {
              this.swalServices.Success("Payment Proof Uploaded");
            });
          } else {
            this.swalServices.Error(response.message);
          }
          this.setState({ isPaymentProofLoader: false });
        });
      } else {
        this.swalServices.Error("Please select only Image");
      }
    }
  };

  viewUpdateSLOTSMember = (value) => {
    value = this.state.siisAccountInformation.slotsMemberId;
    if (value && value > 0) {
      this.setState({ route: '/EditSLOTSMember/SLOTSMemberId?=' + encryptAES(value) });
    }
  }


  RemoveCompanyRegisteredBCAScopeById = (id) => {
    if (id > 0) {

      let request = [
        id
      ]
      this.slotMemberService.RemoveCompanyRegisteredBCAScopeById(request).then((response) => {

        if (response.statusCode === 200 && response.responseItem.responseContent != null) {
          this.swalServices.Success("Scope Removed Sucessfully");

        } else {
          this.swalServices.Error(response.message);
        }
      });
    }
  }

  getSlotsMemberCertificate = (e) => {
    if (this.state.slotsMemberId > 0) {

      let request = [
        this.state.SLOTSAccreditation.certificatePath
      ]
      this.slotMemberService.getSlotsMemberCertificate(request).then((response) => {

        if (response.data !== null && response.status === 200) {


          var formattedDate = moment(new Date()).format("DDMMYYYYHHmmss")

          var filename = `Certificate-${formattedDate}`;

          var file = new Blob([response.data], { type: "application/pdf" });
          URL.createObjectURL(file);
          window.open(URL.createObjectURL(file), "_blank");
          saveAs(file, filename + ".pdf");
        } else {
          this.swalServices.Error(response.message);
        }
      });
    }
  }

  SendRenewalMail = (e) => {
    if (this.state.slotsMemberId > 0) {
      this.setState({ isLoading: true });
      this.slotMemberService.SendRenewalReminderMailForSlotsMembers(this.state.slotsMemberId).then((response) => {
        if (response.statusCode === 200 && response.responseItem.responseContent != null) {
          this.swalServices.Success("Send Renewal mail successfully");
          this.setState({ isLoading: false });
        } else {
          this.swalServices.Error(response.message);
        }
      });
      this.setState({ isLoading: false });
    }
  }

  sendRenewalReminderMailForCorporateMember = () => {
    if (this.state.corporateMemberId > 0) {
      this.setState({ isLoading: true });

      this.corporateMemberServices.sendRenewalReminderMailForCorporateMember(this.state.corporateMemberId).then((response) => {
        if (response.statusCode === 200 && response.responseItem.responseContent != null) {
          this.swalServices.Success("Send Renewal mail successfully");
          this.setState({ isLoading: false });
        } else {
          this.swalServices.Error(response.message);
        }
      });
      this.setState({ isLoading: false });
    }
  }

  copyToClipboard = (copyUrl) => {


    navigator.clipboard.writeText(copyUrl);
    this.swalServices.Toaster("Link Copied");

  };

  render() {
    if (this.state.route != null) {
      return <Navigate to={this.state.route} />;
    }
    return (
      <>
        <div className="main-body-section">
          {/* New  Start*/}
          {/* SIIS Corporate Account Information Start*/}
          <div className="bg-white border border-[#c9c9c9] mb-3">
            <div className="tab w-full border-t">
              <input
                className="absolute opacity-0 toggle-main"
                id="SIISACC"
                type="checkbox"
                name="corporate-d"
                value={"SIISAccountInformation"}
                checked={this.state.openSection === "SIISAccountInformation"}
                onClick={() => this.openSection("SIISAccountInformation")}
              />
              <label
                className="heading-part px-7 py-4 border-b border-[#c9c9c9] flex items-center justify-between relative w-auto leading-normal cursor-pointer"
                for="SIISACC"
              >
                <span className="small-title font-bold theme-color pl-5">
                  {/* SIIS Corporate Account Information */}
                  Corporate Account Information
                </span>

              </label>
              <div className="committee-info-overflow tab-content overflow-hidden leading-normal  px-0">
                <SIISCorporateAccountInformation
                  status={this.state.status}
                  reasonOfLeaving={this.state.reasonOfLeaving}
                  _by={this.state._by}
                  corporateInternalFlags={this.state.corporateInternalFlags}
                  siisAccountInformation={this.state.siisAccountInformation}
                  setFilterParameters={this.setFilterParameters.bind(this)}
                  onSave={this.addEditSIISCorporateAccountInformation.bind(this)}
                  handleChange={this.handleChange.bind(this)}
                  handleChangeMultiDropdown={this.handleChangeMultiDropdown.bind(this)}
                  validState={this.state.validSiisAccountInfo}
                  validate={this.siisAccountInfoValidate.bind(this)}
                  handleChangeCheckBoxSIIS={this.handleChangeCheckBoxSIIS.bind(this)}
                  isLoading={this.state.isLoading}
                  isDisabledFlag={this.state.isDisabledFlag}
                />
              </div>
            </div>
          </div>
          {/* SIIS Corporate Account Information End*/}

          {/* Corporate Basic Information Start */}
          <div className="bg-white border border-[#c9c9c9] mb-3">
            <div className="tab w-full border-t">
              <input
                className="absolute opacity-0 toggle-main"
                id="OBI"
                type="checkbox"
                name="corporate-d"
                value={"OrganisationBasicInformation"}
                checked={
                  this.state.openSection === "OrganisationBasicInformation"
                }
                onClick={() => this.openSection("OrganisationBasicInformation")}
              />
              <label
                className="heading-part px-7 py-4 border-b border-[#c9c9c9] flex items-center justify-between relative w-auto leading-normal cursor-pointer"
                for="OBI"
              >
                <span className="small-title font-bold theme-color pl-5">
                  Organisation Basic Information (SCAL)
                </span>
              </label>
              <div className="committee-info-overflow tab-content overflow-hidden leading-normal px-0">
                <OrganisationBasicInformations
                  membershipTypes={this.state.membershipTypes}
                  annualTurnover={this.state.annualTurnover}
                  organizationBasicInformation={this.state.organizationBasicInformation}
                  disableUenNumber={this.state.disableUenNumber}
                  characterLimit={this.state.characterLimit}
                  setFilterParameters={this.setFilterParameters.bind(this)}
                  onSave={this.addEditOrganisationBasicInformationAdmin.bind(this)}
                  GetCorporateMemberFiles={this.GetCorporateMemberFiles.bind(this)}
                  GetCorporateMemberFilesDownload={this.GetCorporateMemberFilesDownload.bind(this)}
                  handleRadioButtons={this.handleRadioButtons.bind(this)}
                  handleAddScopeAndRoles={this.handleAddScopeAndRoles.bind(this)}
                  handleRemoveScopeAndRoles={this.handleRemoveScopeAndRoles.bind(this)}
                  handleChange={this.handleChange.bind(this)}
                  targetMarket={this.state.targetMarket}
                  validState={this.state.validOrgBasicInfo}
                  validate={this.orgBasicInfoValidate.bind(this)}
                  mrMrs={this.state.mrMrs}
                  countryList={this.state.countryList}
                  designationList={this.state.designationList}
                  companyActivity={this.state.companyActivity}
                  mainGroupList={this.state.mainGroupList}
                  selectedScope={this.state.selectedScope}
                  workheadList={this.state.workheadList}
                  gradeList={this.state.gradeList}
                  membershipSubscription={this.state.membershipSubscription}
                  handleChangeMultiDropdown={this.handleChangeMultiDropdown.bind(this)}
                  isLoading={this.state.isLoading}
                  companyRegisteredWithBCAList={this.state.companyRegisteredWithBCAList}
                  typeOfProjects={this.state.typeOfProjects}
                  fulltimeEmployeeLocal={this.state.fulltimeEmployeeLocal}
                  fullTimeEmployeeForeigners={this.state.fullTimeEmployeeForeigners}
                  knowAboutusList={this.state.knowAboutusList}
                  publicProjectsForms={this.state.publicProjectsForms}
                  handleChangeInCheckbox={this.handleChangeInCheckbox.bind(this)}
                  onFileChange={this.onFileChange.bind(this)}
                  isPaymentProofLoader={this.state.isPaymentProofLoader}
                  isDisabledFlag={this.state.isDisabledFlag}
                  validStateBCAScope={this.state.validStateBCAScope}
                />
              </div>
            </div>
          </div>
          {/* Corporate Basic Information End */}

          {/* Corporate Membership Subscription Start */}
          <div className="bg-white border border-[#c9c9c9] mb-3">
            <div className="tab w-full border-t">
              <input
                className="absolute opacity-0 toggle-main"
                id="CorporateSubscrib"
                type="checkbox"
                name="corporate-d"
                value={"CorporateSubscrib"}
                checked={this.state.openSection === "CorporateSubscription"}
                onClick={() => this.openSection("CorporateSubscription")}
              />
              <label
                className="heading-part px-7 py-4 mb-0 border-b border-[#c9c9c9] flex items-center justify-between relative w-auto leading-normal cursor-pointer"
                for="CorporateSubscrib"
              >
                <span className="small-title font-bold theme-color pl-5">
                  SCAL Membership Information (OAT)
                </span>

                <span className="text-xl items-left text-[#005B9C] font-semibold tracking-normal">
                  {this.state.membershipTypeName}
                </span>
                {this.state.migratedStatus === true ?
                  <> |
                    <span className="text-xl items-left ml-[35px] text-[#005B9C] font-semibold tracking-normal">
                      {this.state.migratedStatus === true ? "Is Migrated" : ""}
                    </span>
                  </>
                  :
                  null
                }
                {this.state.CertificateMembershipNo ?
                  <> |
                    <span className="text-xl items-left ml-[35px] text-[#005B9C] font-semibold tracking-normal">
                      {this.state.CertificateMembershipNo}
                    </span>
                  </>
                  :
                  null
                }

              </label>
              <div className="committee-info-overflow tab-content overflow-hidden leading-normal px-0">
                <CorporateMembershipSubscription
                  _by={this.state._by}
                  billingCategory={this.state.billingCategory}
                  membershipTypes={this.state.membershipTypes}
                  membershipSubscription={this.state.membershipSubscription}
                  //IsMigratedData={this.state.membershipSubscription.IsMigratedData}
                  setFilterParameters={this.setFilterParameters.bind(this)}
                  onSave={this.addEditCorporateMembershipSubscription.bind(this)}
                  handleChange={this.handleChange.bind(this)}
                  validState={this.state.validMemberSub}
                  validate={this.memberSubValidate.bind(this)}
                  handleChangeMultiDropdown={this.handleChangeMultiDropdown.bind(this)}

                  isLoading={this.state.isLoading}
                  isDisabledFlag={this.state.isDisabledFlag}
                  onFileChange={this.onFileChange.bind(this)}
                  sendRenewalReminderMailForCorporateMember={this.sendRenewalReminderMailForCorporateMember.bind(this)}

                />
              </div>
            </div>
          </div>
          {/* Corporate Membership Subscription End */}

          {/* SLOTS Member Start */}
          <div className="bg-white border border-[#c9c9c9] mb-3">
            <div className="tab w-full border-t">
              <input
                className="absolute opacity-0 toggle-main"
                id="SLOTSMEM"
                type="checkbox"
                name="corporate-d"
                value={"SLOTSMember"}

                checked={this.state.siisAccountInformation.isSLOTSMember ? this.state.openSection === "SLOTSMember" : this.state.slotsMemberId === 0 ? false : true}
                onClick={() => this.openSection("SLOTSMember")}
              />
              <label
                className="heading-part px-7 py-4 border-b border-[#c9c9c9] flex items-center justify-between relative w-auto leading-normal cursor-pointer"
                for="SLOTSMEM"
              >
                <span className="small-title font-bold theme-color pl-5">
                  SLOTs
                </span>
                <div className="">
                  {
                    this.state.siisAccountInformation.isSLOTSMember ? (
                      <span className="text-xl items-left text-[#005B9C] mr-[35px] font-semibold tracking-normal">
                        Yes
                      </span>
                    ) : (
                      <span className="text-xl items-left text-[#005B9C]  font-semibold tracking-normal">
                        No
                      </span>
                    )
                  }

                  {this.state.slotsCertificateMembershipNo ?
                    <> |
                      <span className="text-xl items-left ml-[35px] text-[#005B9C] font-semibold tracking-normal">
                        {this.state.slotsCertificateMembershipNo}
                      </span>
                    </>
                    :
                    null
                  }
                </div>


              </label>
              <div className="committee-info-overflow tab-content overflow-hidden leading-normal px-0">
                <SLOTSAccreditationCard
                  _by={this.state._by}
                  billingCategory={this.state.billingCategory}
                  membershipTypes={this.state.membershipTypes}
                  SLOTSAccreditation={this.state.SLOTSAccreditation}
                  setFilterParameters={this.setFilterParameters.bind(this)}
                  onSave={this.addEditCorporateMembershipSubscription.bind(this)}
                  handleChange={this.handleChange.bind(this)}
                  validState={this.state.validMemberSub}
                  validate={this.memberSubValidate.bind(this)}
                  handleChangeMultiDropdown={this.handleChangeMultiDropdown.bind(this)}

                  viewUpdateSLOTSMember={this.viewUpdateSLOTSMember.bind(this)}
                  isLoading={this.state.isLoading}
                  isDisabledFlag={this.state.isDisabledFlag}
                  getSlotsMemberCertificate={this.getSlotsMemberCertificate.bind(this)}
                  copyToClipboard={this.copyToClipboard.bind(this)}
                  SendRenewalMail={this.SendRenewalMail.bind(this)}

                />
              </div>
            </div>
          </div>
          {/* SLOTS Member End */}


          {/* GGBS Member Start */}
          <div className="bg-white border border-[#c9c9c9] mb-3">
            <div className="tab w-full border-t">
              <input
                className="absolute opacity-0 toggle-main"
                id="GGBSMEM"
                type="checkbox"
                name="corporate-d"
                value={"GGBSMember"}
                checked={this.state.openSection === "GGBSMember"}
                onClick={() => this.openSection("GGBSMember")}
              />
              <label
                className="heading-part px-7 py-4 border-b border-[#c9c9c9] flex items-center justify-between relative w-auto leading-normal cursor-pointer"
                for="GGBSMEM"
              >
                <span className="small-title font-bold theme-color pl-5">
                  GGBS
                </span>

                <span className="text-xl items-left text-[#005B9C] font-semibold tracking-normal">
                  No
                </span>
              </label>
              <div className="committee-info-overflow tab-content overflow-hidden leading-normal px-0">

              </div>
            </div>
          </div>
          {/* SLOTS Member End */}

          {/* Engagement Tracking and Notes Start */}
          <div className="bg-white border border-[#c9c9c9] mb-3">
            <div className="tab w-full  border-t">
              <input
                className="absolute opacity-0 toggle-main"
                id="engtrack"
                type="checkbox"
                name="corporate-d"
                value={"EngagementTracking"}
                checked={this.state.openSection === "EngagementTracking"}
                onClick={() => this.openSection("EngagementTracking")}
              />
              <label
                className="heading-part px-7 py-4 border-b border-[#c9c9c9] flex items-center justify-between relative w-auto leading-normal cursor-pointer"
                for="engtrack"
              >
                <span className="small-title font-bold theme-color pl-5">
                  Engagement Tracking and Notes
                </span>
              </label>
              <div className="committee-info-overflow tab-content overflow-hidden leading-normal px-0">
                <EngagementTrackingNotes
                  engagementType={this.state.engagementType}
                  _By={this.state._by}
                  engagementList={this.state.engagemmentTrackingList}
                  engagementTrackingDetails={this.state.engagementTrackingDetails}
                  isCorporate={this.state.corporateMemberId}
                  validState={this.state.validState}
                  onEdit={this.onEditEngagement.bind(this)}
                  onClick={this.addEditEngagementTracking.bind(this)}
                  handleChange={this.handleChange.bind(this)}
                  setFilterParameters={this.setFilterParameters.bind(this)}
                  validate={this.engagementValidate.bind(this)}
                  isLoading={this.state.isLoading}
                  isDisabledFlag={this.state.isDisabledFlag}
                />
              </div>
            </div>
          </div>
          {/* Engagement Tracking and Notes End */}

          {/* Finance Card Start */}
          <div className="bg-white border border-[#c9c9c9] mb-3">
            <div className="tab w-full border-t">
              <input
                className="absolute opacity-0 toggle-main"
                id="Financecard"
                type="checkbox"
                name="corporate-d"
                value={"Financecard"}
                checked={this.state.openSection === "Financecard"}
                onClick={() => this.openSection("Financecard")}
              />
              <label
                className="heading-part px-7 py-4 mb-0 border-b border-[#c9c9c9] flex items-center justify-between relative w-auto leading-normal cursor-pointer"
                for="Financecard"
              >
                <span className="small-title font-bold theme-color pl-5">
                  Finance Card
                </span>

              </label>
              <div className="committee-info-overflow tab-content overflow-hidden leading-normal px-0">
                <FinanceCard
                  corporateFinance={this.state.corporateFinance}
                  handleChangeCheckBox={this.handleChangeCheckBoxFinanceCard.bind(this)}
                  corporateCreditTerms={this.state.corporateCreditTerms}
                  setFilterParameters={this.setFilterParametersFinanceCard.bind(this)}
                  onSave={this.addEditFinanceCardDetails.bind(this)}
                  validState={this.state.validStateFinanceCard}
                  isLoading={this.state.isLoading}
                  isDisabledFlag={this.state.isDisabledFlag}
                />
              </div>
            </div>
          </div>
          {/* Finance Card End */}

          {/* Organisation Employees Section Start  */}
          <div className="bg-white border border-[#c9c9c9] mb-3">
            <div className="tab w-full border-t">
              <input
                className="absolute opacity-0 toggle-main"
                id="emply"
                type="checkbox"
                name="corporate-d"
                value={"Employees"}
                checked={this.state.openSection === "Employees"}
                onClick={() => this.openSection("Employees")}
              />
              <label
                className="heading-part px-7 py-2 border-b border-[#c9c9c9] flex items-center justify-between relative w-auto leading-normal cursor-pointer"
                for="emply"
              >
                <span className="small-title font-bold theme-color pl-5">
                  Organisation Employees
                </span>
                <div className="flex items-center justify-between right-id-sec xl:w-[25%]">
                  <input
                    className={`w-full outline-none appearance-none form-input rounded-none border-[#000000]  text-[#000000]}`}
                    placeholder="Search"
                    type="text"
                    name=""
                    onChange={(e) => this.searchByName(e.target.value)}
                  />
                </div>
              </label>
              <div className="committee-info-overflow tab-content overflow-hidden leading-normal px-0">
                <EmployeesList
                  employeeList={this.state.employeeList}
                  emailDomain={this.state.emailDomain}
                  employeeDetails={this.state.employeeDetails}
                  validState={this.state.validStateEmp}
                  domainArr={this.state.domainArr}
                  mrMrs={this.state.mrMrs}
                  isLoaderOn={this.state.isLoaderOn}
                  onEditEmployee={this.onEditEmployee.bind(this)}
                  saveEmployee={this.addEditEmployeeInformation.bind(this)}
                  setFilterParameters={this.setFilterParametersEmployee.bind(this)}
                  handleChange={this.handleChange.bind(this)}
                  validate={this.validateEmployeeInfo.bind(this)}
                  designationList={this.state.designationList}
                  addEditModel={this.state.addEditModel}
                  isDisabledFlag={this.state.isDisabledFlag}
                  handleChangeMultiDropdown={this.handleChangeMultiDropdown.bind(this)}
                  setLoader={this.setLoader.bind(this)}
                  setAddEditModel={this.setAddEditModel.bind(this)}
                />
              </div>
            </div>
          </div>
          {/* Organisation Employees Section End */}

          {/* Financials Record Start */}
          <div className="bg-[#f5faff] border border-[#c9c9c9] mb-3">
            <div className="tab w-full border-t">
              <input
                className="absolute opacity-0 toggle-main"
                id="finance"
                type="checkbox"
                name="corporate-d"
                value={"FinancialRecords"}
                checked={this.state.openSection === "FinancialRecords"}
                onClick={() => this.openSection("FinancialRecords")}
              />
              <label
                className="bg-white heading-part px-7 py-4 border-b border-[#c9c9c9] flex items-center justify-between relative w-auto leading-normal cursor-pointer"
                for="finance"
              >
                <span className="small-title font-bold theme-color pl-5">
                  Financials
                </span>
              </label>
              <div className="committee-info-overflow tab-content overflow-hidden leading-normal px-0">
                <FinancialsRecord
                  corporateFinanceInfo={this.state.corporateFinanceInfo}
                  getReceiptAndCertificate={this.getReceiptAndCertificate.bind(this)}
                  getCorporateFinanceReceiptInvoice={this.getCorporateFinanceReceiptInvoice.bind(this)}
                  getCreditNoteInfoForCorporateMember={this.getCreditNoteInfoForCorporateMember.bind(this)}
                  corporateCreditNotePaymentInfo={this.state.corporateCreditNotePaymentInfo}
                  getCancellationReasonForCreditNote={this.getCancellationReasonForCreditNote.bind(this)}
                  cancellationReason={this.state.cancellationReason}
                  setFilterParameters={this.setFilterParameters.bind(this)}
                  isOtherCancellationReason={this.state.isOtherCancellationReason}
                  addEditCreditNote={this.state.addEditCreditNote}
                  handleChange={this.handleChange.bind(this)}
                  addFinanceCreditNote={this.addFinanceCreditNote.bind(this)}
                  validate={this.validateFinanceCreditNote.bind(this)}
                  validCreditNote={this.state.validCreditNote}
                  validate2={this.validFinanceCreditNoteForOtherReason.bind(this)}
                  validCreditNote2={this.state.validCreditNote2}
                  isDisabledFlag={this.state.isDisabledFlag}
                  isLoading={this.state.isLoading}
                />
              </div>
            </div>
          </div>
          {/* Financials Record End */}

          {/* Organisation Key Role Start */}
          <div className="w-full">
            <OrganisationChapterRole
              mrMrs={this.state.mrMrs}
              emailDomain={this.state.emailDomain}
              domainArr={this.state.domainArr}
              Organisationrole={this.state.Organisationrole}
              chaptercommitteerol={this.state.chaptercommitteerol}
              organisationDetails={this.state.organisationDetails}
              organizationRoleArray={this.state.organizationRoleArray}
              isAddEditAOrganisation={this.state.isAddEditAOrganisation}
              editOrganisationRole={this.editOrganisationRole.bind(this)}
              addEditOraganisation={this.addEditOraganisation.bind(this)}
              setFilterParameters={this.setFilterParameters.bind(this)}
              handleChange={this.handleChange.bind(this)}
              deleteOrganisationRole={this.deleteOrganisationRole.bind(this)}
              handleReset={this.handleReset.bind(this)}
              validate={this.validateOrganisationKeyRoles.bind(this)}
              validState={this.state.validStateRoles}
              addEditOrganisationModel={this.state.addEditOrganisationModel}
              setOrganisationModel={this.setOrganisationModel.bind(this)}
              handleisAddEditAOrganisation={this.handleisAddEditAOrganisation.bind(this)}
              designationList={this.state.designationList}
              handleChangeMultiDropdown={this.handleChangeMultiDropdown.bind(this)}
              isDisabledFlag={this.state.isDisabledFlag}
            />
          </div>
          {/* Organisation Key Role End */}
          {/* New  End*/}
        </div>
      </>
    );
  }
}