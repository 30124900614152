import React, { useState } from "react";
import ModalBasic from "../../component/FormModal";
import SectionWithBorder from "../../../components/membership/membershipTaskList/SectionWithBorder";
import DropdownSelect from "../../../components/dropdown/Dropdown";
import moment from "moment";
import ValidationText from "../../../utils/validation/ValidationText";
import ButtonLoader from "../../../components/common/buttonLoader/ButtonLoader";
import RadioButton from "../../../components/membership/membershipTaskList/RadioButton";
import IndividualQuickDetailModal from "./IndividualQuickDetailModal";
import { encryptAES } from "../../../utils/Encryption";


const StudentMemberTaskListProcess = (props) => {

  const [isQuickLinkDetail, setQuickLinkDetail] = useState(false);
  // const handleChangeInPriceTable = (value, name, year, tableName) => {
  //   props.handleChangeInPriceTable(value, name, year, tableName);
  // };

  // const handleChangeCheckBox = (e) => {
  //   const { name, checked } = e.target;
  //   if (checked) {
  //     props.taskDetails.quotationNeeded = true;
  //     setIsQuotationRequired(true);
  //   } else {
  //     props.taskDetails.quotationNeeded = false;
  //     setIsQuotationRequired(false);
  //   }
  // };
  // const [isQuotationRequired, setIsQuotationRequired] = useState(false);

  return (
    <>
      <ModalBasic
        id="add-edit-modal"
        className="membership-add-edit-modal"
        modalOpen={props.setAddeditModalOpen}
        setModalOpen={props.setModalOpen}
        title="Student Membership Task List Process"
      >
        {/* Modal content */}
        <div className="2xl:px-14 lg:px-3 pt-4">
          <div className="">
            <SectionWithBorder
              title="Student Basic Information"
              //quickLink="Student Detail"
              //onQuickLink={() => setQuickLinkDetail(true)}
              location={`/CreateIndividualMembers/individualMemberId?=${encryptAES(props.taskDetails.individualMemberId > 0 ? props.taskDetails.individualMemberId : 0)}`}
            >
              <div className="grid grid-cols-12 gap-6 w-full items-center pt-7 px-7 pb-7">
                <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                  <div className="grid grid-cols-12 gap-6">
                    <div className="xl:col-span-12 md:col-span-12 col-span-12">
                      <label
                        className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                        for="default"
                      >
                        Institute Name <span className="text-[#c00000]">*</span>
                      </label>
                      <input
                        id="default"
                        className="form-input rounded-none w-full shadow-red py-3 text-lg text-[#000000]"
                        type="text"
                        name="instituteName"
                        value={props.taskDetails.instituteName}
                        onChange={(event) => props.handleChange(event)}
                      // onBlur={() => props.validateField("companyName")}
                      />
                      <div>
                        {/* {" "}
                        <ValidationText
                          error={props.validState.error.companyName}
                        />{" "} */}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                  <div className="grid grid-cols-12 gap-6">
                    <div className="xl:col-span-12 md:col-span-12 col-span-12">
                      <label
                        className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                        for="default"
                      >
                        First Name <span className="text-[#c00000]">*</span>
                      </label>
                      <input
                        id="default"
                        className="form-input rounded-none w-full shadow-red py-3 text-lg text-[#000000]"
                        type="text"
                        name="firstName"
                        value={props.taskDetails.firstName}
                        onChange={(event) => props.handleChange(event)}
                        onBlur={() => props.validateField("firstName")}
                      />
                      <div>
                        {" "}
                        <ValidationText
                          error={props.validState.error.firstName}
                        />{" "}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                  <div className="grid grid-cols-12 gap-6">
                    <div className="xl:col-span-12 md:col-span-12 col-span-12">
                      <label
                        className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                        for="default"
                      >
                        Last Name <span className="text-[#c00000]">*</span>
                      </label>
                      <input
                        id="default"
                        className="form-input rounded-none w-full shadow-red py-3 text-lg text-[#000000]"
                        type="text"
                        name="lastName"
                        value={props.taskDetails.lastName}
                        onChange={(event) => props.handleChange(event)}
                        onBlur={() => props.validateField("lastName")}
                      //disabled={true}
                      />
                      <div>
                        {" "}
                        <ValidationText
                          error={props.validState.error.lastName}
                        />{" "}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                  <div className="grid grid-cols-12 gap-6">
                    <div className="xl:col-span-12 md:col-span-12 col-span-12">
                      <label
                        className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                        for="default"
                      >
                        Mailing Address Line1 <span className="text-[#c00000]">*</span>
                      </label>
                      <input
                        id="default"
                        className="form-input rounded-none w-full shadow-red py-3 text-lg text-[#000000]"
                        type="text"
                        name="mailingAddressLine1"
                        value={props.taskDetails.mailingAddressLine1}
                        onChange={(event) => props.handleChange(event)}
                        onBlur={() => props.validateField("lastName")}
                      //disabled={true}
                      />
                      <div>
                        {" "}
                        <ValidationText
                          error={props.validState.error.mailingAddressLine1}
                        />{" "}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                  <div className="grid grid-cols-12 gap-6">
                    <div className="xl:col-span-12 md:col-span-12 col-span-12">
                      <label
                        className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                        for="default"
                      >
                        Mailing Address Line2
                      </label>
                      <input
                        id="default"
                        className="form-input rounded-none w-full shadow-red py-3 text-lg text-[#000000]"
                        type="text"
                        name="mailingAddressLine2"
                        value={props.taskDetails.mailingAddressLine2}
                        onChange={(event) => props.handleChange(event)}
                        onBlur={() => props.validateField("lastName")}
                      //disabled={true}
                      />
                      <div>
                        {" "}
                        <ValidationText
                          error={props.validState.error.mailingAddressLine2}
                        />{" "}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                  <div className="grid grid-cols-12 gap-6">
                    <div className="xl:col-span-12 md:col-span-12 col-span-12">
                      <label
                        className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                        for="default"
                      >
                        Mailing Address Line3
                      </label>
                      <input
                        id="default"
                        className="form-input rounded-none w-full shadow-red py-3 text-lg text-[#000000]"
                        type="text"
                        name="mailingAddressLine3"
                        value={props.taskDetails.mailingAddressLine3}
                        onChange={(event) => props.handleChange(event)}
                        onBlur={() => props.validateField("lastName")}
                      //disabled={true}
                      />
                      <div>
                        {" "}
                        <ValidationText
                          error={props.validState.error.mailingAddressLine3}
                        />{" "}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                  <div className="grid grid-cols-12 gap-6">
                    <div className="xl:col-span-12 md:col-span-12 col-span-12">
                      <label
                        className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                        for="default"
                      >
                        Contact Number{" "}
                        <span className="text-[#c00000]">*</span>
                      </label>
                      <input
                        id="default"
                        className="form-input rounded-none w-full shadow-red py-3 text-lg text-[#000000]"
                        type="text"
                        name="contactNo"
                        value={props.taskDetails.contactNo}
                        onChange={(event) => props.handleChange(event)}
                        onBlur={() => props.validateField("contactNo")}
                      />
                      <div>
                        {" "}
                        <ValidationText
                          error={props.validState.error.contactNo}
                        />{" "}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                  <div className="grid grid-cols-12 gap-6">
                    <div className="xl:col-span-12 md:col-span-12 col-span-12">
                      <label
                        className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                        for="default"
                      >
                        Email Address{" "}
                        <span className="text-[#c00000]">*</span>
                      </label>
                      <input
                        id="default"
                        className="form-input rounded-none w-full shadow-red py-3 text-lg text-[#000000]"
                        type="text"
                        name="email"
                        value={props.taskDetails.email}
                        onChange={(event) => props.handleChange(event)}
                        onBlur={() => props.validateField("email")}
                      />
                      <div>
                        {" "}
                        <ValidationText
                          error={props.validState.error.email}
                        />{" "}
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </SectionWithBorder>

            <SectionWithBorder title="Student Membership Subscription">
              <div className="px-7">
                <div className="grid grid-cols-12 gap-6 w-full items-center py-7">
                  <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                    <div className="grid grid-cols-12 gap-6">
                      <div className="xl:col-span-12 md:col-span-12 col-span-12">
                        <label
                          className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                          for="default"
                        >
                          {" "}
                          Billing Category{" "}
                          <span className="text-[#c00000]">*</span>{" "}
                        </label>
                        <DropdownSelect
                          drpIdentity={"billingCategories"}
                          optionArray={props.billingCategories}
                          setFilterParameters={props.setFilterParameters}
                          value={props.taskDetails.billingCategoryId}
                          disabled={props.disableSubscription}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                    <div className="grid grid-cols-12 gap-6">
                      <div className="xl:col-span-12 md:col-span-12 col-span-12">
                        <label
                          className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                          for="default"
                        >
                          Updated By<span className="text-[#c00000]">*</span>{" "}
                        </label>
                        <DropdownSelect
                          drpIdentity={"updatedBy"}
                          optionArray={props.users}
                          setFilterParameters={props.setFilterParameters}
                          value={props.taskDetails.updatedBy}
                          disabled={props.disableSubscription}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                    <div className="grid grid-cols-12 gap-6">
                      <div className="xl:col-span-12 md:col-span-12 col-span-12">
                        <label
                          className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30 "
                          for="default"
                        >
                          {" "}
                          Subscription Start Date{" "}
                          <span className="text-[#c00000]">*</span>{" "}
                        </label>
                        <div className="grid grid-cols-12 gap-6 items-center w-full">
                          <div className=" 2xl:col-span-12 lg:col-span-12 col-span-12 w-full disabled-input">
                            <input
                              id="default"
                              name="subscriptionStartDate"
                              className={`date-pickers form-input rounded-none w-full ${props.disableSubscription
                                ? "bg-[#C4C4C4]"
                                : null
                                } shadow-red py-3 text-lg opacity-50 cursor-not-allowed`}
                              type="date"
                              value={moment(
                                props.taskDetails.subscriptionStartDate
                              ).format("yyyy-MM-DD")}
                              onChange={(event) => props.handleChange(event)}
                              disabled={props.disableSubscription}
                            // onBlur={() => props.validateField("publishDate")}
                            />
                            {/* <div>
                          <ValidationText error={props.validState.error.publishDate} />
                          </div> */}
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                  <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                    <div className="grid grid-cols-12 gap-6">
                      <div className="xl:col-span-12 md:col-span-12 col-span-12">
                        <label
                          className="filter-lable flex w-full font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                          for="default"
                        >
                          <span>Last Updated Date </span>
                          <span className="text-[#c00000]">*</span>{" "}
                        </label>
                        <div className="grid grid-cols-12 gap-6 items-center w-full">
                          <div className=" 2xl:col-span-12 lg:col-span-12 col-span-12 w-full disabled-input">
                            <input
                              id="default"
                              name="lastUpdatedDate"
                              className={`date-pickers form-input rounded-none w-full ${props.disableSubscription
                                ? "bg-[#C4C4C4]"
                                : null
                                } shadow-red py-3 text-lg opacity-50 cursor-not-allowed`}
                              type="date"
                              value={moment(
                                props.taskDetails.lastUpdatedDate
                              ).format("yyyy-MM-DD")}
                              onChange={(event) => props.handleChange(event)}
                              disabled={props.disableSubscription}
                            // onBlur={() => props.validateField("publishDate")}
                            />
                            {/* <div>
                          <ValidationText error={props.validState.error.publishDate} />
                          </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts pt-3">
                    <div className="grid grid-cols-12 gap-6">
                      <div className="xl:col-span-12 md:col-span-12 col-span-12">
                        <label
                          className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                          for="default"
                        >
                          {" "}
                          Subscription End Date{" "}
                          <span className="text-[#c00000]">*</span>{" "}
                        </label>
                        <div className="grid grid-cols-12 gap-6 items-center w-full">
                          <div className=" 2xl:col-span-12 lg:col-span-12 col-span-12 w-full disabled-input">
                            <input
                              id="default"
                              name="subscriptionEndDate"
                              className={`date-pickers form-input rounded-none w-full ${props.disableSubscription
                                ? "bg-[#C4C4C4]"
                                : null
                                } shadow-red py-3 text-lg opacity-50 cursor-not-allowed`}
                              type="date"
                              value={moment(
                                props.taskDetails.subscriptionEndDate
                              ).format("yyyy-MM-DD")}
                              onChange={(event) => props.handleChange(event)}
                              disabled={props.disableSubscription}
                            // onBlur={() => props.validateField("publishDate")}
                            />
                            {/* <div>
                          <ValidationText error={props.validState.error.publishDate} />
                          </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </SectionWithBorder>

            <SectionWithBorder title="Pricing Table">
              <div className="px-7">
              </div>
              <div className='table-section'>
                <div className='overflow-x-auto'>
                  <table className='w-full'>
                    <thead className='bg-[#f2f2f2]'>
                      <tr>
                        <th className='text-left py-3 px-5 w-[25%] whitespace-nowrap'>
                          Type
                        </th>
                        <th className='text-left py-3 px-5 w-[25%] whitespace-nowrap'>
                          Item
                        </th>

                        <th className='text-left py-3 px-5 w-[25%] whitespace-nowrap'>
                          Membership Fees<br></br>
                          Including GST
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className='align-top'>
                        <td className='text-left py-2 px-5 pt-3 w-[25%]'>
                          Membership
                        </td>
                        <td className='text-left py-2 px-5 pt-4 w-[25%] whitespace-nowrap'>
                          <div className="flex items-center w-full mt-0">
                            <div className="flex items-center">
                              <RadioButton
                                id="membershipType"
                                identity="membershipType"
                                name="membershipType"
                                value="Individual Member"
                                checked={props.taskDetails.membershipTypeId === 8}
                                handleRadioButtons={(e) => props.handleRadioButtons(e, "membershipType", props.corporatePricingDetails.ordinaryApplicationFee, props.taskDetails.annualOrdinaryRevenueFee)}
                              />
                              <span className="ml-3">
                                Student Member
                              </span>
                            </div>
                          </div>
                        </td>

                        <td className='text-left py-2 px-5 pt-3 w-[25%] whitespace-nowrap'>
                          <div className='flex items-center w-full mt-1'>
                            <span className='flex items-center'>
                              {/* {`$${parseFloat(props.corporatePricingDetails.annualIndividualRevenueFee).toFixed(2)}`} */}
                              ${props.studentPricingDetails.studentApplicationFee}
                            </span>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="grid grid-cols-12 gap-6">
                {/* <div className="col-span-12 gap-6 px-8 py-8 ">
                   
                </div> */}
                <div className="2xl:col-span-12 lg:col-span-12 col-span-12 gap-6 px-8 pb-10 ">
                  <div className="flex items-center justify-between">
                    <div className="flex items-center justify-between">

                    </div>


                    <div className="flex items-center justify-between">
                      {
                        (props.taskDetails.companyName === "" ||
                          props.taskDetails.companyUEN === "" ||
                          props.taskDetails.incorporationDate === "" ||
                          props.taskDetails.firstName === "" || props.taskDetails.lastName === "" ||
                          props.taskDetails.email === "" ||
                          props.taskDetails.selectedDesignation?.length === 0 ||
                          props.taskDetails.contactNo === "") ?
                          <span className="text-[#005B9C] px-2">Please fill all mandatory fields</span>
                          :
                          null
                      }

                      {props.taskDetails.individualStatusId === 12 ?
                        <>
                          <button className={`col-start-1 col-end-3 text-xl font-bold text-[#005B9C] btn-blue-border w-[250px] text-red p-3 mt-4 mr-5 `}
                            onClick={() => {
                              props.UnrejectMemberApplication('UnReject');
                            }}
                          >
                            Appeal Process
                          </button>
                        </>
                        :
                        <>
                          {/* <button className={`col-start-1 col-end-3 text-xl font-bold text-[#005B9C] btn-blue-border w-[250px] text-red p-3 mt-4 mr-5`}
                            onClick={() => {
                              props.rejectMemberApplication('Reject');
                            }}
                          >
                            Reject Application
                          </button> */}
                          {props.isLoadingPayment ? (
                            <div className="w-[233px] quatation-send-btn">
                              <ButtonLoader className="mt-4 btn btn-blue mr-5" />
                            </div>
                          ) : (
                            <button
                              disabled={(props.taskDetails.companyName === "" ||
                                //props.taskDetails.chineseCompanyName === "" ||
                                //props.taskDetails.companyUEN === "" ||
                                //props.taskDetails.incorporationDate === "" ||
                                props.taskDetails.firstName === "" || props.taskDetails.lastName === "" ||
                                props.taskDetails.email === "" ||
                                //props.taskDetails.selectedDesignation?.length === 0 ||
                                props.taskDetails.contactNo === "") ? true : false}
                              //className={"col-start-1 col-end-3 text-xl font-bold text-[#005B9C] btn-blue-border w-[250px] text-red p-3 mt-4 "}
                              className={`col-start-1 col-end-3 mr-5 text-xl font-bold text-[#005B9C] btn-blue-border w-[250px] text-red p-3 mt-4 ${(props.taskDetails.companyName === "" ||
                                ///props.taskDetails.chineseCompanyName === "" ||
                                ///props.taskDetails.companyUEN === "" ||
                                //props.taskDetails.incorporationDate === "" ||
                                props.taskDetails.firstName === "" || props.taskDetails.lastName === "" ||
                                props.taskDetails.email === "" ||
                                //props.taskDetails.selectedDesignation?.length === 0 ||
                                props.taskDetails.contactNo === "") ? "cursor-not-allowed" : null}`
                              }

                              onClick={() => {
                                props.setSendClarificationsFlag();

                              }}
                            >
                              Send Clarifications
                            </button>
                          )}
                        </>
                      }
                      <div className="flex items-center justify-between">
                        {
                          props && props.isAcceptAndApprovedLoader === true ?
                            <ButtonLoader className="mt-4 btn btn-blue mr-5" />
                            : (
                              <button
                                className={`col-start-1 col-end-3 text-xl font-bold btn btn-blue-border w-[250px] text-white p-3 mt-4
                               ${props.taskDetails.studentStatusId === 23 ? "opacity-50 cursor-not-allowed" : ""}`}
                                disabled={props.taskDetails.studentStatusId === 23 ? true : false}
                                onClick={() => {
                                  props.acceptAndApprovedStudentApplication();
                                }}
                              >
                                Accept & Approved Application
                              </button>
                            )
                        }

                      </div>
                    </div>
                  </div>
                  {/* <div className="flex items-center justify-end">
                    {props.taskDetails.isApplicationFee === false ? <><h6 className="whitespace-nowrap text-[#FF0000] font-bold text-left">Accept Application Fee</h6></> : null}
                  </div> */}
                </div>
              </div>
            </SectionWithBorder>
          </div>

          {/*Footer Button End*/}
          <div className="flex items-center justify-between pb-6">
            <button
              className="text-lg py-2 font-bold btn btn-gray w-auto text-white"
              onClick={() => props.closeModalOnBack()}
            >
              Back
            </button>
            {/* {props.isEscalateLodaing ? (
              <div className="w-full flex justify-center quatation-send-btn pl-[40%] mb-4">
                <ButtonLoader className="mt-4 btn btn-blue mr-5" />
              </div>
            ) : (
              <button
                className=" px-7 py-2 btn-sm btn btn-lighblue text-lg text-white font-bold"
                onClick={(e) => { props.sendEscalateMailToMember(); }}
              >
                Escalate this application
              </button>
            )
            } */}
          </div>
          {/*Footer Button End*/}
        </div>

        <IndividualQuickDetailModal
          taskDetails={props.taskDetails}
          countryList={props.countryList}
          placeOfBirthList={props.placeOfBirthList}
          nationalityList={props.nationalityList}
          highestEducationList={props.highestEducationList}
          hearAboutUsList={props.hearAboutUsList}
          setSendInvoiceModal={isQuickLinkDetail}
          setOpenModal={() => setQuickLinkDetail(false)}
          individualBasicInforamtion={props.individualBasicInforamtion}
          onClose={(e) => {
            e.stopPropagation();
            setQuickLinkDetail(false);
          }}
        />
      </ModalBasic>
    </>
  );
};
export default StudentMemberTaskListProcess;