import moment from "moment";
import React, { Component } from "react";
import DropdownSelect from "../../components/dropdown/Dropdown";
import DropdownAction from "../../components/dropdown/DropdownAction";
import Table from "../../components/table/Table";
import ActionContainer from "../../components/UIComponent/ActionTableComponent/actionContainer";
import FinanceServices from "../../services/axiosServices/apiServices/FinanceServices";
import SwalServices from "../../services/swalServices/SwalServices";
import { saveAs } from "file-saver";
import { CommonValidationMessages } from "../../utils/Messages";
import { isValidForm, validate } from "../../utils/validation/CommonValidator";
import ValidationText from "../../utils/validation/ValidationText";
export default class Export extends Component {
    constructor(props) {
        super(props);
        this.financeService = new FinanceServices();
        this.swalServices = new SwalServices();
        const today = moment();
        this.state = {
            totalResultes: 0,
            extractInvoicesList: [],
            zipInvoicesList: [],
            pagination: {
                pageNo: 1,
                pageSize: 10,
                orderByColumn: "",
                orderFlag: 0,
                MembershipType: 0,
                startDate: "",
                endDate: "",
            },
            typeList: [
                // {
                //     id: 1,
                //     name: "Event",
                // },
                {
                    id: 1,
                    name: "Corporate",
                },
                {
                    id: 2,
                    name: "Individual",
                },
                {
                    id: 3,
                    name: "Slots",
                },
                {
                    id: 4,
                    name: "Student",
                },
            ],
            invoiceTypeId: 0,
            value: moment.range(today.clone().subtract(7, "days"), today.clone()),
            isOpen: false,
            actionDropdownPaid: ["Download"],
            isCreatingZip: false,
            isLoading: false,
            validationRule: {
                startDate: [
                    {
                        type: "require",
                        message: CommonValidationMessages.SelectRequired.replace("{0}", "Start date for Data Extraction report"),
                    },

                    {
                        type: "isMaximumDate",
                        message: "course start date must be earlier than 31 Dec 9999",
                    },
                    {
                        type: "isMinDate",
                        message: "Please check your course start date",
                    },
                ],
                endDate: [
                    {
                        type: "require",
                        message: CommonValidationMessages.SelectRequired.replace("{0}", "End date for Data Extraction  report"),
                    },
                    {
                        type: "isMaximumDate",
                        message: "course end date must be earlier than 31 Dec 9999",
                    },
                    {
                        type: "isMinDate",
                        message: "Please check your course end date",
                    },
                ],

            },
            validState: {
                isValid: true,
                error: {},
            },
        };
    }

    changeIsOpenFalse = () => {
    };

    onToggle = () => {
        if (this.state.pagination.invoiceType > 0) {
            this.setState({ isOpen: true });
        }
    };

    onSelect = (value, states) => {
        this.setState({ value, states });
        let pageObj = { ...this.state.pagination };

        this.setState({ pagination: pageObj }, () => {
            this.GetAllExportMembershipDataRecords();
        });
        this.setState({ isOpen: !this.state.isOpen });
    };

    setPagination = (newPagination) => {
        this.setState({ pagination: newPagination });
        this.GetAllExportMembershipDataRecords();
    };

    handleChangeInDropdown = (id, drpIdentity) => {
        let detail = this.state.pagination;
        if (drpIdentity === "MembershipType") {
            detail.MembershipType = id
            this.setState({ pagination: detail })

            //this.setState({ invoiceType: id })
            if (this.state.pagination.invoiceType !== 0) {
                //this.getAllExtractInvoicesList()
            } else {
                this.setState({ extractInvoicesList: [] });
            }
        }
    }

    actionClick = (index, value, option, event) => {
        if (option === "Download") {
            if (value && value > 0) {
                let exportMembershipDataId = this.state.extractInvoicesList[index].exportMembershipDataId;
                let Membershiptype = this.state.extractInvoicesList[index].membershipType;
                let dateOfGeneration = this.state.extractInvoicesList[index].dateOfGeneration;

                this.DownloadSheetByExportMembershipDataId(exportMembershipDataId, Membershiptype, dateOfGeneration)
            }
        }
    };

    DownloadSheetByExportMembershipDataId = (exportMembershipDataId, Membershiptype, dateOfGeneration) => {
        this.setState({ isLoading: true });
        let request = [];
        request.push(exportMembershipDataId);
        this.financeService.DownloadSheetByExportMembershipDataId(request).then((response) => {
            if (response.status === 200 && response.data != null) {
                // var filename = `${detail}${formattedDate}`;
                var blob = new Blob([response.data])
                var currentTimeStamp = moment(dateOfGeneration); //new Date();
                saveAs(blob, Membershiptype + '_' + currentTimeStamp._i + ".xlsx");
            }
            else {
                this.swalServices.Error(response.message);
            }
            this.setState({ isLoading: false });
        });
    }


    GetAllExportMembershipDataRecords = () => {
        this.setState({ isLoading: true }, () => {
            let request = { ...this.state.pagination };
            request.startDate = moment(this.state.pagination.startDate).format("YYYY MMM DD");
            request.endDate = moment(this.state.pagination.endDate).format("YYYY MMM DD");
            this.financeService.GetAllExportMembershipDataRecords(this.state.pagination).then((response) => {
                if (response.statusCode === 200 && response.responseItem != null) {

                    let data = response.responseItem.responseContent.itemList;
                    data.map((x) => {

                        // { x.dateOfGeneration ? x.dateOfGeneration = moment(x.dateOfGeneration).format("DD MMM YYYY") : x.dateOfGeneration = "-" }                    
                        x.dateOfGeneration ? x.dateOfGeneration = moment(x.dateOfGeneration).format("DD-MMM-yyyy on hh:mm a") : x.dateOfGeneration = "-";
                        x.membershipType = x.membershipType === 1 ? "Corporate" : x.membershipType === 2 ? "Individual" : x.membershipType === 3 ? "Slots" : x.membershipType === 4 ? "Student" : "-";

                        // {x.membershipType == 1 ? "Odinary" :  x.membershipType == 2 ? "Associate" : x.membershipType == 3 ? "Trade" : ""}


                        return x;
                    });
                    this.setState({ extractInvoicesList: data }, () => {
                        this.setState({ isLoading: false })
                    });
                    this.setState({ totalResultes: response.responseItem.responseContent.totalCount });
                } else {
                    this.swalServices.Error("Something went wrong.");
                    this.setState({ isLoading: false });
                }
            })
        });

    }


    GenerateExportMembershipData = () => {
        let isValidateAllFields = this.isValidateAllFields()
        if (isValidateAllFields === true) {
            if (this.state.pagination.startDate && this.state.pagination.endDate) {
                this.setState({ isLoading: true }, () => {

                    if (this.state.pagination.MembershipType > 0) {
                        this.financeService.GenerateExportMembershipData(this.state.pagination).then((response) => {
                            if (response.statusCode === 200 && response.responseItem != null) {
                                this.GetAllExportMembershipDataRecords();
                            }
                            else if (response.message == "No records found") {
                                this.swalServices.Error("No records found.");
                                this.setState({ isLoading: false });
                            }
                            else {
                                this.swalServices.Error("Something went wrong.");
                                this.setState({ isLoading: false });
                            }
                        })
                    } else {
                        this.swalServices.Alert("Please select  Membership type.");
                    }
                });
            }
        }

    }

    validateField = (key) => {
        const newValidState = validate(
            key,
            this.state.pagination,
            this.state.validationRule,
            this.state.validState
        );
        this.setState({ validState: newValidState });
    };

    isValidateAllFields = () => {
        const newValidState = isValidForm(
            this.state.pagination,
            this.state.validationRule,
            this.state.validState
        );
        this.setState({ validState: newValidState });
        return newValidState.isValid;
    };

    actions = (element, index, value) => {
        return element !== "exportMembershipDataId" ? null : (
            <div className="relative w-full credit-note-action-dropdown">
                <ActionContainer>
                    <div className="mr-8">
                        {/* {console.log(element, index, value)} */}


                        {/* <button  key={index}
                            value={value}
                            id={index}
                            index={index}
                            options={this.state.actionDropdownPaid}
                            align="right"
                            onOptionClick={this.actionClick.bind(this) }></button> */}
                        <DropdownAction
                            key={index}
                            value={value}
                            id={index}
                            index={index}
                            options={this.state.actionDropdownPaid}
                            align="right"
                            onOptionClick={this.actionClick.bind(this)}
                        />


                    </div>
                </ActionContainer>
            </div>
        );
    };

    componentDidMount() {
        this.GetAllExportMembershipDataRecords()
    }

    handleChange = (event) => {
        const { name, value } = event.target;
        let detail = this.state.pagination;
        detail[name] = value;
        this.setState({ pagination: detail });
        //this.getAllExtractInvoicesList();
    };

    Generate = () => {
        let detail = this.state.pagination;
        detail.pageNo = 1;
        //generate
        this.setState({ pagination: detail }, () => { this.GenerateExportMembershipData() })
    }

    search = () => {
        let detail = this.state.pagination;
        detail.pageNo = 1;
        //generate
        this.setState({ pagination: detail }, () => { this.GetAllExportMembershipDataRecords() })
    }

    render() {
        return (
            <div className="main-body-section finance-body-sec">
                <div className="bg-white pb-2 mt-10">
                    <div className="grid grid-cols-12 gap-6 w-full items-center px-8 pt-10 pb-12">


                        <div className=" 2xl:col-span-3 lg:col-span-3 col-span-12 w-full">
                            <h2 className="text-xl text-[#000000] font-bold 2xl:mb-1">
                                Membership Type
                                <span className="text-[#c00000]">*</span>
                            </h2>
                            <DropdownSelect
                                drpIdentity="MembershipType"
                                optionArray={this.state.typeList}
                                setFilterParameters={this.handleChangeInDropdown.bind(this)}
                                value={this.state.pagination.MembershipType}
                            />
                        </div>

                        <div className="2xl:col-span-1 lg:col-span-1 col-span-12 pt-8 text-center">
                            <div className="border-l-[1px] border-[#000000] h-[48px] inline-block mx-auto"></div>
                        </div>
                        <div className="2xl:col-span-5 lg:col-span-6 col-span-12 pr-2">
                            <div className="grid grid-cols-12 gap-6 items-center w-full">
                                <div className="2xl:col-span-5 lg:col-span-5 col-span-12 w-full">
                                    <h2 className="text-xl text-[#000000] font-bold mb-1">
                                        Start Date
                                    </h2>
                                    <input
                                        id="default"
                                        name="startDate"
                                        className="date-pickers form-input rounded-none w-full shadow-red py-3 text-lg"
                                        type="date"
                                        value={this.state.pagination.startDate}
                                        onChange={(e) => this.handleChange(e)}
                                        onBlur={() => this.validateField("startDate")}
                                    />
                                    <ValidationText error={this.state.validState.error.startDate} />

                                </div>
                                <div className="2xl:col-span-5 lg:col-span-5 col-span-12 w-full">
                                    <h2 className="text-xl text-[#000000] font-bold mb-1">
                                        End Date
                                    </h2>
                                    <input
                                        id="default"
                                        name="endDate"
                                        className="date-pickers form-input rounded-none w-full shadow-red py-3 text-lg"
                                        type="date"
                                        value={this.state.pagination.endDate}
                                        onChange={(e) => this.handleChange(e)}
                                        onBlur={() => this.validateField("endDate")}
                                    />
                                    <ValidationText error={this.state.validState.error.endDate} />
                                </div>
                                {/* <div className=" 2xl:col-span-1 lg:col-span-5 col-span-12 w-full conquas-registration-btn-dessign-set">
                                    <button
                                        className="btn btn-blue text-white font-semibold"
                                        onClick={() => this.searchBySubmissionDate()}
                                    >
                                        <span className="text-xl text-white font-semibold tracking-normal">
                                            Search
                                        </span>
                                    </button>
                                </div> */}
                            </div>
                        </div>
                        <div className=" 2xl:col-span-5 lg:col-span-5 col-span-12 w-full">
                            {/* <div className={`right-date-range ${this.state.pagination.invoiceType > 0 ? "" : "range-disabled"}`}>
                                <button className="w-full h-[58px] border-solid border-[#000000] btn  py-3 text-lg text-[#000000] flex items-left date-rang-sec relative">
                                    <div className="right-date-range">
                                        <div className="h-[45px] inline-block pt-2 w-full">
                                            <DateRangePickkerSelect
                                                pagination={this.state.pagination}
                                                value={this.state.value}
                                                onSelect={this.onSelect.bind(this)}
                                                isOpen={this.state.isOpen}
                                                onToggle={this.onToggle.bind(this)}
                                                changeIsOpenFalse={this.changeIsOpenFalse.bind(this)}
                                            />
                                        </div>
                                    </div>
                                </button>
                            </div> */}
                        </div>
                        <div className="2xl:col-span-12 pt-10 lg:col-span-12 col-span-12 w-full">
                            <div className=" flex items-center justify-end w-full space-x-4">
                                {/* <button className="btn btn-blue text-xl border text-white "
                                    onClick={() => this.search()}
                                >
                                    Search
                                </button>                       */}

                                <button className="btn btn-blue text-xl border text-white "
                                    onClick={() => this.Generate()}
                                >
                                    Generate
                                </button>
                            </div>
                        </div>
                    </div>

                </div>

                {/* Table Section Start */}
                <div className="mt-10 external-invoice-tbl">
                    {this.state.invoiceTypeId === 1 ?
                        <>
                            <Table
                                columns={[

                                    { name: "dateOfGeneration", title: "Generation Date" },
                                    { name: "membershipType", title: "Membership type" },
                                    { name: "exportMembershipDataId", title: "Actions" },
                                ]}
                                rows={this.state.extractInvoicesList}
                                isLoading={this.state.isLoading}
                                sortingColumns={["", ""]}
                                pagination={this.state.pagination}
                                totalResultes={this.state.totalResultes}
                                totalCount={this.state.totalResultes}
                                setPagination={this.setPagination.bind(this)}
                                customScope={[
                                    {
                                        column: "exportMembershipDataId",
                                        renderTableData: this.actions.bind(this),
                                    },
                                    // {
                                    //     column: "selectRow",
                                    //     renderTableData: this.select.bind(this),
                                    //     valueColumnName: "financeAdhocInvoiceId",
                                    // },
                                ]}
                            />
                        </>
                        :
                        <>
                            <Table
                                columns={[
                                    { name: "DateOfGeneration", title: "Generation Date" },
                                    { name: "membershipType", title: "Membership type" },
                                    { name: "exportMembershipDataId", title: "Actions" },
                                ]}
                                rows={this.state.extractInvoicesList}
                                isLoading={this.state.isLoading}
                                sortingColumns={["", ""]}
                                pagination={this.state.pagination}
                                totalResultes={this.state.totalResultes}
                                totalCount={this.state.totalResultes}
                                setPagination={this.setPagination.bind(this)}
                                customScope={[
                                    {
                                        column: "exportMembershipDataId",
                                        renderTableData: this.actions.bind(this),
                                    },
                                    // {
                                    //     column: "selectRow",
                                    //     renderTableData: this.select.bind(this),
                                    //     valueColumnName: "financeAdhocInvoiceId",
                                    // },
                                ]}
                            />
                        </>
                    }
                </div>
                {/*Table Section End */}

            </div>


        );
    }
}

