import React from "react";

const SectionWithBorder = (props) => {
  return (
    <div className={`border pb-3 border-[#e0e0e0] border-solid border-section-main `}>
      <div
      className={`grid 2xl:grid-cols-${props.totalColumn} lg:grid-cols-${props.totalColumn} gap-6 h-[50px] bg-zinc-100 w-full items-center xl:pr-24 pr-8 pl-4 responsive-member-price-table`}
      >
        <h2 className={`text-${props.textSize} text-[#000000] ${props.className}`}>{props.column1 ? props.column1 : null}</h2>
        <h2 className="text-base text-[#000000]">{props.column2 ? props.column2 : null}</h2>
        <h2 className="text-base text-[#000000]">{props.column3 ? props.column3 : null}</h2>
        {props.totalColumn === 4 ?
          <h2 className="text-base text-[#000000]">{props.column4 ? props.column4 : null}</h2>
          :
          null
        }
      </div>
      {props.children}
    </div>
  );
};
export default SectionWithBorder;