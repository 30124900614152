import React from "react";
import MultiSelectDropdown from "../../dropdown/MultiSelectDropdown";
import CheckboxInput from "../../inputs/CheckboxInput";
import ValidationText from "../../../utils/validation/ValidationText";
import ButtonLoader from "../../common/buttonLoader/ButtonLoader";
import { NumberInput } from "../../../components/allTypesInputComponents/AllTypeInputComponents";

const OrganisationProfile = (props) => {
    return (
        <div className="w-full">
            <div className="2xl:px-10 2xl:py-10 2xl:pt-7 lg:px-7 lg:py-7 lg:pt-5">
                <div className="grid grid-cols-12 gap-6 w-full ">
                    <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                        <div className="grid grid-cols-12 gap-6">
                            <div className="col-span-12">
                                <h2 className="text-xl text-[#000000] font-bold 2xl:mb-1">
                                    Annual Turn over (past 3 years)<span className="text-[#c00000]">*</span>
                                    {/* <span className="text-[#c00000]">*</span> */}
                                </h2>
                                <MultiSelectDropdown
                                    drpIdentity="Annual_Turnover"
                                    options={props.annualTurnoverList}
                                    //isMulti
                                    closeMenuOnSelect={true}
                                    hideSelectedOptions={false}
                                    // components={{ Option }}
                                    onChange={props.handleChangeInDropdown}
                                    allowSelectAll={true}
                                    value={props.annualTurnoverList.filter(tuenover => tuenover.value === props.SLOTSApplicationDetails.annualTurnOverId)}
                                    disabled={props.isDisabledFlag ? true : false}
                                />
                            </div>
                        </div>
                        <ValidationText error={props.validStateOrganisationProfile.error.annualTurnOverId} />
                    </div>
                    <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                        <div className="grid grid-cols-12 gap-6">
                            <div className="col-span-12">
                                <h2 className="text-xl text-[#000000] font-bold 2xl:mb-1">
                                    Company description
                                    <span className="text-[#c00000]">*</span>
                                </h2>
                                <div className={`${props.isDisabledFlag ? 'disabled-input' : null}`}>
                                    <input
                                        className={`w-full outline-none appearance-none`}
                                        placeholder=""
                                        type="text"
                                        name="companyDescription"
                                        identity="BasicInformation"
                                        value={props.SLOTSApplicationDetails.companyDescription}
                                        onChange={(event) => props.handleChange(event, "SLOTS_Information")}
                                        onBlur={(e) => props.validateField("companyDescription", props.SLOTSApplicationDetails)}
                                        autoComplete={"off"}
                                        disabled={props.isDisabledFlag ? true : false}
                                    />
                                </div>
                            </div>
                        </div>
                        <ValidationText error={props.validStateOrganisationProfile.error.companyDescription} />
                    </div>

                    {/* <div className="grid grid-cols-12 gap-4 w-full justify-between mt-6">
                        <div className="col-span-12">
                            <h2 className="input-title mb-4">
                                Is the company including (sole proprietor, partner & director) ever convicted or paid composition fines for work permit and/or
                                occupational safety related issues?
                                <span className="text-[#C00000]">*</span>
                            </h2>
                            <div className="flex items-center w-full">
                                <div className="flex items-center">
                                    <RadioButtonInput
                                        id="isCompanySafetyRelatedIssues"
                                        name="isCompanySafetyRelatedIssues"
                                        className="form-radio-orange"
                                        value={props.SLOTSApplicationDetails.isCompanySafetyRelatedIssues}
                                        checked={props.SLOTSApplicationDetails.isCompanySafetyRelatedIssues === true}
                                        handleRadioButtons={(e) => props.handleRadioButtons(e, "Organisation_Profile")}
                                    />
                                    <span className="ml-3">
                                        Yes
                                    </span>
                                </div>
                                <div className="flex items-center ml-10">
                                    <RadioButtonInput
                                        id="isCompanySafetyRelatedIssues"
                                        name="isCompanySafetyRelatedIssues"
                                        className="form-radio-orange"
                                        value={props.SLOTSApplicationDetails.isCompanySafetyRelatedIssues}
                                        checked={props.SLOTSApplicationDetails.isCompanySafetyRelatedIssues === false}
                                        handleRadioButtons={(e) => props.handleRadioButtons(e, "Organisation_Profile")}
                                    />
                                    <span className="ml-3">
                                        No
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    {
                        props && props.SLOTSApplicationDetails.isCompanySafetyRelatedIssues ?
                            (
                                <>
                                    <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                                        <div className="grid grid-cols-12 gap-6">
                                            <div className="col-span-12">
                                                <h2 className="text-xl text-[#000000] font-bold 2xl:mb-1">
                                                    If yes, please specify
                                                    <span className="text-[#c00000]">*</span>
                                                </h2>
                                                <div className={`${props.isDisabledFlag ? 'disabled-input' : null}`}>
                                                    <input
                                                        className={`w-full outline-none appearance-none`}
                                                        placeholder=""
                                                        type="text"
                                                        name="specifyReason"
                                                        identity="BasicInformation"
                                                        value={props.SLOTSApplicationDetails.specifyReason}
                                                        onChange={(event) => props.handleChange(event, "SLOTS_Information")}
                                                        onBlur={(e) => props.validateField("specifyReason", props.SLOTSApplicationDetails)}
                                                        autoComplete={"off"}
                                                        disabled={props.isDisabledFlag ? true : false}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <ValidationText error={props.validStateOrganisationProfile.error.specifyReason} />
                                    </div>

                                    <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                                        <div className="grid grid-cols-12 gap-6">
                                            <div className="col-span-12">
                                                <h2 className="text-xl text-[#000000] font-bold 2xl:mb-1">
                                                    More Information
                                                    <span className="text-[#c00000]">*</span>
                                                </h2>
                                                <div className={`${props.isDisabledFlag ? 'disabled-input' : null}`}>
                                                    <input
                                                        className={`w-full outline-none appearance-none`}
                                                        placeholder=""
                                                        type="text"
                                                        name="moreInformation"
                                                        identity="BasicInformation"
                                                        value={props.SLOTSApplicationDetails.moreInformation}
                                                        onChange={(event) => props.handleChange(event, "SLOTS_Information")}
                                                        onBlur={(e) => props.validateField("moreInformation", props.SLOTSApplicationDetails)}
                                                        autoComplete={"off"}
                                                        disabled={props.isDisabledFlag ? true : false}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <ValidationText error={props.validStateOrganisationProfile.error.moreInformation} />
                                    </div>
                                </>
                            )
                            :
                            null
                    }


                    <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                        <div className="grid grid-cols-12 gap-6">
                            <div className="col-span-12">
                                <h2 className="text-xl text-[#000000] font-bold 2xl:mb-1">
                                    Number of full-time staff employed Local
                                    <span className="text-[#c00000]">*</span>
                                </h2>
                                <MultiSelectDropdown
                                    drpIdentity="Staff_Employee_Local"
                                    options={props.staffEmployeeLocalList}
                                    //isMulti
                                    closeMenuOnSelect={true}
                                    hideSelectedOptions={false}
                                    // components={{ Option }}
                                    onChange={props.handleChangeInDropdown}
                                    allowSelectAll={true} numberOfStaffEmployedLocalId
                                    value={props.staffEmployeeLocalList.filter(localEmp => localEmp.value === props.SLOTSApplicationDetails.numberOfStaffEmployedLocalId)}
                                    disabled={props.isDisabledFlag ? true : false}
                                />
                            </div>
                        </div>
                        <ValidationText error={props.validStateOrganisationProfile.error.numberOfStaffEmployedLocalId} />
                    </div>

                    <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                        <div className="grid grid-cols-12 gap-6">
                            <div className="col-span-12">
                                <h2 className="text-xl text-[#000000] font-bold 2xl:mb-1">
                                    Foreign work permit holder
                                    <span className="text-[#c00000]">*</span>
                                </h2>
                                <MultiSelectDropdown
                                    drpIdentity="Foreign_WorkPermit_Holder"
                                    options={props.foreignWorkPermitHolderList}
                                    //isMulti
                                    closeMenuOnSelect={true}
                                    hideSelectedOptions={false}
                                    // components={{ Option }}
                                    onChange={props.handleChangeInDropdown}
                                    allowSelectAll={true}
                                    disabled={props.isDisabledFlag ? true : false}
                                    value={props.foreignWorkPermitHolderList.filter(foreignWorkPermitHolder => foreignWorkPermitHolder.value === props.SLOTSApplicationDetails.foreignWorkPermitHolderId)}
                                />
                            </div>
                        </div>
                        <ValidationText error={props.validStateOrganisationProfile.error.foreignWorkPermitHolderId} />
                    </div>
                    {/* <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                        <div className="grid grid-cols-12 gap-6">
                            <div className="col-span-12">
                                <h2 className="text-xl text-[#000000] font-bold 2xl:mb-1">
                                    Foreign work permit holder
                                    <span className="text-[#c00000]">*</span>
                                </h2>
                                <input
                                    className={`w-full outline-none appearance-none`}
                                    placeholder=""
                                    type="text"
                                    name="foreignWorkPermitHolder"
                                    identity="BasicInformation"
                                    value={props.SLOTSApplicationDetails.foreignWorkPermitHolder}
                                    onChange={(event) => props.handleChange(event, "SLOTS_Information")}
                                    onBlur={(e) => props.validateField("foreignWorkPermitHolder", props.SLOTSApplicationDetails)}
                                    autoComplete={"off"}
                                />
                            </div>
                        </div>
                        <ValidationText error={props.validStateSLOTSMember.error.foreignWorkPermitHolder} />
                    </div> */}

                    <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                        <div className="grid grid-cols-12 gap-6">
                            <div className="col-span-12">
                                <h2 className="text-xl text-[#000000] font-bold 2xl:mb-1">
                                    Head of Company First Name
                                    <span className="text-[#c00000]">*</span>
                                </h2>
                                <div className={`${props.isDisabledFlag ? 'disabled-input' : null}`}>
                                    <input
                                        className={`w-full outline-none appearance-none`}
                                        placeholder=""
                                        type="text"
                                        name="headOfCompanyFirstName"
                                        identity="BasicInformation"
                                        value={props.SLOTSApplicationDetails.headOfCompanyFirstName}
                                        onChange={(event) => props.handleChange(event, "SLOTS_Information")}
                                        onBlur={(e) => props.validateField("headOfCompanyFirstName", props.SLOTSApplicationDetails)}
                                        autoComplete={"off"}
                                        disabled={props.isDisabledFlag ? true : false}
                                    />
                                </div>
                            </div>
                        </div>
                        <ValidationText error={props.validStateOrganisationProfile.error.headOfCompanyFirstName} />
                    </div>

                    <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                        <div className="grid grid-cols-12 gap-6">
                            <div className="col-span-12">
                                <h2 className="text-xl text-[#000000] font-bold 2xl:mb-1">
                                    Head of Company Last Name
                                    <span className="text-[#c00000]">*</span>
                                </h2>
                                <div className={`${props.isDisabledFlag ? 'disabled-input' : null}`}>
                                    <input
                                        className={`w-full outline-none appearance-none`}
                                        placeholder=""
                                        type="text"
                                        name="headOfCompanyLastName"
                                        identity="BasicInformation"
                                        value={props.SLOTSApplicationDetails.headOfCompanyLastName}
                                        onChange={(event) => props.handleChange(event, "SLOTS_Information")}
                                        onBlur={(e) => props.validateField("headOfCompanyLastName", props.SLOTSApplicationDetails)}
                                        autoComplete={"off"}
                                        disabled={props.isDisabledFlag ? true : false}
                                    />
                                </div>
                            </div>
                        </div>
                        <ValidationText error={props.validStateOrganisationProfile.error.headOfCompanyLastName} />
                    </div>

                    <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                        <div className="grid grid-cols-12 gap-6">
                            <div className="col-span-12">
                                <h2 className="text-xl text-[#000000] font-bold 2xl:mb-1">
                                    Job Title
                                    <span className="text-[#c00000]">*</span>
                                </h2>
                                <div className={`${props.isDisabledFlag ? 'disabled-input' : null}`}>
                                    <input
                                        className={`w-full outline-none appearance-none`}
                                        placeholder=""
                                        type="text"
                                        name="jobTitle"
                                        identity="BasicInformation"
                                        value={props.SLOTSApplicationDetails.jobTitle}
                                        onChange={(event) => props.handleChange(event, "SLOTS_Information")}
                                        onBlur={(e) => props.validateField("jobTitle", props.SLOTSApplicationDetails)}
                                        autoComplete={"off"}
                                        disabled={props.isDisabledFlag ? true : false}
                                    />
                                </div>
                            </div>
                        </div>
                        <ValidationText error={props.validStateOrganisationProfile.error.jobTitle} />
                    </div>

                    <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                        <div className="grid grid-cols-12 gap-6">
                            <div className="col-span-12">
                                <h2 className="text-xl text-[#000000] font-bold 2xl:mb-1">
                                    Direct Office Number
                                    <span className="text-[#c00000]">*</span>
                                </h2>
                                <div className={`${props.isDisabledFlag ? 'disabled-input' : null}`}>
                                    <NumberInput
                                        placeholder=""
                                        name="directOfficeNumber"
                                        value={props.SLOTSApplicationDetails.directOfficeNumber}
                                        identity="BasicInformation"
                                        handleChange={(event) => props.handleChange(event, "SLOTS_Information")}
                                        onBlur={(e) => props.validateField("directOfficeNumber", props.SLOTSApplicationDetails)}
                                    />
                                    {/* <input
                                        className={`w-full outline-none appearance-none`}
                                        placeholder=""
                                        type="text"
                                        name="directOfficeNumber"
                                        identity="BasicInformation"
                                        value={props.SLOTSApplicationDetails.directOfficeNumber}
                                        onChange={(event) => props.handleChange(event, "SLOTS_Information")}
                                        onBlur={(e) => props.validateField("directOfficeNumber", props.SLOTSApplicationDetails)}
                                        autoComplete={"off"}
                                        disabled={props.isDisabledFlag ? true : false}
                                    /> */}
                                </div>
                            </div>
                        </div>
                        <ValidationText error={props.validStateOrganisationProfile.error.directOfficeNumber} />
                    </div>

                    <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                        <div className="grid grid-cols-12 gap-6">
                            <div className="col-span-12">
                                <h2 className="text-xl text-[#000000] font-bold 2xl:mb-1">
                                    Mobile Number
                                    <span className="text-[#c00000]">*</span>
                                </h2>
                                <div className={`${props.isDisabledFlag ? 'disabled-input' : null}`}>
                                    <NumberInput
                                        placeholder=""
                                        name="mobileNumber"
                                        value={props.SLOTSApplicationDetails.mobileNumber}
                                        identity="BasicInformation"
                                        handleChange={(event) => props.handleChange(event, "SLOTS_Information")}
                                        onBlur={(e) => props.validateField("mobileNumber", props.SLOTSApplicationDetails)}
                                    />
                                    {/* <input
                                        className={`w-full outline-none appearance-none`}
                                        placeholder=""
                                        type="text"
                                        name="mobileNumber"
                                        identity="BasicInformation"
                                        value={props.SLOTSApplicationDetails.mobileNumber}
                                        onChange={(event) => props.handleChange(event, "SLOTS_Information")}
                                        onBlur={(e) => props.validateField("mobileNumber", props.SLOTSApplicationDetails)}
                                        autoComplete={"off"}
                                        disabled={props.isDisabledFlag ? true : false}
                                    /> */}
                                </div>
                            </div>
                        </div>
                        <ValidationText error={props.validStateOrganisationProfile.error.mobileNumber} />
                    </div>

                    <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                        <div className="grid grid-cols-12 gap-6">
                            <div className="col-span-12">
                                <h2 className="text-xl text-[#000000] font-bold 2xl:mb-1">
                                    Email Address
                                    <span className="text-[#c00000]">*</span>
                                </h2>
                                <div className={`${props.isDisabledFlag ? 'disabled-input' : null}`}>
                                    <input
                                        className={`w-full outline-none appearance-none`}
                                        placeholder=""
                                        type="text"
                                        name="email"
                                        identity="BasicInformation"
                                        value={props.SLOTSApplicationDetails.email}
                                        onChange={(event) => props.handleChange(event, "SLOTS_Information")}
                                        onBlur={(e) => props.validateField("email", props.SLOTSApplicationDetails)}
                                        autoComplete={"off"}
                                        disabled={props.isDisabledFlag ? true : false}
                                    />
                                </div>
                            </div>
                        </div>
                        <ValidationText error={props.validStateOrganisationProfile.error.email} />
                    </div>

                    <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                        <div className="grid grid-cols-12 gap-6">
                            <div className="col-span-12">
                                <h2 className="text-xl text-[#000000] font-bold 2xl:mb-1">
                                    Paid-Up Capital
                                    <span className="text-[#c00000]">*</span>
                                </h2>
                                <div className={`${props.isDisabledFlag ? 'disabled-input' : null}`}>
                                    <input
                                        className={`w-full outline-none appearance-none`}
                                        placeholder=""
                                        type="text"
                                        name="paidUpCapital"
                                        identity="BasicInformation"
                                        value={props.SLOTSApplicationDetails.paidUpCapital}
                                        onChange={(event) => props.handleChange(event, "SLOTS_Information")}
                                        // onBlur={(e) => props.validateField("paidUpCapital", props.SLOTSApplicationDetails)}
                                        autoComplete={"off"}
                                        disabled={props.isDisabledFlag ? true : false}
                                    />
                                </div>
                            </div>
                        </div>
                        {/* <ValidationText error={props.validStateOrganisationProfile.error.paidUpCapital} />  */}
                    </div>

                    <div className="2xl:col-span-12 lg:col-span-12 col-span-12 px-8">
                        <div className="flex items-center justify-end">
                            <div className="flex gap-3 items-center justify-between mt-3">
                                {props.isOrganisationProfileLoading && props.isOrganisationProfileLoading === true ?
                                    <>
                                        <ButtonLoader className="w-[250px] p-3 mt-4 btn btn-orange" />
                                    </>
                                    :
                                    <>
                                        <button
                                            className={`col-start-1 col-end-3 text-xl font-bold btn btn-orange-border w-[250px] text-red p-3 mt-4 ${props.isDisabledFlag ? 'cursor-not-allowed' : null}`}
                                            onClick={() => props.updateOrganisationProfileDetails()}
                                            disabled={props.isDisabledFlag ? true : false}
                                        >
                                            Update Organisation Profile
                                        </button>
                                    </>
                                }
                            </div>
                        </div>
                    </div>

                    <div className='col-span-12'>
                        <h2 className="text-sm font-semibold mb-2">
                            Authorised Representative
                            {/* <span className="text-[#C00000]">*</span> */}
                        </h2>
                        <div className='desc-part'>
                            <p>
                                Please appoint a representative where applicable. Kindly provide email address unique to the representative i.e.
                                the email ad dress must not be the same with other representatives
                            </p>
                            <p>
                                Please tick to indicate if you would like to authorise the representative to
                            </p>
                            <div className='inner-listing'>
                                <ul>
                                    <li>
                                        Receive weekly circular emails and important notices from SCAL and respective government regulatory
                                    </li>
                                    <li>
                                        SCAL members Telegram Channel: Get the latest construction and SCAL updates sent directly to your mobile.
                                    </li>
                                    <li>
                                        SCAL member e portal: One stop online resource centre to access downloads, important notices etc.
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className='col-span-12'>
                        <h2 className="input-title mb-2">
                            Authorised Representative Summary
                        </h2>
                        <div className='table-section'>
                            <div className='overflow-x-auto'>
                                <table className='w-full'>
                                    <thead className='bg-[#e78e3c] text-white'>
                                        <tr>
                                            <th className='text-left py-3 px-5'>
                                                Roles
                                            </th>
                                            <th className='text-left py-3 px-5'>
                                                First Name
                                            </th>
                                            <th className='text-left py-3 px-5'>
                                                Last Name
                                            </th>
                                            <th className='text-left py-3 px-5'>
                                                Job Title
                                            </th>
                                            <th className='text-left py-3 px-5 w-[200px]'>
                                                Direct Office Number
                                            </th>
                                            <th className='text-left py-3 px-5'>
                                                Mobile Number
                                            </th>
                                            <th className='text-left py-3 px-5'>
                                                Email Address
                                            </th>
                                            <th className='text-left py-3 px-5'>
                                                Newsletter
                                            </th>
                                            <th className='text-left py-3 px-5'>
                                                Telegram
                                            </th>
                                            <th className='text-left py-3 px-5'>
                                                ePortal
                                            </th>
                                            <th className='text-left py-3 px-5'>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="text-sm custom-tbody">
                                        {props && props.SLOTSApplicationDetails.authorisedRepresentativeSummaryList?.map((role, key) => {
                                            return (
                                                <tr className='bg-[#eeeeee] text-black border-t-4 border-[#ffffff]' key={key}>
                                                    <td className='text-left py-2 px-5'>
                                                        {role.roles}
                                                    </td>
                                                    <td className='text-left py-2 px-5'>
                                                        {role.firstName}
                                                    </td>
                                                    <td className='text-left py-2 px-5'>
                                                        {role.lastName}
                                                    </td>
                                                    <td className='text-left py-2 px-5'>
                                                        {role.jobTitle}
                                                    </td>
                                                    <td className='text-left py-2 px-5'>
                                                        {role.directOfficeNumber}
                                                    </td>
                                                    <td className='text-left py-2 px-5'>
                                                        {role.mobileNumber}
                                                    </td>
                                                    <td className='text-left py-2 px-5'>
                                                        {role.emailAddress}
                                                    </td>
                                                    <td className='py-2 px-5 text-center'>
                                                        <img className='h-5 w-5 object-contain mx-auto' src={role.isNewsletter ? "/check-orange.png" : "/checkNot.png"} alt='' />
                                                    </td>
                                                    <td className='py-2 px-5 text-center'>
                                                        <img className='h-5 w-5 object-contain mx-auto' src={role.isTelegram ? "/check-orange.png" : "/checkNot.png"} alt='' />
                                                    </td>
                                                    <td className='py-2 px-5 text-center'>
                                                        <img className='h-5 w-5 object-contain mx-auto' src={role.isePortal ? "/check-orange.png" : "/checkNot.png"} alt='' />
                                                    </td>
                                                    <td className='py-2 px-5 text-center'>
                                                        <div className='flex gap-2'>
                                                            <button
                                                                className='action-btn flex gap-2'
                                                                onClick={() => props.getAuthorisedRepresentative(key)}
                                                            >
                                                                <img className='min-h-[30px] min-w-[30px] mx-auto' src='../edit.png' alt='' />
                                                            </button>

                                                            <button
                                                                className={`action-btn ${props.isDisabledFlag ? 'cursor-not-allowed' : null}`}
                                                                onClick={(event) => props.handleChange(event, "Remove_Organisation_Role", key, role.authorisedRepresentativeId)}
                                                                disabled={props.isDisabledFlag ? true : false}
                                                            >
                                                                <img className='min-h-[30px] min-w-[30px] mx-auto' src='../delete-icon.svg' alt=''>
                                                                </img>
                                                            </button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                        <div className="grid grid-cols-12 gap-6">
                            <div className="col-span-12">
                                <h2 className="text-xl text-[#000000] font-bold 2xl:mb-1">
                                    Roles
                                    <span className="text-[#c00000]">*</span>
                                </h2>
                                <MultiSelectDropdown
                                    drpIdentity="Role"
                                    options={props.roleList}
                                    //isMulti
                                    closeMenuOnSelect={true}
                                    hideSelectedOptions={false}
                                    // components={{ Option }}
                                    onChange={props.handleChangeInDropdown}
                                    allowSelectAll={false}
                                    numberOfStaffEmployedLocalId
                                    value={props.roleList.filter(role => role.value === props.roleDetails.roleId)}
                                    disabled={props.isDisabledFlag ? true : false}
                                />
                            </div>
                        </div>
                        <ValidationText error={props.validStateSLOTSMemberRole.error.roleId} />
                    </div>
                    <div className="lg:col-span-6 col-span-12 input-sec-texts mt-8">
                        <div className='flex justify-end'>
                            <button
                                className={`btn btn-orange-border with-[250px] ${props.isDisabledFlag ? 'cursor-not-allowed' : null}`}
                                onClick={(event) => props.handleChange(event, "Add_Organisation_Role")}
                                disabled={props.isDisabledFlag ? true : false}
                            >
                                <span className='fa fa-plus'></span>
                                <span className='ml-2'>
                                    {props.roleDetails.authorisedRepresentativeId > 0 ? "Update Role" : "Add Role"}
                                </span>
                            </button>
                        </div>
                    </div>

                    <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                        <div className="grid grid-cols-12 gap-6">
                            <div className="col-span-12">
                                <h2 className="text-xl text-[#000000] font-bold 2xl:mb-1">
                                    First Name
                                    <span className="text-[#c00000]">*</span>
                                </h2>
                                <div className={`${props.isDisabledFlag ? 'disabled-input' : null}`}>
                                    <input
                                        className={`w-full outline-none appearance-none `}
                                        placeholder=""
                                        type="text"
                                        name="firstName"
                                        identity="BasicInformation"
                                        value={props.roleDetails.firstName}
                                        onChange={(event) => props.handleChange(event, "Roles_Details")}
                                        onBlur={(e) => props.validateRole("firstName", props.roleDetails)}
                                        autoComplete={"off"}
                                        disabled={props.isDisabledFlag ? true : false}
                                    />
                                </div>
                            </div>
                        </div>
                        <ValidationText error={props.validStateSLOTSMemberRole.error.firstName} />
                    </div>

                    <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                        <div className="grid grid-cols-12 gap-6">
                            <div className="col-span-12">
                                <h2 className="text-xl text-[#000000] font-bold 2xl:mb-1">
                                    Last Name
                                    <span className="text-[#c00000]">*</span>
                                </h2>
                                <div className={`${props.isDisabledFlag ? 'disabled-input' : null}`}>
                                    <input
                                        className={`w-full outline-none appearance-none`}
                                        placeholder=""
                                        type="text"
                                        name="lastName"
                                        identity="BasicInformation"
                                        value={props.roleDetails.lastName}
                                        onChange={(event) => props.handleChange(event, "Roles_Details")}
                                        onBlur={(e) => props.validateRole("lastName", props.roleDetails)}
                                        autoComplete={"off"}
                                        disabled={props.isDisabledFlag ? true : false}
                                    />
                                </div>
                            </div>
                        </div>
                        <ValidationText error={props.validStateSLOTSMemberRole.error.lastName} />
                    </div>

                    <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                        <div className="grid grid-cols-12 gap-6">
                            <div className="col-span-12">
                                <h2 className="text-xl text-[#000000] font-bold 2xl:mb-1">
                                    Job Title
                                    <span className="text-[#c00000]">*</span>
                                </h2>
                                <div className={`${props.isDisabledFlag ? 'disabled-input' : null}`}>
                                    <input
                                        className={`w-full outline-none appearance-none`}
                                        placeholder=""
                                        type="text"
                                        name="jobTitle"
                                        identity="BasicInformation"
                                        value={props.roleDetails.jobTitle}
                                        onChange={(event) => props.handleChange(event, "Roles_Details")}
                                        onBlur={(e) => props.validateRole("jobTitle", props.roleDetails)}
                                        autoComplete={"off"}
                                        disabled={props.isDisabledFlag ? true : false}
                                    />
                                </div>
                            </div>
                        </div>
                        <ValidationText error={props.validStateSLOTSMemberRole.error.jobTitle} />
                    </div>

                    <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                        <div className="grid grid-cols-12 gap-6">
                            <div className="col-span-12">
                                <h2 className="text-xl text-[#000000] font-bold 2xl:mb-1">
                                    Direct Office Number
                                    <span className="text-[#c00000]">*</span>
                                </h2>
                                <div className={`${props.isDisabledFlag ? 'disabled-input' : null}`}>
                                    <NumberInput
                                        placeholder=""
                                        name="directOfficeNumber"
                                        value={props.roleDetails.directOfficeNumber}
                                        identity="BasicInformation"
                                        handleChange={(event) => props.handleChange(event, "Roles_Details")}
                                        onBlur={(e) => props.validateRole("directOfficeNumber", props.roleDetails)}
                                        disabled={props.isDisabledFlag ? true : false}
                                    />
                                </div>
                            </div>
                        </div>
                        <ValidationText error={props.validStateSLOTSMemberRole.error.directOfficeNumber} />
                    </div>

                    <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                        <div className="grid grid-cols-12 gap-6">
                            <div className="col-span-12">
                                <h2 className="text-xl text-[#000000] font-bold 2xl:mb-1">
                                    Mobile Number
                                    <span className="text-[#c00000]">*</span>
                                </h2>
                                <div className={`${props.isDisabledFlag ? 'disabled-input' : null}`}>
                                    <NumberInput
                                        placeholder=""
                                        name="mobileNumber"
                                        value={props.roleDetails.mobileNumber}
                                        identity="BasicInformation"
                                        handleChange={(event) => props.handleChange(event, "Roles_Details")}
                                        onBlur={(e) => props.validateRole("mobileNumber", props.roleDetails)}
                                        disabled={props.isDisabledFlag ? true : false}
                                    />
                                </div>
                            </div>
                        </div>
                        <ValidationText error={props.validStateSLOTSMemberRole.error.mobileNumber} />
                    </div>

                    <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                        <div className="grid grid-cols-12 gap-6">
                            <div className="col-span-12">
                                <h2 className="text-xl text-[#000000] font-bold 2xl:mb-1">
                                    Email Address<span className="text-[#c00000]">*</span>
                                </h2>
                                <div className={`${props.isDisabledFlag ? 'disabled-input' : null}`}>
                                    <input
                                        className={`w-full outline-none appearance-none`}
                                        placeholder=""
                                        type="text"
                                        name="emailAddress"
                                        identity="BasicInformation"
                                        value={props.roleDetails.emailAddress}
                                        onChange={(event) => props.handleChange(event, "Roles_Details")}
                                        onBlur={(e) => props.validateRole("emailAddress", props.roleDetails)}
                                        autoComplete={"off"}
                                        disabled={props.isDisabledFlag ? true : false}
                                    />
                                </div>
                            </div>
                        </div>
                        <ValidationText error={props.validStateSLOTSMemberRole.error.emailAddress} />
                    </div>
                    <div className="lg:col-span-6 col-span-12 input-sec-texts">
                        <div className='flex items-center justify-between'>
                            <div className="check-box-sec flex items-center register-desc">
                                <div className={`${props.isDisabledFlag ? 'disabled-input' : null}`}>
                                    <CheckboxInput
                                        id="isNewsletter"
                                        name="isNewsletter"
                                        className="cursor-pointer text-[#e1e1e1] focus:ring-[#e1e1e1] rounded check-box-custom"
                                        checked={props.roleDetails.isNewsletter}
                                        value={props.roleDetails.isNewsletter}
                                        handleChange={(event) => props.handleChange(event, "Role_Checkboxes")}
                                        disabled={props.isDisabledFlag ? true : false}
                                    />
                                </div>

                                <span className="filter-lable font-bold text-[#757575] pl-4">
                                    Newsletter
                                </span>
                            </div>
                            <div className="check-box-sec flex items-center register-desc">
                                <div className={`${props.isDisabledFlag ? 'disabled-input' : null}`}>
                                    <CheckboxInput
                                        id="isTelegram"
                                        name="isTelegram"
                                        className="cursor-pointer text-[#e1e1e1] focus:ring-[#e1e1e1] rounded check-box-custom"
                                        checked={props.roleDetails.isTelegram}
                                        value={props.roleDetails.isTelegram}
                                        handleChange={(event) => props.handleChange(event, "Role_Checkboxes")}
                                        disabled={props.isDisabledFlag ? true : false}
                                    />
                                </div>
                                <span className="filter-lable font-bold text-[#757575] pl-4">
                                    Telegram
                                </span>
                            </div>
                            <div className="check-box-sec flex items-center register-desc">
                                <div className={`${props.isDisabledFlag ? 'disabled-input' : null}`}>
                                    <CheckboxInput
                                        id="isePortal"
                                        name="isePortal"
                                        className="cursor-pointer text-[#e1e1e1] focus:ring-[#e1e1e1] rounded check-box-custom"
                                        checked={props.roleDetails.isePortal}
                                        value={props.roleDetails.isePortal}
                                        handleChange={(event) => props.handleChange(event, "Role_Checkboxes")}
                                        disabled={props.isDisabledFlag ? true : false}
                                    />
                                </div>
                                <span className="filter-lable font-bold text-[#757575] pl-4">
                                    e-Portal
                                </span>
                            </div>
                        </div>
                    </div>
                </div>


                {/* Upload Files From Admin Section */}
                <div className="col-span-12 mt-12">
                    <div className="grid grid-cols-12 gap-6">
                        <div className="col-span-12">
                            <h2 className="input-title mb-2">
                                Uploaded Documents
                            </h2>

                            {/* Upload ACRA Certificate */}
                            <div class="flex pt-4 flex items-center">
                                <div class="flex items-center w-auto h-6">
                                    <img
                                        src={props && props.SLOTSApplicationDetails.uploadACRACertificate ? '/check-orange.png' : '/checkNot.png'}
                                        className='w-[25px] mr-3'
                                        alt='' />
                                    <span className='text-[#000]'>
                                        Upload ACRA Certificate
                                    </span>
                                </div>
                                <div className="ml-5">
                                    <ValidationText error={props.validStateOrganisationProfile.error.uploadACRACertificate} />
                                </div>
                                <div class={`flex-initial w-auto `}>
                                    {
                                        props && props.isACRACerLoader ?
                                            <ButtonLoader
                                                className='ml-10 btn btn-orange'
                                            />
                                            :
                                            (
                                                <form className={`custom-uploader custom-file-upload-btn flex cursor-pointer ml-10 md:mt-0 mt-2 `}>
                                                    <div className={`border border-[#f3f3f3] btn btn-orange ${props.isDisabledFlag ? 'cursor-not-allowed' : null}`}>
                                                        <label htmlFor="Upload_ACRA_Certificate" className={`text-xl text-white ${props.isDisabledFlag ? 'cursor-not-allowed' : null}`}>Upload File</label>
                                                        <input className="sr-only" id="Upload_ACRA_Certificate" type="file"
                                                            onChange={(event) => props.onFileChange(event, "Upload_ACRA_Certificate")}
                                                            disabled={props.isDisabledFlag ? true : false}
                                                        />
                                                    </div>
                                                </form>
                                            )
                                    }
                                </div>
                                {props && props.SLOTSApplicationDetails.uploadACRACertificate ?
                                    <div class="w-auto">
                                        <button className="ml-5 2xl:px-7 lg:px-5 py-2 btn btn btn-orange-border text-xl border text-[#e78e3c]"
                                            onClick={() => props.GetSlotsMemberFiles('ACRACertificates', props.SLOTSApplicationDetails.uploadACRACertificate)}
                                        >
                                            <span className="text-xl text-[#e78e3c] font-semibold tracking-normal underline">
                                                View Files
                                            </span>
                                        </button>
                                    </div>
                                    :
                                    null
                                }
                            </div>
                            <div className="ml-9 mt-4">{props.SLOTSApplicationDetails.uploadACRACertificate}</div>

                            {/* Upload WP Online Record Start*/}
                            <div class="flex pt-4 flex items-center">
                                <div class="flex items-center w-auto h-6">
                                    <img
                                        src={props && props.SLOTSApplicationDetails.uploadWPOnlineRecord ? '/check-orange.png' : '/checkNot.png'}
                                        className='w-[25px] mr-3'
                                        alt='' />
                                    <span className='text-[#000]'>
                                        Upload WP Online Record
                                    </span>
                                </div>
                                <div className="ml-5">
                                    <ValidationText error={props.validStateOrganisationProfile.error.uploadWPOnlineRecord} />
                                </div>
                                <div class="flex-initial w-auto">
                                    {
                                        props && props.isWPRecordLoader ?
                                            <ButtonLoader
                                                className='ml-10 btn btn-orange'
                                            />
                                            :
                                            (
                                                <form className="custom-uploader custom-file-upload-btn flex cursor-pointer ml-10 md:mt-0 mt-2">
                                                    <div className={`border border-[#f3f3f3] btn btn-orange ${props.isDisabledFlag ? 'cursor-not-allowed' : null}`}>
                                                        <label htmlFor="Upload_WP_Record" className={`text-xl text-white ${props.isDisabledFlag ? 'cursor-not-allowed' : null}`}>Upload File</label>
                                                        <input className="sr-only" id="Upload_WP_Record" type="file"
                                                            onChange={(event) => props.onFileChange(event, "Upload_WP_Record")}
                                                            disabled={props.isDisabledFlag ? true : false}
                                                        />
                                                    </div>
                                                </form>
                                            )
                                    }
                                </div>
                                {
                                    props && props.SLOTSApplicationDetails.uploadWPOnlineRecord ?
                                        <div class="w-auto">
                                            <button className="ml-5 2xl:px-7 lg:px-5 py-2 btn btn btn-orange-border text-xl border text-[#e78e3c]"
                                                onClick={() => props.DownloadMultipleFilesZip('WPOnlineRecord')}
                                            >
                                                <span className="text-xl text-[#e78e3c] font-semibold tracking-normal underline">
                                                    View Files
                                                </span>
                                            </button>
                                        </div>
                                        : null
                                }

                            </div>
                            <div>
                                {props.SLOTSApplicationDetails.fileListOfWPRecord?.length > 0 ?
                                    props.SLOTSApplicationDetails.fileListOfWPRecord.map((data, key) => {
                                        return (
                                            <div className='mt-5 ml-9 file-uploaded-txt flex items-center' key={key}>
                                                {/* <img src='/check-orange.png' className='w-[25px] mr-3' alt=''></img> */}
                                                <span className='text-[#000]'>
                                                    {data}
                                                </span>
                                                {/* <button className='underline text-[#e74c3c] font-[500] ml-3'
                                                // onClick={() => props.GetSlotsMemberFiles('WPOnlineRecord', data)}
                                                >
                                                    Download File
                                                </button> */}
                                                <button className='underline text-[#e74c3c] font-[500] ml-3'
                                                    onClick={() => props.deleteFile(data, "Upload_WP_Record")}
                                                >
                                                    <i className="fa fa-trash" aria-hidden="true"></i>
                                                </button>
                                            </div>
                                        )
                                    })

                                    : null
                                }
                            </div>
                            {/* Upload WP Online Record End*/}

                            {/*  Latest 6 months CPF contribution of local employees (CPF Form 90) Start */}
                            <div class="flex pt-4 flex items-center">
                                <div class="flex items-center w-auto h-6">
                                    <img
                                        src={props && props.SLOTSApplicationDetails.latest6monthsCPFContribution ? '/check-orange.png' : '/checkNot.png'}
                                        className='w-[25px] mr-3'
                                        alt='' />
                                    <span className='text-[#000]'>
                                        Latest 6 months CPF contribution of local employees (CPF Form 90)
                                    </span>
                                </div>
                                <div className="ml-5">
                                    <ValidationText error={props.validStateOrganisationProfile.error.latest6monthsCPFContribution} />
                                </div>
                                <div class="flex-initial w-auto">
                                    {
                                        props && props.is6MonCPFLoader ?
                                            <ButtonLoader
                                                className='ml-10 btn btn-orange'
                                            />
                                            :
                                            (
                                                <form className="custom-uploader custom-file-upload-btn flex cursor-pointer ml-10 md:mt-0 mt-2">
                                                    <div className={`border border-[#f3f3f3] btn btn-orange ${props.isDisabledFlag ? 'cursor-not-allowed' : null}`}>
                                                        <label htmlFor="CPF_Contribution" className={`text-xl text-white ${props.isDisabledFlag ? 'cursor-not-allowed' : null}`}>Upload File</label>
                                                        <input className="sr-only" id="CPF_Contribution" type="file"
                                                            onChange={(event) => props.onFileChange(event, "CPF_Contribution")}
                                                            disabled={props.isDisabledFlag ? true : false}
                                                        />
                                                    </div>
                                                </form>
                                            )
                                    }
                                </div>
                                {props && props.SLOTSApplicationDetails.latest6monthsCPFContribution ?
                                    <div class="w-auto">
                                        <button className="ml-5 2xl:px-7 lg:px-5 py-2 btn btn btn-orange-border text-xl border text-[#e78e3c]"
                                            onClick={() => props.DownloadMultipleFilesZip('Latest6MonthsCPFcontribution')}
                                        >
                                            <span className="text-xl text-[#e78e3c] font-semibold tracking-normal underline">
                                                View Files
                                            </span>
                                        </button>
                                    </div>
                                    :
                                    null
                                }
                            </div>
                            <div>
                                {props.SLOTSApplicationDetails.fileList2?.length > 0 ?
                                    props.SLOTSApplicationDetails.fileList2.map((data, key) => {
                                        return (
                                            <div className='mt-5 ml-9 file-uploaded-txt flex items-center' key={key}>
                                                {/* <img src='/check-orange.png' className='w-[25px] mr-3' alt=''></img> */}
                                                <span className='text-[#000]'>
                                                    {data}
                                                </span>
                                                {/* <button className='underline text-[#e74c3c] font-[500] ml-3'
                                                // onClick={() => props.GetSlotsMemberFiles('WPOnlineRecord', data)}
                                                >
                                                    Download File
                                                </button> */}
                                                <button className='underline text-[#e74c3c] font-[500] ml-3'
                                                    onClick={() => props.deleteFile(data, "CPF_Contribution")}
                                                >
                                                    <i className="fa fa-trash" aria-hidden="true"></i>
                                                </button>
                                            </div>
                                        )
                                    })
                                    : null
                                }
                            </div>
                            {/*  Latest 6 months CPF contribution of local employees (CPF Form 90) Start End */}

                            {/* Copy of CPF contributions of local employees for the duration of completed projects Start */}
                            <div class="flex pt-4 flex items-center">
                                <div class="flex items-center w-auto h-6">
                                    <img
                                        src={props && props.SLOTSApplicationDetails.copyOfCPFContributionsOfCompletedProjects ? '/check-orange.png' : '/checkNot.png'}
                                        className='w-[25px] mr-3'
                                        alt='' />
                                    <span className='text-[#000]'>
                                        Copy of CPF contributions of local employees for the duration of completed projects
                                    </span>
                                </div>
                                <div className="ml-5">
                                    <ValidationText error={props.validStateOrganisationProfile.error.copyOfCPFContributionsOfCompletedProjects} />
                                </div>
                                <div class="flex-initial w-auto">
                                    {
                                        props && props.isCPFConLoader ?
                                            <ButtonLoader
                                                className='ml-10 btn btn-orange'
                                            />
                                            :
                                            (
                                                <form className="custom-uploader custom-file-upload-btn flex cursor-pointer ml-10 md:mt-0 mt-2">
                                                    <div className={`border border-[#f3f3f3] btn btn-orange ${props.isDisabledFlag ? 'cursor-not-allowed' : null}`}>
                                                        <label htmlFor="CPF_Complete_Contribution" className={`text-xl text-white ${props.isDisabledFlag ? 'cursor-not-allowed' : null}`}>Upload File</label>
                                                        <input className="sr-only" id="CPF_Complete_Contribution" type="file"
                                                            onChange={(event) => props.onFileChange(event, "CPF_Complete_Contribution")}
                                                            disabled={props.isDisabledFlag ? true : false}
                                                        />
                                                    </div>
                                                </form>
                                            )
                                    }
                                </div>
                                {
                                    props && props.SLOTSApplicationDetails.copyOfCPFContributionsOfCompletedProjects ?
                                        <div class="w-auto">
                                            <button className="ml-5 2xl:px-7 lg:px-5 py-2 btn btn btn-orange-border text-xl border text-[#e78e3c]"
                                                onClick={() => props.DownloadMultipleFilesZip('CopyOfCPFContributionsOfCompletedProjects')}
                                            >
                                                <span className="text-xl text-[#e78e3c] font-semibold tracking-normal underline">
                                                    View Files
                                                </span>
                                            </button>
                                        </div>
                                        :
                                        null
                                }

                            </div>
                            <div>
                                {props.SLOTSApplicationDetails.fileListOfcopyofcpfContribution?.length > 0 ?
                                    props.SLOTSApplicationDetails.fileListOfcopyofcpfContribution.map((data, key) => {
                                        return (
                                            <div className='mt-5 ml-9 file-uploaded-txt flex items-center' key={key}>
                                                {/* <img src='/check-orange.png' className='w-[25px] mr-3' alt=''></img> */}
                                                <span className='text-[#000]'>
                                                    {data}
                                                </span>
                                                {/* <button className='underline text-[#e74c3c] font-[500] ml-3'
                                                // onClick={() => props.GetSlotsMemberFiles('WPOnlineRecord', data)}
                                                >
                                                    Download File
                                                </button> */}
                                                <button className='underline text-[#e74c3c] font-[500] ml-3'
                                                    onClick={() => props.deleteFile(data, "CPF_Complete_Contribution")}
                                                >
                                                    <i className="fa fa-trash" aria-hidden="true"></i>
                                                </button>
                                            </div>
                                        )
                                    })

                                    : null
                                }
                            </div>
                            {/* Copy of CPF contributions of local employees for the duration of completed projects End */}

                            {/* Approved technical qualification (BCA Academy trade test certificates or relevant trade qualifications as approved by SCAL) Start */}
                            <div class="flex pt-4 flex items-center">
                                <div class="flex items-center w-auto h-6">
                                    <img
                                        src={props && props.SLOTSApplicationDetails.approvedTechnicalQualification ? '/check-orange.png' : '/checkNot.png'}
                                        className='w-[25px] mr-3'
                                        alt='' />
                                    <span className='text-[#000]'>
                                        Approved technical qualification (BCA Academy trade test certificates or relevant trade qualifications as approved by SCAL)
                                    </span>
                                </div>
                                <div className="ml-5">
                                    <ValidationText error={props.validStateOrganisationProfile.error.approvedTechnicalQualification} />
                                </div>
                                <div class="flex-initial w-64">
                                    {
                                        props && props.isApprovedBCALoader ?
                                            <ButtonLoader
                                                className='ml-10 btn btn-orange'
                                            />
                                            :
                                            (
                                                <form className="custom-uploader custom-file-upload-btn flex cursor-pointer ml-10 md:mt-0 mt-2">
                                                    <div className={`border border-[#f3f3f3] btn btn-orange ${props.isDisabledFlag ? 'cursor-not-allowed' : null}`}>
                                                        <label htmlFor="Approved_technical_qualification" className={`text-xl text-white ${props.isDisabledFlag ? 'cursor-not-allowed' : null}`}>Upload File</label>
                                                        <input className="sr-only" id="Approved_technical_qualification" type="file"
                                                            onChange={(event) => props.onFileChange(event, "Approved_technical_qualification")}
                                                            disabled={props.isDisabledFlag ? true : false}
                                                        />
                                                    </div>
                                                </form>
                                            )
                                    }
                                </div>
                                {props && props.SLOTSApplicationDetails.approvedTechnicalQualification ?

                                    <div class="w-64">
                                        <button className="ml-5 2xl:px-7 lg:px-5 py-2 btn btn btn-orange-border text-xl border text-[#e78e3c]"
                                            onClick={() => props.DownloadMultipleFilesZip('ApprovedTechnicalQualification')}
                                        >
                                            <span className="text-xl text-[#e78e3c] font-semibold tracking-normal underline">
                                                View Files
                                            </span>
                                        </button>
                                    </div>
                                    :
                                    null
                                }
                            </div>
                            <div>
                                {props.SLOTSApplicationDetails.fileListOfApprovedTechnicalQualification?.length > 0 ?
                                    props.SLOTSApplicationDetails.fileListOfApprovedTechnicalQualification.map((data, key) => {
                                        return (
                                            <div className='mt-5 ml-9 file-uploaded-txt flex items-center' key={key}>
                                                {/* <img src='/check-orange.png' className='w-[25px] mr-3' alt=''></img> */}
                                                <span className='text-[#000]'>
                                                    {data}
                                                </span>
                                                {/* <button className='underline text-[#e74c3c] font-[500] ml-3'
                                                // onClick={() => props.GetSlotsMemberFiles('WPOnlineRecord', data)}
                                                >
                                                    Download File
                                                </button> */}
                                                <button className='underline text-[#e74c3c] font-[500] ml-3'
                                                    onClick={() => props.deleteFile(data, "Approved_technical_qualification")}
                                                >
                                                    <i className="fa fa-trash" aria-hidden="true"></i>
                                                </button>
                                            </div>
                                        )
                                    })
                                    : null
                                }
                            </div>
                            {/* Approved technical qualification (BCA Academy trade test certificates or relevant trade qualifications as approved by SCAL) End */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default OrganisationProfile;