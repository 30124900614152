import React, { Component } from "react";
//import MultiSelectDropdown from '../../dropdown/MultiSelectDropdown';
import { Navigate } from "react-router-dom";
import SwalServices from "../../../services/swalServices/SwalServices";
import { checkParameters } from "../../../utils/Encryption";
import StudentMemberService from "../../../services/axiosServices/apiServices/StudentMemberService";
import SectionWithBorder from "../../../components/UIComponent/SectionBorder";
import StudentApplication from "../../../components/membership/studentMembers/StudentApplication";
import { validate } from "../../../utils/validation/CommonValidator";
import { StudentMemberValidationRules } from "./StudentMemberValidationRule";
import { CommonSuccessMessages } from "../../../utils/Messages";
import { isValidForm } from "../../../utils/validation/CommonValidator";
import moment from "moment";
import DropdownSelect from "../../../components/dropdown/Dropdown";
import { saveAs } from "file-saver";
import { getAuthProps } from "../../../utils/AuthenticationLibrary";

export default class EditStudentMember extends Component {
  constructor(props) {
    super(props);
    //this.slotMemberService = new SlotMemberService();
    //this.commonServices = new CommonServices();
    this.myRef = React.createRef();
    this.swalServices = new SwalServices();
    this.studentMemberService = new StudentMemberService();
    this.state = {
      billingCategories: [],
      updatedBy:0,
      studentMemberId: 0,
      route: null,
      characterLimit: 100,
      studentPricingDetails: {},
      countryList: [],
      billingCategory: [],
      postalCodeList: [],
      nationalityList: [],
      yearList: [],
      qualificationList: [],
      knowAboutUsList: [],
      nameOfInstitutionList: [],
      validStateStudentMember: {
        isValid: true,
        error: {},
      },
      isLoading: false,
     
      disableSubscription: true,
      studentSignUpData: {
        studentMemberId: 0,
        firstName: "",
        lastName: "",
        MailingAddressLine1: "",
        MailingAddressLine2: "",
        MailingAddressLine3: "",
        countryId: 153,
        nationalityTypeId: 0,
        postalCode: "",
        contactNumber: "",
        email: "",
        tellUsMoreAboutYourSelf: "",
        nameOfCurrentInstitution: 0,
        courseTitle: "",
        expectedGraduationDate: "",
        statusId: 0,
        howDoYouHearAboutUs: 0,
        membershipType: "Student",
        isStudent: true,
        isRequiredQuotation: false,
        isRequiredIndustry: false,
        isRequiredCareer: false,
        isRequiredStudent: false,
        isRequiredJourneys: false,
        otherActivitiesYouWouldLikeToBeInvolved: "",
        specifictopicswouldbebeneficial: "",
        industryTalks: "",
        additionalInformationOrResources: "",
        UploadStudentIDCard: "",
        currentlyInYear: 0,
        typeOfCurrentOrLatestQualification: 0,
        tellUsMoreAboutYourSelfwordCount: 0,
        otherActivitiesYouWouldLikeToBeInvolvedwordCount: 0,
        specifictopicswouldbebeneficialwordCount: 0,
        additionalInformationOrResourceswordCount: 0,
        billingCategoryId: 0,

        //updatedBy: ,
        studentFinanceDetailsResponseList: [],
      },
      path: "",
      validState: {
        isValid: true,
        error: {},
      },
    };
  }

  componentDidMount = () => {
    let params = new URLSearchParams(window.location.search);

    let cookie = getAuthProps();
    if (cookie != null) {
      const adminUserId = cookie.adminUserId;
      this.setState({ updatedBy: adminUserId });
    }

    let checkRead = window.location.search;
    let getRead = checkRead.split("=");
    let checkFinal = getRead[2];
    if (checkFinal === "ReadOnly") {
      this.setState({ isDisabledFlag: true });
    }

    let studentId = params.get("");
    if (studentId && (studentId = checkParameters(studentId, "Number"))) {
      if (studentId != null && studentId > 0) {
        this.setState(
          { studentMemberId: studentId }
          //     , () => {
          //     this.getStudentMemberDetailByMemberId();
          // }
        );
      }
    }

    this.getAllDropDownsForStudentMember();
    // this.getSLOTSMemberDetailByMemberId(slotsId);
  };

  getStudentMemberDetailByMemberId = () => {
    let request = this.state.studentMemberId;

    this.studentMemberService
      .GetStudentMemberDetailByMemberId(request)
      .then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          let data = response.responseItem.responseContent;

          if (
            data.additionalInformationOrResources &&
            data.additionalInformationOrResources !== ""
          ) {
            const array = data.additionalInformationOrResources
              .split(/\s+/)
              .filter((word) => word !== "").length;
            data.additionalInformationOrResourceswordCount = array;
          } else {
            data.additionalInformationOrResourceswordCount = 0;
          }

          if (
            data.specifictopicswouldbebeneficial &&
            data.specifictopicswouldbebeneficial !== ""
          ) {
            const array2 = data.specifictopicswouldbebeneficial
              .split(/\s+/)
              .filter((word) => word !== "").length;
            data.specifictopicswouldbebeneficialwordCount = array2;
          } else {
            data.specifictopicswouldbebeneficialwordCount = 0;
          }

          if (
            data.otherActivitiesYouWouldLikeToBeInvolved &&
            data.otherActivitiesYouWouldLikeToBeInvolved !== ""
          ) {
            const array2 = data.otherActivitiesYouWouldLikeToBeInvolved
              .split(/\s+/)
              .filter((word) => word !== "").length;
            data.otherActivitiesYouWouldLikeToBeInvolvedwordCount = array2;
          } else {
            data.otherActivitiesYouWouldLikeToBeInvolvedwordCount = 0;
          }

          if (
            data.tellUsMoreAboutYourSelf &&
            data.tellUsMoreAboutYourSelf !== ""
          ) {
            const array3 = data.tellUsMoreAboutYourSelf
              .split(/\s+/)
              .filter((word) => word !== "").length;
            data.tellUsMoreAboutYourSelfwordCount = array3;
          } else {
            data.tellUsMoreAboutYourSelfwordCount = 0;
          }

          //data.additionalInformationOrResources = value;
          //let value = (this.state.characterLimit - data.descriptionWordsCount);
          //this.setState({ studentSignUpData: detail });

          this.setState({ studentSignUpData: data });
        }
      });
  };
  handleChange(event, identity) {
    let value = event.target.value.toUpperCase();
    // let value = event.target.value;
    let name = event.target.name;
    let detail = this.state.studentSignUpData;
    if (identity === "StudentForm") {
      let studentSignUpData = { ...this.state.studentSignUpData };
      studentSignUpData[name] = value;
      this.setState({ studentSignUpData: studentSignUpData });
    } else if (name === "tellUsMoreAboutYourSelf") {
      const array = value.split(/\s+/).filter((word) => word !== "").length;
      if (array <= this.state.characterLimit) {
        //const array = value.trim().split(" ");
        detail.tellUsMoreAboutYourSelfwordCount = array;
        detail.tellUsMoreAboutYourSelf = value;

        //let test = (this.state.characterLimit - detail.descriptionWordsCount);
        this.setState({ studentSignUpData: detail });
      }
    } else if (name === "specifictopicswouldbebeneficial") {
      const array = value.split(/\s+/).filter((word) => word !== "").length;
      if (array <= this.state.characterLimit) {
        //const array = value.trim().split(" ");
        detail.specifictopicswouldbebeneficialwordCount = array;
        detail.specifictopicswouldbebeneficial = value;
        //let test = (this.state.characterLimit - detail.descriptionWordsCount);
        this.setState({ studentSignUpData: detail });
      }
    } else if (name === "otherActivitiesYouWouldLikeToBeInvolved") {
      const array = value.split(/\s+/).filter((word) => word !== "").length;
      if (array <= this.state.characterLimit) {
        //const array = value.trim().split(" ");
        detail.otherActivitiesYouWouldLikeToBeInvolvedwordCount = array;
        detail.otherActivitiesYouWouldLikeToBeInvolved = value;
        //let test = (this.state.characterLimit - detail.descriptionWordsCount);
        this.setState({ studentSignUpData: detail });
      }
    } else if (name === "additionalInformationOrResources") {
      const array = value.split(/\s+/).filter((word) => word !== "").length;
      if (array <= this.state.characterLimit) {
        //const array = value.trim().split(" ");
        detail.additionalInformationOrResourceswordCount = array;
        detail.additionalInformationOrResources = value;
        //let test = (this.state.characterLimit - detail.descriptionWordsCount);
        this.setState({ studentSignUpData: detail });
      }
    } else {
      detail[name] = value;
      this.setState({ studentSignUpData: { ...detail } });
    }
  }
  getAllDropDownsForStudentMember = () => {
    
    this.studentMemberService
      .getAllDropDownsForStudentMember()
      .then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          var billingCategoryArr = response.responseItem.responseContent.filter(
            (drp) => drp.dropdownName === "BillingCategory"
          );
          var country = response.responseItem.responseContent.filter(
            (drp) => drp.dropdownName === "Country"
          );
          country.forEach(function (item) {
            delete item["dropdownName"];
          });
          country = country.map((p) => ({ value: p.id, label: p.name }));
          this.setState({ countryList: country, placeOfBirthList: country });

          var nationality = response.responseItem.responseContent.filter(
            (drp) => drp.dropdownName === "Nationality"
          );
          nationality.forEach(function (item) {
            delete item["dropdownName"];
          });
          nationality = nationality.map((p) => ({
            value: p.id,
            label: p.name,
          }));
          this.setState({ nationalityList: nationality });

          var corporateGetToKnowUs =
            response.responseItem.responseContent.filter(
              (drp) => drp.dropdownName === "CorporateGetToKnowUs"
            );
          corporateGetToKnowUs.forEach(function (item) {
            delete item["dropdownName"];
          });
          corporateGetToKnowUs = corporateGetToKnowUs.map((p) => ({
            value: p.id,
            label: p.name,
          }));
          this.setState({ knowAboutUsList: corporateGetToKnowUs });

          var CurrentlyInYears = response.responseItem.responseContent.filter(
            (drp) => drp.dropdownName === "CurrentlyInYears"
          );
          CurrentlyInYears.forEach(function (item) {
            delete item["dropdownName"];
          });
          CurrentlyInYears = CurrentlyInYears.map((p) => ({
            value: p.id,
            label: p.name,
          }));
          this.setState({ yearList: CurrentlyInYears });

          var CurrentQualification =
            response.responseItem.responseContent.filter(
              (drp) => drp.dropdownName === "CurrentQualification"
            );
          CurrentQualification.forEach(function (item) {
            delete item["dropdownName"];
          });
          CurrentQualification = CurrentQualification.map((p) => ({
            value: p.id,
            label: p.name,
          }));
          this.setState({ qualificationList: CurrentQualification });

          var NameOfInstitutions = response.responseItem.responseContent.filter(
            (drp) => drp.dropdownName === "NameOfInstitutions"
          );
          NameOfInstitutions.forEach(function (item) {
            delete item["dropdownName"];
          });
          NameOfInstitutions = NameOfInstitutions.map((p) => ({
            value: p.id,
            label: p.name,
          }));
          this.setState({ nameOfInstitutionList: NameOfInstitutions });

          billingCategoryArr.map(function (item) {
            delete item["dropdownName"];
            return item;
          });

          console.log(nationality);
          this.setState(
            {
              billingCategories: billingCategoryArr,
            },
            () => {
              this.getStudentMemberDetailByMemberId(this.state.studentMemberId);
            }
          );
        }
      });
  };
  handleChangeSelectorDropdown(selected, identity) {
    if (identity === "Selected_Country") {
      var profile = { ...this.state.studentSignUpData };
      profile.countryId = selected.id;
      this.setState({ studentSignUpData: profile }, () => {
        // this.validateField("countryId")
      }
      );
    } else if (identity === "Nationality") {
      var profile1 = { ...this.state.individualSignUpDetails };
      profile1.nationalityTypeId = selected.value;
      this.setState({ individualSignUpDetails: profile1 }, () => {
        // this.validateField("nationalityTypeId")
      }
      );
    } else if (identity === "Know_About_Us") {
      var profile2 = { ...this.state.studentSignUpData };
      profile2.howDoYouHearAboutUs = selected.id;
      this.setState({ studentSignUpData: profile2 }, () => {
        // this.validateField("howDoYouHearAboutUs")
      }
      );
    } else if (identity === "Currently_InYear") {
      var profile = { ...this.state.studentSignUpData };
      profile.CurrentlyInYear = selected.id;
      this.setState({ studentSignUpData: profile }, () => {
        // this.validateField("CurrentlyInYear")
      }
      );
    } else if (identity === "CurrentQualificationName") {
      var profile3 = { ...this.state.studentSignUpData };
      profile3.TypeOfCurrentOrLatestQualification = selected.id;
      this.setState({ studentSignUpData: profile3 }, () => {
        // this.validateField("TypeOfCurrentOrLatestQualification")
      }
      );
    } else if (identity === "NameOfInstituitons") {
      var profile4 = { ...this.state.studentSignUpData };
      profile4.nameOfCurrentInstitution = selected.value;
      this.setState({ studentSignUpData: profile4 }, () => {
        // this.validateField("nameOfCurrentInstitution")
      }
      );
    }
  }
  // validateField = (key) => {

  //     // let newRules = StudentSignUpValidationRules;
  //     if (this.state.urlParamBool === false) {
  //         newRules.uploadPaymentProof = this.state.uploadPaymentProof;
  //         newRules.comments = this.state.comments;
  //     }
  //     const newValidState = validate(
  //         key,
  //         this.state.studentSignUpData,
  //         // StudentSignUpValidationRules,
  //         newRules,
  //         this.state.validState
  //     );
  //     this.setState({ validState: newValidState });
  // };
  handleChangeInDropdown(selected, identity) {
    if (identity === "Selected_Country") {
      var profile = { ...this.state.studentSignUpData };
      profile.countryId = selected.value;
      this.setState({ studentSignUpData: profile }, () => {
        // this.validateField("countryId")
      }
      );
    } else if (identity === "Nationality") {
      var profile1 = { ...this.state.studentSignUpData };
      profile1.nationalityTypeId = selected.value;
      this.setState({ studentSignUpData: profile1 }, () => {
        // this.validateField("nationalityTypeId")
      }
      );
    } else if (identity === "Know_About_Us") {
      var profile2 = { ...this.state.studentSignUpData };
      profile2.howDoYouHearAboutUs = selected.value;
      this.setState({ studentSignUpData: profile2 }, () => {
        // this.validateField("howDoYouHearAboutUs")
      }
      );
    } else if (identity === "Currently_InYear") {
      var profile3 = { ...this.state.studentSignUpData };
      profile3.currentlyInYear = selected.value;
      this.setState({ studentSignUpData: profile3 }, () => {
        // this.validateField("currentlyInYear")
      }
      );
    } else if (identity === "CurrentQualificationName") {
      var profile4 = { ...this.state.studentSignUpData };
      profile4.typeOfCurrentOrLatestQualification = selected.value;
      this.setState({ studentSignUpData: profile4 }, () => {
        // this.validateField("typeOfCurrentOrLatestQualification")
      }
      );
    } else if (identity === "NameOfInstituitons") {
      var profile5 = { ...this.state.studentSignUpData };
      profile5.nameOfCurrentInstitution = selected.value;
      this.setState({ studentSignUpData: profile5 }, () => {
        // this.validateField("nameOfCurrentInstitution")
      }
      );
    }
  }

  deleteFile = () => {
    let data = this.state.studentSignUpData;
    data.uploadStudentIDCard = "";
    this.setState({ studentSignUpData: data });
  };

  handleChangeInCheckbox(event) {
    //const value = event.target.value;
    const name = event.target.name;
    let detail = this.state.studentSignUpData;
    detail[name] = !detail[name];
    this.setState({ studentSignUpData: { ...detail } });
  }
  saveMainImage = (file, keyId, imageIdentity) => {
    file.storagePath = imageIdentity;
    this.studentMemberService.storeBase64Image(file).then((response) => {
      if (response != null) {
        this.swalServices.Success("Photo Uploaded");
        if (file.storagePath === "StudentMemberData") {
          let detail = this.state.studentSignUpData;
          detail.uploadStudentIDCard = response.fileName;
          this.setState({ studentSignUpData: { ...detail } });
        }
      } else {
        this.swalServices.Error(response.message);
      }
    });
  };
  validateField = (key) => {
    let newRules = StudentMemberValidationRules;
    if (this.state.urlParamBool === false) {
      newRules.uploadPaymentProof = this.state.uploadPaymentProof;
      newRules.comments = this.state.comments;
    }
    const newValidState = validate(
      key,
      this.state.studentSignUpData,
      StudentMemberValidationRules,
      newRules,
      this.state.validState
    );
    this.setState({ validState: newValidState });
  };

  onFileChange = (event, fileType) => {
    const file = event.target.files[0];
    if (fileType === "Upload_StudId") {
      if (
        file &&
        (file.type === "image/jpeg" ||
          file.type === "image/png" ||
          file.type === "application/pdf")
      ) {
        let request = {};
        request.storagePath = "PaymentImages";
        request.file = file;
        request.fileName = file.name;
        this.setState({ isLoading: true });
        this.studentMemberService.uploadPDF(request).then((response) => {
          if (response != null) {
            if (response.isNotCurrupted === true) {
              let detail = { ...this.state.studentSignUpData };
              detail.uploadPaymentProof = response.fileName;
              this.setState({ studentSignUpData: detail }, () => {
                this.swalServices.Success("Image  Uploaded");
              });
            } else {
              this.swalServices.Error("Invalid Document");
            }
          } else {
            this.swalServices.Error(response.message);
          }
          this.setState({ isLoading: false });
        });
      } else {
        this.swalServices.Error("Please select only Image or PDF");
      }
    }
  };

  setFilterParameters = (id, drpIdentity) => {
    let detail = this.state.studentSignUpData;
    if (drpIdentity === "billingCategories") {
      detail["billingCategoryId"] = id;
      //this.validateField("resourceTypeId");
    } else if (drpIdentity === "updatedBy") {
      detail["updatedBy"] = id;
    } else if (drpIdentity === "membershipTypes") {
      detail["membershipTypeId"] = id === 0 ? 1 : id;
      this.getPriceTabelForTaskListProcess();
      this.validateField("membershipTypeId");
    } else if (drpIdentity === "membershipRevenue") {
      detail["membershipRevenueId"] = id === 0 ? 1 : id;
      this.getPriceTabelForTaskListProcess();
      this.validateField("membershipRevenueId");
    } else if (drpIdentity === "transactionTypes") {
      detail["transactionTypeId"] = id === 0 ? 1 : id;
      this.getPriceTabelForTaskListProcess();
      this.validateField("transactionTypeId");
    } else if (drpIdentity === "membershipTenure") {
      detail["membershipTenureId"] = id;
      this.validateFieldForInvoice("membershipTenureId");
    } else if (drpIdentity === "chapters") {
      if (id !== 0 || id < 0) {
        detail["numberOfChapter"] = id - 1;
        this.validateFieldForInvoice("numberOfChapter");
      } else {
        detail["numberOfChapter"] = id - 1;
        this.validateFieldForInvoice("numberOfChapter");
      }
    }
    this.setState({ studentSignUpData: { ...detail } });
  };

  DownloadCertificateForStudentMemberById = () => {
    let request = [this.state.studentMemberId];
    this.studentMemberService
      .DownloadCertificateForStudentMemberById(request)
      .then((response) => {
        if (response.status === 200 && response != null) {
          this.swalServices.Success("Certificate Downloaded Successfully");
          var formattedDate = moment(new Date()).format("DDMMYYYYHHmmss");

          var filename = `Certificate-${formattedDate}`;

          var file = new Blob([response.data], { type: "application/pdf" });
          URL.createObjectURL(file);
          window.open(URL.createObjectURL(file), "_blank");
          saveAs(file, filename + ".pdf");
        } else {
          this.swalServices.Error(response.message);
        }
        //this.setState({ isLoading: false });
      });
  };

  DownloadCertificateForStudentMemberByPath = (path) => {
    let request = [path];
    this.studentMemberService
      .DownloadCertificateForStudentMemberByPath(request)
      .then((response) => {
        if (response.status === 200 && response != null) {
          this.swalServices.Success("Certificate Downloaded Successfully");
          var formattedDate = moment(new Date()).format("DDMMYYYYHHmmss");

          var filename = `Certificate-${formattedDate}`;

          var file = new Blob([response.data], { type: "application/pdf" });
          URL.createObjectURL(file);
          window.open(URL.createObjectURL(file), "_blank");
          saveAs(file, filename + ".pdf");
        } else {
          this.swalServices.Error(response.message);
        }
        //this.setState({ isLoading: false });
      });
  };

  DownloadInvoiceForStudentMemberById = (path) => {
    let request = [path];
    // let request = [this.state.studentMemberId];
    this.studentMemberService
      .DownloadInvoiceForStudentMemberById(request)
      .then((response) => {
        if (response.status === 200 && response != null) {
          this.swalServices.Success("Invoice Downloaded Successfully");
          var formattedDate = moment(new Date()).format("DDMMYYYYHHmmss");

          var filename = `Invoice-${formattedDate}`;

          var file = new Blob([response.data], { type: "application/pdf" });
          URL.createObjectURL(file);
          window.open(URL.createObjectURL(file), "_blank");
          saveAs(file, filename + ".pdf");
        } else {
          this.swalServices.Error(response.message);
        }
        //this.setState({ isLoading: false });
      });
  };

  DownloadReceiptForStudentMemberById = (path) => {
    let request = [path];
    this.studentMemberService
      .DownloadReceiptForStudentMemberById(request)
      .then((response) => {
        if (response.status === 200 && response != null) {
          this.swalServices.Success("Receipt Downloaded Successfully");
          var formattedDate = moment(new Date()).format("DDMMYYYYHHmmss");

          var filename = `Receipt-${formattedDate}`;

          var file = new Blob([response.data], { type: "application/pdf" });
          URL.createObjectURL(file);
          window.open(URL.createObjectURL(file), "_blank");
          saveAs(file, filename + ".pdf");
        } else {
          this.swalServices.Error(response.message);
        }
        //this.setState({ isLoading: false });
      });
  };

  isValidStudentMember = () => {
    let data = { ...StudentMemberValidationRules };
    const returnValidState = isValidForm(
      this.state.studentSignUpData,
      data,
      this.state.validStateStudentMember
    );
    this.setState({ validStateStudentMember: returnValidState });
    return returnValidState.isValid;
  };
  editStudentMemberDetails = () => {
  
    this.swalServices
      .Confirm(
        CommonSuccessMessages.Confirm_update_student_Memebr_Information.replace(
          "{0}",
          "Admin"
        ),
        "",
        CommonSuccessMessages.Yes_Text,
        CommonSuccessMessages.No_Text
      )
      .then((response) => {
        if (response) {
          // if (this.isValidStudentMember()) {
          let request = this.state.studentSignUpData;
          request.updatedAppId = 111;
          request.updatedBy=this.state.updatedBy;
          this.setState({ isLoading: true });
          this.studentMemberService
            .EditStudentMemberDetails(request)
            .then((response) => {
              if (
                response.statusCode === 200 &&
                response.responseItem != null
              ) {
                this.swalServices.Success(
                  "Student Member Details Updated successfully"
                );
                // this.getCONQUASMemberDetailByMemberId(
                //   this.state.basicApplicationDetails.conquasMemberId
                // );
                //this.getAllDropDownsForCONQUASMember();
              } else {
                this.swalServices.Error(response.message);
              }
              this.setState({ isLoading: false });
            });
          // }
        }
      });
  };
  render() {
    if (this.state.route != null) {
      return <Navigate to={this.state.route} />;
    }
    return (
      <>
        <div className="main-body-section">
          <div className="custom-card shadow-lg bg-[#f5faff]">
            {/* <h2>{this.state.conquasMemberId}</h2> */}
            <div>
              <div>
                <SectionWithBorder
                  totalColumn={1}
                  textSize="2xl"
                  className="font-bold"
                  column1="Student Membership Subscription"
                >
                  <div className="px-7">
                    <div className="grid grid-cols-12 gap-6 w-full items-center py-7">
                      <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                        <div className="grid grid-cols-12 gap-6">
                          <div className="xl:col-span-12 md:col-span-12 col-span-12">
                            <label
                              className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                              for="default"
                            >
                              {" "}
                              Billing Category{" "}
                              <span className="text-[#c00000]">*</span>{" "}
                            </label>
                            <DropdownSelect
                              drpIdentity={"billingCategories"}
                              optionArray={this.state.billingCategories}
                              setFilterParameters={this.setFilterParameters}
                              value={
                                this.state.studentSignUpData.billingCategoryId
                              }
                              disabled={this.state.disableSubscription}
                            />
                          </div>
                        </div>
                      </div>
                      {/* <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                                                <div className="grid grid-cols-12 gap-6">
                                                    <div className="xl:col-span-12 md:col-span-12 col-span-12">
                                                        <label
                                                            className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                                                            for="default"
                                                        >
                                                            Updated By<span className="text-[#c00000]">*</span>{" "}
                                                        </label>
                                                        <DropdownSelect
                                                            drpIdentity={"updatedBy"}
                                                            optionArray={this.state.users}
                                                            setFilterParameters={this.setFilterParameters}
                                                            value={this.state.studentSignUpData.updatedBy}
                                                            disabled={this.state.disableSubscription}
                                                        />
                                                    </div>
                                                </div>
                                            </div> */}
                      <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                        <div className="grid grid-cols-12 gap-6">
                          <div className="xl:col-span-12 md:col-span-12 col-span-12">
                            <label
                              className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30 "
                              for="default"
                            >
                              {" "}
                              Subscription Start Date{" "}
                              <span className="text-[#c00000]">*</span>{" "}
                            </label>
                            <div className="grid grid-cols-12 gap-6 items-center w-full">
                              <div className=" 2xl:col-span-12 lg:col-span-12 col-span-12 w-full disabled-input">
                                <input
                                  id="default"
                                  name="subscriptionStartDate"
                                  className={`date-pickers form-input rounded-none w-full ${this.state.disableSubscription
                                    ? "bg-[#C4C4C4]"
                                    : null
                                    } shadow-red py-3 text-lg opacity-50 cursor-not-allowed`}
                                  type="date"
                                  value={moment(
                                    this.state.studentSignUpData
                                      .subscriptionStartDate
                                  ).format("yyyy-MM-DD")}
                                  onChange={(event) =>
                                    this.state.handleChange(event)
                                  }
                                  disabled={this.state.disableSubscription}
                                // onBlur={() => this.state.validateField("publishDate")}
                                />
                                {/* <div>
                          <ValidationText error={this.state.validState.error.publishDate} />
                          </div> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                        <div className="grid grid-cols-12 gap-6">
                          <div className="xl:col-span-12 md:col-span-12 col-span-12">
                            <label
                              className="filter-lable flex w-full font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                              for="default"
                            >
                              <span>Last Updated Date </span>
                              <span className="text-[#c00000]">*</span>{" "}
                            </label>
                            <div className="grid grid-cols-12 gap-6 items-center w-full">
                              <div className=" 2xl:col-span-12 lg:col-span-12 col-span-12 w-full disabled-input">
                                <input
                                  id="default"
                                  name="lastUpdatedDate"
                                  className={`date-pickers form-input rounded-none w-full ${this.state.disableSubscription
                                    ? "bg-[#C4C4C4]"
                                    : null
                                    } shadow-red py-3 text-lg opacity-50 cursor-not-allowed`}
                                  type="date"
                                  value={moment(
                                    this.state.studentSignUpData.lastUpdatedDate
                                  ).format("yyyy-MM-DD")}
                                  onChange={(event) => this.handleChange(event)}
                                  disabled={this.state.disableSubscription}
                                // onBlur={() => this.state.validateField("publishDate")}
                                />
                                {/* <div>
                          <ValidationText error={this.state.validState.error.publishDate} />
                          </div> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts pt-3">
                        <div className="grid grid-cols-12 gap-6">
                          <div className="xl:col-span-12 md:col-span-12 col-span-12">
                            <label
                              className="filter-lable block font-medium 2xl:mb-1 lg:[0.25rem] theme-color min-h-30"
                              for="default"
                            >
                              {" "}
                              Subscription End Date{" "}
                              <span className="text-[#c00000]">*</span>{" "}
                            </label>
                            <div className="grid grid-cols-12 gap-6 items-center w-full">
                              <div className=" 2xl:col-span-12 lg:col-span-12 col-span-12 w-full disabled-input">
                                <input
                                  id="default"
                                  name="subscriptionEndDate"
                                  className={`date-pickers form-input rounded-none w-full ${this.state.disableSubscription
                                    ? "bg-[#C4C4C4]"
                                    : null
                                    } shadow-red py-3 text-lg opacity-50 cursor-not-allowed`}
                                  type="date"
                                  value={moment(
                                    this.state.studentSignUpData
                                      .subscriptionEndDate
                                  ).format("yyyy-MM-DD")}
                                  onChange={(event) =>
                                    this.state.handleChange(event)
                                  }
                                  disabled={this.state.disableSubscription}
                                // onBlur={() => this.state.validateField("publishDate")}
                                />
                                {/* <div>
                          <ValidationText error={this.state.validState.error.publishDate} />
                          </div> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="xl:col-span-6 lg:col-span-6 col-span-12 input-sec-texts">
                        <div className="grid grid-cols-12 gap-6">
                          <div className="xl:col-span-12 md:col-span-12 col-span-12">
                            <button
                              className={`${this.state.studentSignUpData.statusId === 4
                                ? ""
                                : "disabledButtonForCerti"
                                } col-end-9 col-span-2 text-xl font-bold btn btn-blue w-[250px] p-3 mt-4 `}
                              onClick={() => {
                                this.DownloadCertificateForStudentMemberById();
                              }}
                            >
                              Download Certificate
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </SectionWithBorder>
              </div>

              <SectionWithBorder
                totalColumn={1}
                textSize="2xl"
                className="font-bold"
                column1="Personal Particular"
              >
                <StudentApplication
                  // studentPricingDetails={this.state.studentPricingDetails}
                  studentSignUpData={this.state.studentSignUpData}
                  countryList={this.state.countryList}
                  postalCodeList={this.state.postalCodeList}
                  birthPalceList={this.state.birthPalceList}
                  nationalityList={this.state.nationalityList}
                  nameOfInstitutionList={this.state.nameOfInstitutionList}
                  yearList={this.state.yearList}
                  qualificationList={this.state.qualificationList}
                  knowAboutUsList={this.state.knowAboutUsList}
                  validState={this.state.validState}
                  // onSubmit={this.onSubmit.bind(this)}
                  handleChange={this.handleChange.bind(this)}
                  handleChangeSelectorDropdown={this.handleChangeSelectorDropdown.bind(
                    this
                  )}
                  handleChangeInCheckbox={this.handleChangeInCheckbox.bind(
                    this
                  )}
                  onFileChange={this.onFileChange.bind(this)}
                  validateField={this.validateField.bind(this)}
                  myRef={this.myRef}
                  handleChangeInDropdown={this.handleChangeInDropdown.bind(
                    this
                  )}
                  saveMainImage={this.saveMainImage.bind(this)}
                  deleteFile={this.deleteFile.bind(this)}
                  characterLimit={this.state.characterLimit}
                  editStudentMemberDetails={this.editStudentMemberDetails.bind(this)}
                />
              </SectionWithBorder>
            </div>

            <div>
              <SectionWithBorder
                totalColumn={1}
                textSize="2xl"
                className="font-bold"
                column1="Financials"
              >
              </SectionWithBorder>
              {this.state.studentSignUpData.studentFinanceDetailsResponseList && this.state.studentSignUpData.studentFinanceDetailsResponseList.length > 0 && this.state.studentSignUpData.studentFinanceDetailsResponseList.map((value, key) => (

                <div className="grid grid-cols-12 gap-6 w-full px-6 bg-[#fff] mb-6">
                  <div className="2xl:col-span-10 lg:col-span-9 col-span-12 2xl:pt-7 lg:mt-4 items-center">
                    <div className="grid grid-cols-12 gap-2">
                      <div className="xl:col-span-12 lg:col-span-12 col-span-12">
                        <div className="flex items-start justify-between">
                          <div className="left-side">
                            <h2 className="text-xl theme-color 2xl:mb-3">
                              Invoice Number :{" "}
                              <span>{value.invoiceNumber}</span>
                            </h2>
                            <h2 className="text-xl theme-color 2xl:mb-3">
                              Date:{" "}
                              <span>
                                {moment(
                                  value.certificateCreateDate
                                ).format("DD MMM YYYY")}
                              </span>
                            </h2>

                            <h2 className="text-xl theme-color 2xl:mb-3">
                              Payment Status:{" "}
                              <span>{value.paymentStatus}</span>
                            </h2>
                          </div>
                          <div className="right-side">

                            <h2 className="text-xl theme-color 2xl:mb-3">
                              Amount (Billable) :{" "}
                              <span>${value.totalAmount} </span>
                            </h2>

                            <h2 className="text-xl theme-color ">
                              Amount (Paid) :{" "}
                              <span>
                                {value.paymentStatus === "Pending" ? (
                                  "$0.00"
                                ) : (
                                  <>${value.totalAmount}</>
                                )}{" "}
                              </span>
                            </h2>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="2xl:col-span-2 lg:col-span-3 col-span-12">
                    <div className="relative flex ">

                      <div className="right-btn mt-20 w-full">
                        <div className="flex items-center justify-end mb-5">
                          <button
                            className={`${value.statusId === 4
                              ? ""
                              : "disabledButtonForCerti"
                              } 2xl:px-7 lg:px-5 py-2 btn btn-blue-border font-bold  text-xl border text-[#005B9C]`}
                            onClick={() =>
                              this.DownloadReceiptForStudentMemberById(
                                value.receiptPath
                              )
                            }
                          >
                            View Receipt
                          </button>
                        </div>

                        <div className="flex items-center justify-end mb-5">
                          <button
                            className={`${this.state.studentSignUpData.statusId === 4
                              ? ""
                              : "disabledButtonForCerti"
                              } 2xl:px-7 lg:px-5 py-2 btn btn-blue-border font-bold  text-xl border text-[#005B9C]`}
                            onClick={() =>
                              this.DownloadInvoiceForStudentMemberById(
                                value.invoicePath
                              )
                            }
                          >
                            View Invoice
                          </button>
                        </div>
                        <div className="flex items-center justify-end mb-5">
                          <button
                            className={`${this.state.studentSignUpData.statusId === 4
                              ? ""
                              : "disabledButtonForCerti"
                              } 2xl:px-7 lg:px-5 py-2 btn btn-blue-border font-bold text-xl border text-[#005B9C]`}
                            onClick={() =>
                              this.DownloadCertificateForStudentMemberByPath(
                                value.certificatePath
                              )
                            }
                          >
                            View Certificate
                          </button>
                        </div>


                      </div>
                    </div>
                  </div>
                </div>

              )
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}
