import Dashboard from "../pages/dashboard/Dashboard";
import MembershipPricing from "../pages/membership/membershipPricing/MembershipPricing";
import SLOTSPricing from "../pages/membership/membershipPricing/SLOTSPricing";
import MembershipTaskList from "../pages/membership/membershipTaskList/MembershipTaskList";
import IndividualMemberTaskList from "../pages/membership/membershipTaskList/IndividualMemberTaskList";
// import MembershipFeeApproval from "../pages/membership/membershipFeeApproval/MembershipFeeApproval";
import ViewCorporateMembers from "../pages/membership/corporateMembers/ViewCorporateMembers";
import CreateCorporateMembers from "../pages/membership/corporateMembers/CreateCorporateMembers";
import IndividualMembers from "../pages/membership/individualMembers/IndivisualMembers";
import CreateIndividualMembers from "../pages/membership/individualMembers/CreateIndividualMembers";
import DropdownManagement from "../pages/fieldManagement/DropdownManagement";
import EmailTemplateManagement from "../pages/fieldManagement/EmailTemplateManagement";
import ManageTags from "../pages/fieldManagement/ManageTags";
import CreateEmailTemplate from "../components/fieldManagement/CreateEmailTemplate";

import CorporateMemberViewOnly from "../pages/membershipViewOnly/CorporateMemberViewOnly";
import IndividualMembersViewOnly from "../pages/membershipViewOnly/IndividualMemberViewOnly";
import SLOTSAccreditationViewOnly from "../pages/membershipViewOnly/SLOTSAccreditationViewOnly";

// Financial Start
import GlobalSettingFinance from "../pages/financial/GlobalSettingFinance";
//import ChartsOfAccounts from "../pages/financial/ChartsOfAccounts";
import PaymentReconcilation from "../pages/financial/PaymentReconcilation";
//import CreditNoteApprovalProcess from "../pages/financial/CreditNoteApprovalProcess";
import BatchJobExtraction from "../pages/financial/BatchJobExtraction";
import CreditNoteApproval from "../pages/financial/CreditNoteApproval";
//import AddEditExternalInvoice from "../pages/financial/AddEditExternalInvoice";
import CreditNoteManagement from "../pages/financial/CreditNoteManagement";
//import ExternalInvoices from "../pages/financial/ExternalInvoices";
import ViewBookingDetail from "../pages/financial/ViewBookingDetail";
import UnAuthorized from "../pages/authentication/UnAuthorized";
import PageNotFound from "../pages/pageNotFound/PageNotFound";
// import VoucherCodeManagement from "../pages/financial/VoucherCodeManagement";
//import CreateVoucherCode from "../pages/financial/CreateVoucherCode";
//import EventRegistrationApproval from "../pages/financial/EventRegistrationApproval";
import ExtractInvoices from "../pages/financial/ExtractInvoices";
import DeferredIncomeReport from "../pages/financial/DeferredIncomeReport";
import CreateAdminAccount from "../pages/accountManagement/CreateAdminAccount";
import ViewEditAdminAccount from "../pages/accountManagement/ViewEditAdminAccount";
import SLOTAccreditation from "../pages/membership/slotsMembers/SLOTAccreditation";
import EditSLOTSMember from "../pages/membership/slotsMembers/EditSLOTSMember";

import ARAgingReport from "../pages/financial/ARAgingReport";
import GSTReport from "../pages/financial/GSTReport";
import TransactionReport from "../pages/financial/TransactionReport";
import PaymentReconciliationExtraction from '../pages/financial/PaymentReconciliationExtraction';

import ChangePassword from "../pages/authentication/ChangePassword";
import Export from "../pages/report/Export";
import ReportForSLOTs from "../pages/report/ReportForSLOTs";
import CONQUASPricing from "../pages/membership/membershipPricing/CONQUASPricing";
// import CONQUASMemberService from "../services/axiosServices/apiServices/CONQUASMemberServices";
import CONQUASRegistration from "../pages/membership/conquasMembers/CONQUASRegistration";
import EditCONQUASMember from "../pages/membership/conquasMembers/EditCONQUASMember";
import SlotRenewalReport from "../pages/report/SlotRenewalReport";
import SlotNewlySubmittedReport from "../pages/report/SlotNewlySubmittedReport";
import StudentPricing from "../pages/membership/membershipPricing/StudentPricing";
import StudentMemberTaskList from "../pages/membership/membershipTaskList/StudentMemberTaskList";
import StudentRegistration from "../pages/membership/studentmembers/StudentRegistration";
import EditStudentMember from "../pages/membership/studentmembers/EditStudentMember";
import OATIandSlotsUpdateReport from "../pages/report/OATIandSlotsUpdateReport";


const ComponentNavigation = [
  {
    path: "/",
    title: "Dashboard",
    component: Dashboard,
    hasParams: false,
    roles: [1, 2, 3, 4, 5, 6, 7, 8, 9]
  },
  {
    path: "Dashboard",
    title: "Dashboard",
    component: Dashboard,
    hasParams: false,
    roles: [1, 2, 3, 4, 5, 6, 7, 8, 9]
  },
  {
    path: "PageNotFound",
    title: "PageNotFound",
    component: PageNotFound,
    hasParams: false,
    roles: [1, 2, 3, 4, 5, 6, 7, 8, 9]
  },

  {
    path: "Membership/Pricing",
    previousPath: "Membership",
    title: "Membership Pricing",
    component: MembershipPricing,
    hasParams: false,
    roles: [1],
  },

  {
    path: "Membership/SLOTSPricing",
    previousPath: "Membership",
    title: "SLOTs Pricing",
    component: SLOTSPricing,
    hasParams: false,
    roles: [1],
  },

  {
    path: "Membership/StudentPricing",
    previousPath: "StudentPricing",
    title: "Student Pricing",
    component: StudentPricing,
    hasParams: false,
    roles: [1],
  },


  {
    path: "Membership/MembershipTaskList",
    previousPath: "Membership",
    title: "Membership Task List",
    component: MembershipTaskList,
    hasParams: false,
    roles: [1, 2],
  },
  {
    path: "Membership/IndividualMemberTaskList",
    previousPath: "Membership",
    title: "Individual Member Task List",
    component: IndividualMemberTaskList,
    hasParams: false,
    roles: [1, 2],
  },
  {
    path: "Membership/StudentMemberTaskList",
    previousPath: "Membership",
    title: "Student Member Task List",
    component: StudentMemberTaskList,
    hasParams: false,
    roles: [1, 2],
  },
  // {
  //   path: "Membership/MembershipFeeApproval",
  //   previousPath: "Membership",
  //   title: "Membership Fee Approval",
  //   component: MembershipFeeApproval,
  //   hasParams: false,
  //   roles: [1, 2],
  // },

  {
    path: "ViewCorporateMembers",
    previousPath: "Membership",
    title: "View Corporate Members",
    component: ViewCorporateMembers,
    hasParams: false,
    roles: [1, 2],
  },

  // Prasad
  {
    path: "CorporateMemberViewOnly",
    previousPath: "Membership",
    title: "View Corporate Members",
    component: CorporateMemberViewOnly,
    hasParams: false,
    roles: [1, 9],
  },

  {
    path: "IndividualMembersViewOnly",
    previousPath: "Membership",
    title: "View Individual Members",
    component: IndividualMembersViewOnly,
    hasParams: false,
    roles: [1, 9],
  },

  {
    path: "SLOTSAccreditationViewOnly",
    previousPath: "Membership",
    title: "SLOTs Registration",
    component: SLOTSAccreditationViewOnly,
    hasParams: true,
    roles: [1, 9]
  },

  {
    path: "EditSLOTSMember/:SLOTSMemberId",
    previousPath: "Membership",
    title: "Edit SLOTs Member",
    component: EditSLOTSMember,
    hasParams: true,
    roles: [1, 2, 9]
  },

  {
    path: "CreateCorporateMembers",
    previousPath: "Membership",
    title: "Create Corporate Member",
    component: CreateCorporateMembers,
    hasParams: false,
    roles: [1, 2],
  },
  {
    path: "CreateCorporateMembers/:CorporateMemberId",
    previousPath: "Membership",
    title: "Edit Corporate Member",
    component: CreateCorporateMembers,
    hasParams: true,
    roles: [1, 2, 9]
  },
  {
    path: "IndividualMembers",
    previousPath: "Membership",
    title: "View Individual Members",
    component: IndividualMembers,
    hasParams: false,
    roles: [1, 2,],
  },
  {
    path: "CreateIndividualMembers",
    previousPath: "Membership",
    title: "Create Individual Members",
    component: CreateIndividualMembers,
    hasParams: false,
    roles: [1, 2],
  },
  {
    path: "CreateIndividualMembers/:IndividualMemberId",
    previousPath: "Membership",
    title: "Create Individual Members",
    component: CreateIndividualMembers,
    hasParams: true,
    roles: [1, 2, 9]
  },
  {
    path: "DropdownManagement",
    previousPath: "Field Management",
    title: "Dropdown Management",
    component: DropdownManagement,
    roles: [1],
    hasParams: false,
  },
  {
    path: "ManageTags",
    previousPath: "Field Management",
    title: "Manage Tags",
    component: ManageTags,
    roles: [1],
    hasParams: false,
  },
  {
    path: "EmailTemplateManagement",
    previousPath: "Field Management",
    title: "Email Template Management",
    component: EmailTemplateManagement,
    roles: [1],
    hasParams: false,
  },
  {
    path: "CreateEmailTemplate",
    previousPath: "Field Management",
    title: "Create Email Template",
    component: CreateEmailTemplate,
    roles: [1],
    hasParams: false,
  },

  {
    path: "CreateEmailTemplate/:emailTemplateId",
    previousPath: "Field Management",
    title: "Create Email Template",
    component: CreateEmailTemplate,
    roles: [1],
    hasParams: true,
  },


  // {
  //   path: "Membership/MembershipDirectoryEnquiry",
  //   previousPath: "Membership",
  //   title: "Membership Directory Enquiry",
  //   component: MembershipDirectoryEnquiry,
  //   roles: [1, 7],
  //   hasParams: false,
  // },


  // Financial
  {
    path: "financial/GlobalSettingFinance",
    previousPath: "Financial",
    title: "Global Setting Finance",
    component: GlobalSettingFinance,
    roles: [1, 3],
    hasParams: false,
  },
  // {
  //   path: "financial/ChartsOfAccounts",
  //   previousPath: "Financial",
  //   title: "Charts of Accounts",
  //   component: ChartsOfAccounts,
  //   roles: [1, 3],
  //   hasParams: false,
  // },
  {
    path: "financial/PaymentReconciliation",
    previousPath: "Financial",
    title: "Payment Reconciliation",
    component: PaymentReconcilation,
    roles: [1, 3],
    hasParams: false,
  },

  {
    path: "financial/ARAgingReport",
    previousPath: "Financial",
    title: "AR Aging Report",
    component: ARAgingReport,
    roles: [1, 3],
    hasParams: false,
  },

  {
    path: "financial/GSTReport",
    previousPath: "Financial",
    title: "GST Report",
    component: GSTReport,
    roles: [1, 3],
    hasParams: false,
  },

  {
    path: "financial/TransactionReport",
    previousPath: "Financial",
    title: "Transaction Report",
    component: TransactionReport,
    roles: [1, 3],
    hasParams: false,
  },

  {
    path: "financial/BatchJobExtraction",
    previousPath: "Financial",
    title: "Batch Job Extraction",
    component: BatchJobExtraction,
    roles: [1, 3],
    hasParams: false,
  },


  {
    path: "financial/PaymentReconciliationExtraction",
    previousPath: "Financial",
    title: "Zero Payment Reconciliation Extraction",
    component: PaymentReconciliationExtraction,
    roles: [1, 3],
    hasParams: false,
  },
  {
    path: "financial/CreditNoteApproval",
    previousPath: "Financial",
    title: "Credit Note Approval",
    component: CreditNoteApproval,
    roles: [1, 3, 4],
    hasParams: false,
  },
  // {
  //   path: "financial/AddEditExternalInvoice",
  //   previousPath: "Financial",
  //   title: "External Invoices",
  //   component: AddEditExternalInvoice,
  //   roles: [1, 3],
  //   hasParams: false,
  // },
  // {
  //   path: "financial/AddEditExternalInvoice",
  //   previousPath: "Financial",
  //   title: "External Invoices",
  //   component: Accountpayable,
  //   roles: [1, 3],
  //   hasParams: false,
  // },

  // khushbu 27/9 Start
  // {
  //   path: "financial/AddEditExternalInvoice/:financeExternalInvoiceId",
  //   previousPath: "Financial",
  //   title: "External Invoices ",
  //   component: AddEditExternalInvoice,
  //   roles: [1, 3],
  //   hasParams: false,
  // },
  {
    path: "financial/CreditNoteManagement/:CreditNoteId",
    previousPath: "Financial",
    title: "Credit Note Management ",
    component: CreditNoteManagement,
    roles: [1, 3],
    hasParams: false,
  },
  // {
  //   path: "financial/ExternalInvoices",
  //   previousPath: "Financial",
  //   title: "External Invoices ",
  //   component: ExternalInvoices,
  //   roles: [1, 3],
  //   hasParams: false,
  // },


  {
    path: "financial/ViewBookingDetail/:bookingAttendeeInformationId/:bookingId",
    previousPath: "Financial",
    title: "View Booking Detail ",
    component: ViewBookingDetail,
    roles: [1, 3],
    hasParams: true,
  },
  // khushbu 27/9 End

  // {
  //   path: "financial/VoucherCodeManagement",
  //   previousPath: "Financial",
  //   title: "Voucher Code Management",
  //   component: VoucherCodeManagement,
  //   roles: [1, 3],
  //   hasParams: true,
  // },

  // {
  //   path: "createVoucherCode/:voucherCodeId",
  //   previousPath: "VoucherCodeManagement",
  //   title: "Create Voucher Code",
  //   component: CreateVoucherCode,
  //   roles: [1, 3],
  //   hasParams: true,
  // },
  // {
  //   path: "createVoucherCode",
  //   previousPath: "VoucherCodeManagement",
  //   title: "Create Voucher Code",
  //   component: CreateVoucherCode,
  //   roles: [1, 3],
  //   hasParams: true,
  // },




  // Account management Start

  {
    path: "CreateAdminAccount",
    previousPath: "Account Management",
    title: "Create Admin Account",
    component: CreateAdminAccount,
    roles: [1, 8],
    hasParams: false,
  },
  {
    path: "CreateAdminAccount/adminId",
    title: "Edit Admin Account",
    component: CreateAdminAccount,
    roles: [1, 8],
    hasParams: false,
  },
  {
    path: "ViewEditAdminAccount",
    previousPath: "Account Management",
    title: "View/Edit Admin Account",
    component: ViewEditAdminAccount,
    roles: [1, 8],
    hasParams: false,
  },

  // Account management End
    
  // Un Authorized 
  {
    path: "authentication/UnAuthorized",
    previousPath: "UnAuthorized",
    title: "UnAuthorized",
    component: UnAuthorized,
    roles: [1, 2, 3, 4, 5, 6, 7, 8],
    hasParams: false,
  },

  // {
  //   path: "financial/EventRegistrationApproval",
  //   previousPath: "Financial",
  //   title: "Event Registration Approval ",
  //   component: EventRegistrationApproval,
  //   roles: [1, 3],
  //   hasParams: false,
  // },
  {
    path: "financial/ExtractInvoices",
    previousPath: "Financial",
    title: "Extract Invoices",
    component: ExtractInvoices,
    roles: [1, 3],
    hasParams: false,
  },

  {
    path: "financial/DeferredIncomeReport",
    previousPath: "Financial",
    title: "Deferred Income Report",
    component: DeferredIncomeReport,
    roles: [1, 3],
    hasParams: false,
  },

  {
    path: "Membership/SLOTAccreditation",
    previousPath: "Membership",
    title: "SLOTs Registration",
    component: SLOTAccreditation,
    hasParams: false,
    roles: [1, 2, 9],
    checkView: "ViewOnly"
  },

  {
    path: "/ChangePassword",
    previousPath: "ChangePassword",
    title: "ResetPassword",
    component: ChangePassword,
    hasParams: false,
    roles: [1, 2, 3, 9],
  },

  // {
  //   path: "financial/PaymentReconciliation",
  //   previousPath: "Financial",
  //   title: "Payment Reconciliation",
  //   component: PaymentReconcilation,
  //   roles: [1, 3],
  //   hasParams: false,
  // },

  {
    path: "report/ReportForSLOTs",
    previousPath: "report",
    title: "Report For SLOTs",
    component: ReportForSLOTs,
    roles: [1, 3],
    hasParams: false,
  },
  {
    path: "report/Exports",
    previousPath: "report",
    title: "Data Extraction",
    component: Export,
    roles: [1, 3],
    hasParams: false,
  },
  {
    path: "Membership/CONQUASRegistration",
    previousPath: "Membership",
    title: "CONQUAS Registration",
    component: CONQUASRegistration,
    hasParams: false,
    roles: [1, 2, 9],
  },
  {
    path: "Membership/CONQUASPricing",
    previousPath: "Membership",
    title: "CONQUAS Pricing",
    component: CONQUASPricing,
    hasParams: false,
    roles: [1],
  },
  {
    path: "EditCONQUASMember/:CONQUASMemberId",
    previousPath: "Membership",
    title: "Edit SLOTs Member",
    component: EditCONQUASMember,
    hasParams: true,
    roles: [1, 2, 9]
  },
  {
    path: "report/SlotRenewalReport",
    previousPath: "report",
    title: "Slot Renewal Report",
    component: SlotRenewalReport,
    roles: [1, 3],
    hasParams: false,
  },
  {
    path: "report/OATIandSlotsUpdateReport",
    previousPath: "Report",
    title: "OATI and Slots Update Report",
    component : OATIandSlotsUpdateReport,
    roles: [1, 3],
    hasParams: false,
  },
  {
    path: "report/SlotNewlySubmittedReport",
    previousPath: "report",
    title: "Slot Newly Submitted Report",
    component: SlotNewlySubmittedReport,
    roles: [1, 3],
    hasParams: false,
  },
  {
    path: "StudentMember/StudentRegistration",
    previousPath: "StudentMember",
    title: "Student Registration",
    component: StudentRegistration,
    hasParams: false,
    roles: [1, 2, 9],
  },
  {
    path: "EditStudentMember/:StudentMemberId",
    previousPath: "Membership",
    title: "Edit Student Member",
    component: EditStudentMember,
    hasParams: true,
    roles: [1, 2, 9]
  },
];
export default ComponentNavigation;