import React, { Component } from "react";
import { Navigate } from "react-router-dom";

import ButtonLoader from "../../components/common/buttonLoader/ButtonLoader";
import LogoImage from "../../images/logo.png";
import AuthenticationServices from "../../services/axiosServices/apiServices/AuthenticationServices";
import SwalServices from "../../services/swalServices/SwalServices";
import { setAuthProps } from "../../utils/AuthenticationLibrary";
import { ErrorMessage } from "../../utils/Messages";
import { isValidForm, validate } from "../../utils/validation/CommonValidator";
import ValidationText from "../../utils/validation/ValidationText";
import { MsalContext } from "@azure/msal-react";
import { withMsal } from "@azure/msal-react";
import { loginRequest, msalConfig } from "../../HOC/authConfig";
import { InteractionStatus, PublicClientApplication } from "@azure/msal-browser";
const msalInstance = new PublicClientApplication(msalConfig);

class Login extends Component {
  static contextType = MsalContext;
  constructor(props) {
    super(props);
    this.authServices = new AuthenticationServices();
    this.swalServices = new SwalServices();

    this.state = {
      isLoading: false,
      redirect: "",
      userDetails: {
        userName: "",
        password: "",
      },
      validationRules: {
        userName: [
          {
            type: "require",
            message: ErrorMessage.FieldRequired.replace("{0}", "Email address"),
          },
          {
            type: "email",
            message: ErrorMessage.NOTVALIDPATTERN.replace(
              "{0}",
              "Email address"
            ),
          },
        ],
        password: [
          {
            type: "require",
            message: ErrorMessage.FieldRequired.replace("{0}", "Password"),
          },
          //   { type: 'password', message: ErrorMessage.PasswordNotValid }
        ],
      },
      validState: {
        isValid: true,
        error: {},
      },
    };
  }

  validateField = (key) => {
    const newValidState = validate(
      key,
      this.state.userDetails,
      this.state.validationRules,
      this.state.validState
    );
    this.setState({ validState: newValidState });
  };

  isValidateAllFields = () => {
    const newValidState = isValidForm(
      this.state.userDetails,
      this.state.validationRules,
      this.state.validState
    );
    this.setState({ validState: newValidState });
    return newValidState.isValid;
  };

  redirectToDashboard = () => {
    this.setState({ route: "/Dashboard" });
  };

  login = () => {
    if (this.isValidateAllFields()) {
      this.setState({ isLoading: true });
      this.authServices.adminLogin(this.state.userDetails).then((response) => {
        console.log(response);
        if (response.statusCode === 200 && response.responseItem) {
          let authDetails = response.responseItem.responseContent;
          setAuthProps(authDetails);
          localStorage.setItem("adminUserId", authDetails.adminUserId);
          // this.setState({ route: "/Dashboard" });
          window.location.href = "/Dashboard";
        } else {
          this.swalServices.Error(ErrorMessage.InvalidAuthorization);
        }
        this.setState({ isLoading: false });
      });
    }
   };

  handleLogin(loginType) {
    this.setState({ isLoading: true });
    const isAuthenticated = this.props.msalContext.accounts.length > 0;
    if (!isAuthenticated && this.props.msalContext.inProgress === InteractionStatus.None) {
      msalInstance.loginPopup(loginRequest).then(response => {
        console.log(response);
        var azureusername = response.account.username;
        this.authServices.adminLogin(azureusername).then((response) => {
          console.log(response);
          if (response.statusCode === 200 && response.responseItem) {
            let authDetails = response.responseItem.responseContent;
            setAuthProps(authDetails);
            window.location.href = "/Dashboard";
          }
          else {
            this.swalServices.Error(ErrorMessage.InvalidAuthorization);
          }
        });
      })
    }

  }



  handleChange = (key, value) => {
    let newUserDetails = { ...this.state.userDetails };
    newUserDetails[key] = value;
    this.setState({ userDetails: newUserDetails });
  };

  render() {
    if (this.state.route != null) {
      return <Navigate to={this.state.route} />;
    }

    return (
      <div>
        <div className="main-login-sec">
          <div className="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8 bg-white w-full">
            <div className="login-main-bg max-w-md w-full space-y-8 p-8 rounded-sm shadow-red">
              <div className="">
                <img
                  className="mx-auto w-[220px]"
                  src={LogoImage}
                  alt="logo"
                />
                <h2 className="mt-5 text-center text-3xl font-extrabold ">
                  Log in
                </h2>
              </div>
              <input type="hidden" name="remember" value="true" />
              <div className="rounded-md -space-y-px relative">
                <div>
                  <label for="email-address" className="sr-only">
                    Email address
                  </label>
                  <input
                    id="email-address"
                    name="email"
                    type="email"
                    autoComplete="email"
                    required
                    className="login-input appearance-none rounded-none relative block w-full px-3 py-2 border border-[#005b9c] placeholder-gray-900 text-[#005b9c] rounded-t-md focus:outline-none focus:ring-[#005b9c] focus:border-[#004270] focus:z-10 sm:text-sm bg-white"
                    placeholder="Email address"
                    value={this.state.userDetails.userName}
                    onChange={(event) =>
                      this.handleChange("userName", event.target.value)
                    }
                    onBlur={() => this.validateField("userName")}
                  />
                  <ValidationText
                    className="absolute left-0 top-[100%]"
                    error={this.state.validState.error.userName}
                  />
                </div>

                <div className="mt-4">
                  <label for="password" className="sr-only">
                    Password
                  </label>
                  <input
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="current-password"
                    required
                    className=" login-input appearance-none rounded-none relative block w-full px-3 py-2 border border-[#005b9c] placeholder-gray-900 text-[#005b9c] rounded-b-md focus:outline-none focus:ring-[#005b9c] focus:border-[#004270] focus:z-10 sm:text-sm bg-white"
                    placeholder="Password"
                    value={this.state.userDetails.password}
                    onChange={(event) =>
                      this.handleChange("password", event.target.value)
                    }
                    onBlur={() => this.validateField("password")}
                  />
                  <ValidationText
                    className="absolute left-0 top-[100%]"
                    error={this.state.validState.error.password}
                  />
                </div>
              </div>

              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <input
                    id="remember-me"
                    name="remember-me"
                    type="checkbox"
                    className="h-4 w-4 text-[#005b9c] focus:ring-[#004270] rounded"
                  />
                  <label
                    for="remember-me"
                    className="ml-2 block text-sm text-gray-900"
                  >
                    {" "}
                    Remember me{" "}
                  </label>
                </div>

                <div className="text-sm">
                  <a
                    href="/ForgetPassword"
                    className="font-medium text-[#005b9c] hover:text-[#004270]"
                  >
                    {" "}
                    Forgot your password?{" "}
                  </a>
                </div>
              </div>

              <div className="flex justify-center">
                {this.state.isLoading ? (
                  <ButtonLoader className="btn btn-blue" />
                ) : (
                  <button
                    type="submit"
                    className="group relative w-2/5 flex items-center justify-center border border-transparent text-sm font-medium text-white btn btn-blue focus:outline-none"
                    onClick={() => this.login()}
                  >
                    <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                      <svg
                        className="h-5 w-5 text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </span>
                    Sign in
                  </button>
                )}
              </div>

             

            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Login = withMsal(Login);
