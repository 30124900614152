import moment from "moment";
import React, { Component } from "react";
import BatchJobExtractionServices from "../../services/axiosServices/apiServices/BatchJobExtractionServices";
import SwalServices from "../../services/swalServices/SwalServices";
import { saveAs } from "file-saver";
import PageLoader from "../../components/common/pageLoader/PageLoader";

export default class PaymentReconciliationExtraction extends Component {
  constructor(props) {
    super(props);
    this.batchJobExtractionServices = new BatchJobExtractionServices();
    this.swalServices = new SwalServices();
    this.state = {

      weeklyPaymentReconsiliationRecordList: [],
      totalWeeklyRecordCount: 0,
      paginationPaymentReconsiliation: {
        pageNo: 1,
        pageSize: 10,
        orderByColumn: "CreatedAt",
        orderFlag: 0,
        searchText: "",
      },
      generateFromDateWeekly: '',
      isLoading: false
    };
  }

  componentDidMount = () => {
    this.GetAllWeeklyPaymentRecociliationRecords();
  }

  GetAllWeeklyPaymentRecociliationRecords = () => {
    this.setState({ isLoading: true });
    let paginationPaymentReconsiliation = { ...this.state.paginationPaymentReconsiliation };
    this.batchJobExtractionServices.GetAllWeeklyPaymentRecociliationRecords(paginationPaymentReconsiliation).then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {
        let details = response.responseItem.responseContent.itemList;
        this.setState({
          weeklyPaymentReconsiliationRecordList: details,
          totalWeeklyRecordCount: response.responseItem.responseContent.totalCount
        });
      }

      this.setState({ isLoading: false });
    });
  }



  GenerateWeeklyPaymentReconciliation = () => {
    if (this.state.generateFromDateWeekly) {
      this.setState({ isLoading: true });
      this.batchJobExtractionServices.GenerateWeeklyPaymentReconciliation(this.state.generateFromDateWeekly).then((response) => {
        if (response.statusCode === 200 && response.responseItem != null) {
          let details = response.responseItem.responseContent.itemList;
          this.swalServices.Success("Payment Recociliation Records generated successfully.");
          this.GetAllWeeklyPaymentRecociliationRecords();
        }
        else if (response.statusCode === 409) {
          this.swalServices.Error(response.message);
        }
        else {
          this.swalServices.Error(response.message);
        }
        this.setState({ isLoading: false });
      });
    }
    else {
      this.swalServices.Alert('Please select date');
    }
  }


  DownloadSheetByXeroPaymentReconciliationId = (xeroBatchJobId, batchJobType) => {
    this.setState({ isLoading: true });
    let request = [];
    request.push(xeroBatchJobId);
    this.batchJobExtractionServices.DownloadSheetByXeroPaymentReconciliationId(request).then((response) => {
      if (response.status === 200 && response.data != null) {
        var blob = new Blob([response.data])
        saveAs(blob, `${batchJobType}BatchJob-Payment-reconciliation.xlsx`);

      }
      else {
        this.swalServices.Error(response.message);
      }
      this.setState({ isLoading: false });
    });
  }

  handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    if (name === 'generateFromDateWeekly') {
      this.setState({ generateFromDateWeekly: value })
    }
  }

  render() {
    return (
      <div className="main-body-section finance-body-sec ">
        {/* Business Transaction Batch Job Start */}

        <div className="bg-white">
          <div className="heading-part pl-7 py-4 border-b">
            <div className="flex items-center justify-between">
              <h4 className="small-title font-bold theme-color">
                {/* Business Transaction Batch Job */}
                Business Payment Reconciliations Batch Job
              </h4>
            </div>
          </div>

          <div className="grid grid-cols-12 gap-6 w-full items-center px-8 pt-6 pb-12">
            <div className="col-span-3">

              <h2 className="text-xl text-[#000000] font-bold 2xl:mb-1">
                Generate from date
                <span className="text-[#c00000]">*</span>
              </h2>
              <input
                id="generateFromDateWeekly"
                name="generateFromDateWeekly"
                className="date-pickers form-input rounded-none w-full shadow-red py-3 text-lg"
                type="date"
                value={moment(this.state.generateFromDateWeekly).format("yyyy-MM-DD")}
                onChange={(event) => this.handleChange(event)}
              />
            </div>
            <div className="col-span-4">
              <button className="btn btn-blue text-xl border text-white mt-8" onClick={() => this.GenerateWeeklyPaymentReconciliation()}>
                Generate Last Week Payment Reconciliations Records
              </button>
            </div>

            <div className="2xl:col-span-12 lg:col-span-12 col-span-12 w-full">
              {this.state.isLoading ? <PageLoader /> :
                <table className="w-full mx-auto my-5 fixed_header batch-job-extraction-table border">
                  <thead>
                    <tr className="text-base font-bold flex">
                      <th className="px-2 first:pl-5 last:pr-5 tbl-th whitespace-nowrap text-white w-[25%]">
                        <div className="font-bold tble-th-txt text-left">
                          Generate Date
                        </div>
                      </th>
                      <th className="px-2 first:pl-5 last:pr-5 tbl-th whitespace-nowrap text-white w-[15%]">
                        <div className="font-bold tble-th-txt text-left">
                          Report ID
                        </div>
                      </th>
                      <th className="px-2 first:pl-5 last:pr-5 tbl-th whitespace-nowrap text-white w-[20%]">
                        <div className="font-bold tble-th-txt text-left">
                          Period Start
                        </div>
                      </th>
                      <th className="px-2 first:pl-5 last:pr-5 tbl-th whitespace-nowrap text-white w-[20%]">
                        <div className="font-bold tble-th-txt text-left">
                          Period End
                        </div>
                      </th>
                      <th className="px-2 first:pl-5 last:pr-5 tbl-th whitespace-nowrap text-white w-[20%]">
                        <div className="font-bold tble-th-txt text-left">Action</div>
                      </th>
                    </tr>
                  </thead>

                  <tbody className="scrollit text-sm custom-tbody">
                    {this.state.weeklyPaymentReconsiliationRecordList.map((e) => {
                      return (
                        <tr className="custom-tbl-td flex items-center">
                          <td className="px-2 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt text-[#000000] w-[25%] ">
                            {moment(e.dateOfGeneration).format("DD MMM YYYY")}
                          </td>
                          <td className="px-2 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt text-[#000000] w-[15%]">
                            {e.paymentRecociliationNumber}
                          </td>
                          <td className="px-2 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt text-[#000000] w-[20%]">
                            {moment(e.startDate).format("DD MMM YYYY")}
                          </td>
                          <td className="px-2 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt text-[#000000] w-[20%]">
                            {moment(e.endDate).format("DD MMM YYYY")}
                          </td>
                          <td className="px-2 first:pl-5 last:pr-5 whitespace-nowrap tble-td-txt text-[#000000] w-[20%]">
                            <button
                              className="action-btn mr-4 flex items-center"
                              aria-controls="add-edit-modal"
                              onClick={() => {
                                this.DownloadSheetByXeroPaymentReconciliationId(e.xeroPaymentReconciliationId, 'Weekly');
                              }}
                            >
                              <p className="ml-2 tool-tip-txt underline text-[#005b9c]">
                                Download
                              </p>
                            </button>
                          </td>
                        </tr>
                      );
                    })}

                  </tbody>
                </table>
              }
            </div>

          </div>
        </div>

      </div>
    );
  }
}
