import React, { Component } from "react";
import { Navigate } from "react-router-dom";
import CreateAdmin from "../../components/accountManagement/createAdminAccount/CreateAdmin";
import AuthenticationServices from "../../services/axiosServices/apiServices/AuthenticationServices";
import CommonServices from "../../services/axiosServices/apiServices/CommonServices";
import SwalServices from "../../services/swalServices/SwalServices";
import { getAuthProps } from "../../utils/AuthenticationLibrary";
import { CommonValidationMessages } from "../../utils/Messages";
import { isValidForm, validate } from "../../utils/validation/CommonValidator";
import { checkParameters } from "../../utils/Encryption";

export default class CreateAdminAccount extends Component {
  constructor(props) {
    super(props);
    this.adminServices = new AuthenticationServices();
    this.commonServices = new CommonServices();
    this.swalServices = new SwalServices();
    this.state = {
      adminId: 0,
      adminDetails: {
        adminID: 0,
        name: "",
        lastName: "",
        email: "",
        password: "",
        roleId: "",
        profilePic: "",
        selectedRole: [],
        createdBy: 0,
        createdAppId: 0,
      },
      route: "",
      adminRoleList: [],
      createdBy: 0,
      createdAppId: 0,
      updatedBy: 0,
      updatedAppId: 0,
      adminName: '',
      validationRules: {
        name: [
          {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace("{0}", "first name"),
          },
        ],
        lastName: [
          {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace("{0}", "last name"),
          },
        ],
        email: [
          {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace("{0}", "email address"),
          },
          {
            type: "email",
            message: CommonValidationMessages.FieldRequired.replace("{0}", "valid email address"),
          },
        ],
        password: [
          {
            type: "require",
            message: CommonValidationMessages.FieldRequired.replace("{0}", "password"),
          },
          {
            type: "password",
            message: CommonValidationMessages.FieldRequired.replace("{0}", "valid password"),
          },
        ],
        selectedRole: [
          {
            type: "require",
            message: CommonValidationMessages.SelectRequired.replace("{0}", "admin role"),
          },
        ],
      },
      validState: {
        isValid: true,
        error: {},
      },
    };
  }

  ValidateAdminDetails = (key, object) => {
    const returnValidState = validate(key, this.state.adminDetails, this.state.validationRules, this.state.validState);
    this.setState({ validState: returnValidState, });
  };

  isValidateAllDetails = () => {
    let valRule = { ...this.state.validationRules };
    if (this.state.adminId > 0) {
      delete valRule.password;
    }
    const returnValidState = isValidForm(this.state.adminDetails, valRule, this.state.validState);
    this.setState({ validState: returnValidState });
    return returnValidState.isValid;
  };

  componentDidMount() {
    let params = new URLSearchParams(window.location.search);
    let adminId = params.get("");
    if (adminId && (adminId = checkParameters(adminId, "Number"))) {
      let cookie = getAuthProps();
      const adminUserId = (cookie && cookie.adminUserId) ? Number(cookie.adminUserId) : 0;
      const adminName = (cookie && cookie.name) ? cookie.name : '';
      if (adminId) {
        this.setState({ adminId: adminId, updatedBy: adminUserId, updatedAppId: 114, adminName: adminName });
      } else {
        this.setState({ adminId: adminId, createdBy: adminUserId, createdAppId: 114, adminName: adminName });
      }
    }
    this.getRolesForAdmin();
  }
  addEditAdminDetails = () => {
    if (this.isValidateAllDetails()) {
      let request = this.state.adminDetails;
      if (request.adminID && request.adminID > 0) {
        request.createdBy = this.state.updatedBy;
        request.createdAppId = this.state.updatedAppId;
      } else {
        request.createdBy = this.state.createdBy;
        request.createdAppId = this.state.createdAppId;
      }
      this.adminServices.addEditAdminDetails(request).then((response) => {
        if (response.statusCode === 200 && response.responseItem != null && response.responseItem.responseContent.errorColumn === "Email") {
          this.swalServices.Error("Email address already exists.");
        }
        else if (response.statusCode === 200 && response.responseItem != null) {
          this.swalServices.Success("Admin details updated successfully.");
          this.setState({ route: "/ViewEditAdminAccount" });
        }
        else {
          this.swalServices.Error(response.message);
        }
        this.setState({ isLoading: false });
      })
    }
  }
  getAdminDetailById = (id) => {
    this.adminServices.getAdminDetailById(id).then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {

        let data = this.state.adminRoleList.filter(x => x.value === response.responseItem.responseContent.roleId);
        let details = response.responseItem.responseContent;
        details.selectedRole = data;
        this.setState({ adminDetails: details });
      }
      else {
        this.swalServices.Error(response.message);
      }
      this.setState({ isLoading: false });
    })
  }



  handleChange = (event) => {
    const value = event.target.value;
    const name = event.target.name;
    let detail = { ...this.state.adminDetails }
    detail[name] = value;
    this.setState({ adminDetails: detail });
  };

  getRolesForAdmin = () => {


    this.adminServices.getRolesForAdmin().then((response) => {
      if (response.statusCode === 200 && response.responseItem != null) {

        let array = [];
        array = response.responseItem.responseContent;

        array = array.map((p) => ({
          value: p.id,
          label: p.name,
        }));

        this.setState({ adminRoleList: array }, () => {
          if (this.state.adminId && this.state.adminId > 0) {
            this.getAdminDetailById(this.state.adminId);
          }
        });

      }
      else {
        this.swalServices.Error(response.message);
      }
      this.setState({ isLoading: false });
    })
  }

  saveMainImage = (e, file, keyId, imageIdentity) => {
    file.storagePath = "Admin";
    this.commonServices.storeBase64Image(file).then((response) => {
      if (response != null) {
        this.swalServices.Success("File Uploaded");
        if (file.storagePath === "Admin") {
          let detail = this.state.adminDetails;
          detail["profilePic"] = response.fileName;
          this.setState({ adminDetails: { ...detail } });
        }
      }
      else {
        this.swalServices.Error(response.message);
      }
    })
  }

  handleChangeMultiDropdown(selected, identity) {
    var profile = this.state.adminDetails;
    if (identity === "AdminRole") {
      profile.selectedRole = selected;
      profile.roleId = selected.value;
      this.setState({ adminDetails: profile });
      this.ValidateAdminDetails("selectedRole");
    }
  }

  render() {
    if (this.state.route != null && this.state.route !== "") {
      return <Navigate to={this.state.route} />;
    }
    return (
      <div className="main-body-section">
        <div className="custom-card shadow-lg bg-white">
          <CreateAdmin
            adminDetails={this.state.adminDetails}
            handleChange={this.handleChange.bind(this)}
            adminRoleList={this.state.adminRoleList}
            handleChangeMultiDropdown={this.handleChangeMultiDropdown.bind(this)}
            saveMainImage={this.saveMainImage.bind(this)}
            ValidateAdminDetails={this.ValidateAdminDetails.bind(this)}
            validState={this.state.validState}
            adminId={this.state.adminId}
          />
        </div>
        <div className="grid grid-cols-12 gap-6 mt-14">
          <div className="xl:col-span-12 lg:col-span-12 col-span-12">
        
            <div className="flex justify-end">
              {this.state.adminId > 0 ?
                <button className="w-32 px-7 py-3 btn-sm btn btn-blue text-xl text-white font-semibold"
                  onClick={() => this.addEditAdminDetails()}
                >
                  Update
                </button>
                :
                <button className="w-32 px-7 py-3 btn-sm btn btn-blue text-xl text-white font-semibold"
                  onClick={() => this.addEditAdminDetails()}
                >
                  Create
                </button>
              }
            </div>

          </div>
        </div>
      </div>
    );
  }
}
