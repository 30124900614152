import moment from "moment/moment";
import React, { useState } from "react";
import { isNullString } from "../../../utils/Utils";
import GenerateCreditNote from "./GenerateCreditNote";
import ButtonLoader from "../../common/buttonLoader/ButtonLoader";

function FinancialsRecord(props) {

  const [generateCreditNote, setGenerateCreditNote] = useState(false);
  const [isInvoiceCancel, setIsInvoiceCancel] = useState(false);


  return (
    <div className="2xl:px-10 2xl:py-10 2xl:pt-7 lg:px-7 lg:py-7 lg:pt-5">
      {props.corporateFinanceInfo && props.corporateFinanceInfo.length > 0 && props.corporateFinanceInfo.map((value, key) => {
        return (
          <>
            <div className="px-5 2xl:py-5 mt-3 lg:py-5 2xl:pt-0 lg:pt-0 bg-white border-b border-[#f3f3f3]" key={key}>
              <div className="grid grid-cols-12 gap-6 w-full ">
                <div className="2xl:col-span-10 lg:col-span-9 col-span-12 2xl:pt-7 lg:mt-4 items-center">
                  <div className="grid grid-cols-12 gap-2">
                    <div className="xl:col-span-12 lg:col-span-12 col-span-12">
                      <div className="flex items-start justify-between">
                        <div className="left-side">
                          <h2 className="text-xl theme-color 2xl:mb-3">
                            Invoice Number : <span>{value.membershipPaymentInvoiceNo}</span>
                          </h2>
                          <h2 className="text-xl theme-color 2xl:mb-3">
                            Date : <span>{moment(value.paymentDate).format("DD MMM YYYY")}</span>
                          </h2>
                          {/* <h2 className="text-xl theme-color 2xl:mb-3">
                            Payment Method : <span>{value.paymentVia}</span>
                          </h2> */}
                          <h2 className="text-xl theme-color 2xl:mb-3">
                            Payment Status : <span>{value.paymentStatus}</span>
                          </h2>
                          {/* <h2 className="text-xl theme-color">
                            Invoice PDF : <span>Invoice</span>
                          </h2> */}
                        </div>
                        <div className="right-side">
                          <h2 className="text-xl theme-color 2xl:mb-3">
                            Promotion Code : <span className="">{isNullString(value.promoCode) === "" ? "N.A" : value.promoCode}</span>
                          </h2>
                          <h2 className="text-xl theme-color 2xl:mb-3">
                            Amount (Billable) : <span>${value.totalAmount} </span>
                          </h2>
                          {/* <h2 className="text-xl theme-color 2xl:mb-3">
                            Promotion Amount : <span>$2500 </span>
                          </h2> */}
                          <h2 className="text-xl theme-color ">
                            {/* {   voucherCode  Column use for showing paid amount of invoice  } */}
                            Amount (Paid) : <span>{value.voucherCode === null || value.voucherCode ==='' ? "$0.00" : <>${value.voucherCode}</>} </span>
                            {/* Amount (Paid) : <span>{value.paymentStatus === "Pending" ? "$0.00" : <>${value.totalAmount}</>} </span> */}
                          </h2>
                        </div>
                      </div>
                    </div>
                    <div className="xl:col-span-12 lg:col-span-12 col-span-12">
                      {/* <h2 className="text-xl theme-color 2xl:mb-3 flex">
                  <span className="title-desc">Description:</span>
                  <span className="desc-sec ml-5">
                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit,
                    sed diam nonummy nibh euismod tincidunt ut laoreet dolore
                    magna aliquam erat volutpat.
                  </span>
                </h2> */}
                    </div>
                  </div>
                </div>
                <div className="2xl:col-span-2 lg:col-span-3 col-span-12">
                  <div className="relative flex ">
                    <div className="right-status-label absolute 2xl:top-[9px] 2xl:right-[-20px] xl:top-[10px] xl:right-[-20px]  lg:top-[10px] lg:right-[-20px]">
                      <span className={`text-xl text-white font-semibold  2xl:px-2 xl:px-2 lg:px-3 md:px-6 sm:px-4 py-3 ${value.paymentStatus === "Success" ? "bg-[#7a9763]" : "bg-[#ffae00]"}`}>
                        {value.paymentStatus === "Success" ? "Paid" : value.paymentStatus}
                      </span>
                    </div>
                    <div className="right-btn mt-20 w-full">
                      <div className="flex items-center justify-end mb-5">
                        <button className="2xl:px-7 lg:px-5 py-2 btn btn-blue-border text-xl border text-[#005B9C]" onClick={() => props.getCorporateFinanceReceiptInvoice(value.membershipPaymentId, "Invoice")}>
                          <span className="text-xl text-[#005B9C] font-semibold tracking-normal underline">
                            View Invoice
                          </span>
                        </button>
                      </div>

                      {value.isReceiptGenerated === true && (value.paymentStatus === "Success" || value.paymentStatus === "Pending Cancellation") ?
                        <>
                          <div className="flex items-center justify-end mb-5">
                            <button className="2xl:px-7 lg:px-5 py-2 btn btn-blue-border text-xl border text-[#005B9C]" onClick={() => props.getCorporateFinanceReceiptInvoice(value.membershipPaymentId, "Receipt")}>
                              <span className="text-xl text-[#005B9C] font-semibold tracking-normal underline">
                                View Receipt
                              </span>
                            </button>
                          </div>
                          <div className="flex items-center justify-end mb-5">
                            <>
                              {value.membershipPaymentTypeId === 1 || value.membershipPaymentTypeId === 2 || value.membershipPaymentTypeId === 4 ?
                                <button className="2xl:px-7 lg:px-5 py-2 btn btn-blue-border text-xl border text-[#005B9C]" onClick={() => props.getCorporateFinanceReceiptInvoice(value.membershipPaymentId, "Certificate")}>
                                  <span className="text-xl text-[#005B9C] font-semibold tracking-normal underline">
                                    View Certificate
                                  </span>
                                </button>
                                : null}
                            </>
                          </div>
                          <div className="flex items-center justify-end mb-5">
                            <>
                              {value.membershipPaymentTypeId === 1 || value.membershipPaymentTypeId === 2 || value.membershipPaymentTypeId === 4 ?
                                <button className="2xl:px-7 lg:px-5 py-2 btn btn-blue text-white mr-0 "
                                  onClick={() => props.getReceiptAndCertificate(value.membershipPaymentId)}>
                                  {/* <span className="text-xl text-[#005B9C] font-semibold tracking-normal underline">
                                    Sent Receipt & Certificate
                                  </span> */}
                                  <span className="text-xl text-white font-semibold tracking-normal finance-button ">
                                    Sent Receipt & Certificate
                                  </span>
                                </button>
                                : null}
                            </>
                          </div>
                        </>
                        : null
                      }
                      {(value.membershipPaymentTypeId === 3 || value.paymentStatus === "Cancelled" || value.paymentStatus === "Pending Cancellation") ?
                        null
                        :
                        <>
                          {/* <div>
                            <button className="2xl:px-7 lg:px-5 py-2 btn btn-blue text-white "
                              onClick={(e) => {
                             
                                props.getReceiptAndCertificate(value.membershipPaymentId);


                              }}>
                              <span className="text-xl text-white font-semibold tracking-normal finance-button">
                                Generate Receipt&Certificate
                              </span>
                            </button>
                          </div> */}
                          {value.isAvailableForCreditNote === true ?
                            <div className="flex items-center justify-end">
                              <button className="2xl:px-7 lg:px-5 py-2 btn btn-blue text-white mr-0"
                                onClick={(e) => {
                                  e.stopPropagation(); setGenerateCreditNote(true);
                                  setIsInvoiceCancel(value.paymentStatus === "Pending" ? true : false);
                                  props.getCreditNoteInfoForCorporateMember(value.membershipPaymentId, value.paymentStatus, value.isReceiptGenerated);
                                  props.getCancellationReasonForCreditNote();
                                  props.addEditCreditNote.membershipPaymentId = value.membershipPaymentId;
                                }}>
                                <span className="text-xl text-white font-semibold tracking-normal finance-button ">
                                  {value.paymentStatus === "Pending" ? "Cancel Invoice" : "Generate Credit Note"}
                                </span>
                              </button>


                            </div>
                            : null}
                        </>
                      }



                      {/* {value.isReceiptGenerated === true && value.paymentStatus === "Success" ?
                        <>
                           <div className="flex items-center justify-end mb-5">
                            <button className="2xl:px-7 lg:px-5 py-2 btn btn-blue-border text-xl border text-[#005B9C]" onClick={() => props.getCorporateFinanceReceiptInvoice(value.membershipPaymentId, true)}>
                              <span className="text-xl text-[#005B9C] font-semibold tracking-normal underline">
                                View Receipt
                              </span>
                            </button>
                          </div> 
                           <div className="flex items-center justify-end">
                            <button className="2xl:px-7 lg:px-5 py-2 btn btn-blue text-white" onClick={(e) => { e.stopPropagation(); setGenerateCreditNote(true); setIsInvoiceCancel(false); props.getCreditNoteInfoForCorporateMember(value.membershipPaymentId, value.paymentStatus, value.isReceiptGenerated); props.getCancellationReasonForCreditNote(); props.addEditCreditNote.membershipPaymentId = value.membershipPaymentId; }}>
                              <span className="text-xl text-white font-semibold tracking-normal">
                                Generate Credit Note
                              </span>
                            </button>
                          </div> 
                        </>
                        : value.paymentStatus === "Pending" ?
                          <>
                            <div className="flex items-center justify-end">
                              <button className="2xl:px-7 lg:px-5 py-2 btn btn-blue text-white" onClick={(e) => { e.stopPropagation(); setGenerateCreditNote(true); setIsInvoiceCancel(true); props.getCreditNoteInfoForCorporateMember(value.membershipPaymentId, value.paymentStatus, value.isReceiptGenerated); props.getCancellationReasonForCreditNote(); props.addEditCreditNote.membershipPaymentId = value.membershipPaymentId; }}>
                                <span className="text-xl text-white font-semibold tracking-normal">
                                  Cancel Invoice
                                </span>
                              </button>
                            </div>
                          </>
                          : null
                      } */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )
      })}
      <div className="add-edite-profile-popup">
        <GenerateCreditNote
          generateCreditNote={generateCreditNote}
          setOpenModal={() => setGenerateCreditNote(false)}
          corporateCreditNotePaymentInfo={props.corporateCreditNotePaymentInfo}
          cancellationReason={props.cancellationReason}
          setFilterParameters={props.setFilterParameters}
          isOtherCancellationReason={props.isOtherCancellationReason}
          addEditCreditNote={props.addEditCreditNote}
          handleChange={props.handleChange}
          addFinanceCreditNote={props.addFinanceCreditNote}
          validate={props.validate}
          validCreditNote={props.validCreditNote}
          validate2={props.validate2}
          validCreditNote2={props.validCreditNote2}
          isInvoiceCancel={isInvoiceCancel}
          isLoading={props.isLoading}
        />
      </div>
    </div>
  );
}

export default FinancialsRecord;
